/* eslint-disable */

import Immutable from 'seamless-immutable';
import { get, isEmpty, omit } from 'lodash';
import { v4 as uuid } from 'uuid';
import { toast } from 'react-toastify';

import axios from 'axios';
import * as api from '~/api';
import i18n from '~/common/helpers/i18n';

const normalize = canvas => {
  if (canvas.toJS) canvas = canvas.toJS();
  const nodes = Immutable(canvas)
    .asMutable({ deep: true })
    .nodes.map(node => {
      if (node.data.onProcessTrack) {
        node.data.onProcessTrack = normalize(node.data.onProcessTrack);
      }

      if (node.data.onExceptionTrack) {
        node.data.onExceptionTrack = normalize(node.data.onExceptionTrack);
      }

      return {
        id: node.data.id,
        data: omit(node.data, ['id', 'key', 'uuid']),
        type: node.data.key
      };
    });

  const edges = canvas.edges.map(edge => ({
    source: edge.data.source,
    target: edge.data.target,
    data: edge.data.data
  }));

  return { nodes, edges };
};

let isDeprecatedCapsule = node => {
  const regex = new RegExp('^v2');
  if (node.data.type !== 'library');
  return regex.test(node.data.name);
};

let isSublevelComponent = node => {
  return node.data.onProcessTrack || node.data.onExceptionTrack;
};

const replaceRealm = (name, service) => {
  return name?.substring(3)?.replace(`-${service}`, '');
};

let upgradeCapsule = node => {
  const realm = replaceRealm(node.data.name, node.data.service);
  const capsuleName = `capsule-v1-${realm}-${node.data.catalog}-${node.data.service}`;
  const data = {
    type: 'capsule',
    id: node.data.id,
    key: `component@capsule${capsuleName}`,
    name: capsuleName,
    capsuleCollection: node.data.catalog,
    capsuleCollectionVersion: node.data.catalogVersion,
    capsule: node.data.service,
    capsuleVersionMajor: node.data.serviceVersionMajor,
    capsuleVersionMinor: node.data.serviceVersionMinor,
    stepName: node.data.stepName,
    params: node.data.params
  };
  return { ...node, data };
};

let convertPipeline = canvas => {
  const nodes = canvas.nodes.map(node => {
    if (isDeprecatedCapsule(node)) {
      return upgradeCapsule(node);
    } else {
      if (isSublevelComponent(node)) {
        const onProcessTrack = node.data.onProcessTrack
          ? convertPipeline(node.data.onProcessTrack)
          : undefined;
        const onExceptionTrack = node.data.onExceptionTrack
          ? convertPipeline(node.data.onExceptionTrack)
          : undefined;
        return {
          ...node,
          data: {
            ...node.data,
            onProcessTrack,
            onExceptionTrack
          }
        };
      } else {
        return node;
      }
    }
  });

  return {
    nodes,
    edges: canvas.edges
  };
};

export const prepare = canvas => {
  const { nodes, edges } = normalize(canvas);
  return {
    nodes: nodes.map(node =>
      Object.assign({}, node, { data: JSON.stringify(node.data) })
    ),
    edges
  };
};

const initialState = Immutable({
  canUpdateInstance: false,
  rendered: false,
  data: {
    pipeline: {
      name: '',
      description: '',
      canvasVersion: 1,
      canvas: {
        nodes: [],
        edges: []
      },
      parameterizedReplica: null,
      inSpec: null,
      outSpec: null,
      hasAlert: false,
      alert: null
    },
    sensitiveFields: {
      logSensitiveFields: []
    }
  },
  saving: false,
  trackingId: uuid(),
  dialogAlert: {
    opened: false
  },
  triggerConfiguration: {
    opened: false
  }
});

const LibraryModel = {
  name: 'pipelineExperimental',
  state: initialState,
  reducers: {
    reset(state) {
      return initialState;
    },
    setRendered(state, value) {
      return state.setIn(['rendered'], value);
    },
    setData(state, data = {}) {
      return state.merge(
        // eslint-disable-next-line no-unneeded-ternary
        {
          data,
          canUpdateInstance: data.pipeline.parameterizedReplica ? false : true
        },
        { deep: true }
      );
    },
    resetAlert(state) {
      return state.merge(
        {
          data: {
            pipeline: {
              ...state.data.pipeline,
              hasAlert: false,
              alert: null
            }
          }
        },
        { deep: true }
      );
    },

    closeDialogAlert(state) {
      return state.merge(
        {
          dialogAlert: { opened: false }
        },
        { deep: true }
      );
    },

    closeTriggerConfiguration(state) {
      return state.merge(
        {
          triggerConfiguration: {
            opened: false
          }
        },
        { merge: true }
      );
    },
    openTriggerConfiguration(state) {
      return state.merge(
        {
          triggerConfiguration: {
            opened: true
          }
        },
        { merge: true }
      );
    },
    openDialogAlert(state) {
      return state.merge(
        {
          dialogAlert: {
            opened: true
          }
        },
        { deep: true }
      );
    },
    setSaving(state, saving) {
      return state.merge({ saving });
    },

    updatePipeline(state, data) {
      return state.merge(
        {
          // eslint-disable-next-line no-unneeded-ternary
          canUpdateInstance: data.parameterizedReplica ? false : true,
          data: {
            pipeline: data
          }
        },
        { deep: true }
      );
    },

    setPipelineSettings(
      state,
      {
        description,
        sensitiveFields,
        inSpec,
        outSpec,
        parameterizedReplica,
        hasInstance
      }
    ) {
      return state
        .setIn(['data', 'pipeline', 'description'], description)
        .setIn(['data', 'pipeline', 'inSpec'], inSpec)
        .setIn(['data', 'pipeline', 'outSpec'], outSpec)
        .setIn(
          ['data', 'sensitiveFields', 'logSensitiveFields'],
          sensitiveFields
        )
        .setIn(
          ['data', 'pipeline', 'parameterizedReplica'],
          parameterizedReplica
        )
        .setIn(
          ['data', 'pipeline', 'parameterizedReplica'],
          hasInstance ? parameterizedReplica : null
        );
    }
  },
  effects: dispatch => ({
    async fetchData({ id, realm }) {
      dispatch.pipelineExperimental.setRendered(true);
      performance.mark('pipeline-fetch-start');
      const response = await api.pipeline.getExperimental({ id, realm });
      dispatch.pipelineExperimental.setData(response.data);
    },
    async savePipeline(
      { name, description, image, projectId = null } = {},
      { application, cy, design, pipelineExperimental, authentication, acls },
      meta
    ) {
      const { data, trackingId } = pipelineExperimental;
      const { realm } = application.realm;
      if (design.saving) return false;
      const canvas = meta?.isUpdateVersion
        ? convertPipeline(cy.present.getIn(['root']).toJS())
        : cy.present.getIn(['root']).toJS();
      const triggerSpec = cy.present
        .getIn(['root', 'nodes'])
        .find(node => node.getIn(['data', 'id']) === 'trigger')
        .get('data')
        .toJS();
      const pipeline = {
        ...omit(data.pipeline, ['canvas', 'canvasVersion']),
        name: data.pipeline.name === 'Untitled' ? name : data.pipeline.name,
        description: description || data.pipeline.description,
        hasAlert: data.pipeline.hasAlert,
        alert: data.pipeline.alert,
        canvas: prepare(canvas),
        triggerSpec:
          triggerSpec.key === 'trigger@not-configured'
            ? {}
            : omit(triggerSpec, ['id', 'key', 'stepName'])
      };
      if (isEmpty(name) && isEmpty(pipeline.id))
        return dispatch.design.openSave();

      dispatch.design.setSaving(true);
      try {
        const betaShapes = acls.scopes.some(scope =>
          ['BETA:SHAPES'].includes(scope)
        );
        if (!betaShapes) {
          const saveImage = async () => {
            try {
              return await api.pipeline.saveImage({
                token: authentication?.userData?.token,
                image,
                realm,
                thumbnailName: data.pipeline.thumbnailName
              });
            } catch (error) {
              return undefined;
            }
          };

          const thumbnailName = await saveImage();

          pipeline.thumbnailName = thumbnailName;
        }
        const response = await api.pipeline.save({
          realm,
          trackingId,
          projectId,
          pipeline
        });

        const { updatePipeline } = response.data;

        await api.pipeline.updateSensitiveFields({
          realm,
          pipelineId: updatePipeline.id,
          sensitiveFields: get(
            pipelineExperimental,
            'data.sensitiveFields.logSensitiveFields',
            []
          )
        });
        dispatch.pipelineExperimental.updatePipeline(updatePipeline, meta);
      } catch (error) {
        // TODO: Verificar se faz necessário a flag saving em pipeline.
        dispatch.pipelineExperimental.setSaving(false);

        if (error.message.includes('409')) {
          return toast.error(i18n.t('label.update_pipeline_msg_error'));
        }

        return toast.error(error.message);
      } finally {
        dispatch.design.setPendingSave(false);
        dispatch.design.setSaving(false);
      }
    },

    updateTrigger(value, rootState) {
      const { normalizedPipeline } = rootState.pipelineExperimental;
      const setNode = (node = Immutable({}), data) => node.merge(data);

      const currentTriggerPath = ['nodes', 0];
      const updated = normalizedPipeline.updateIn(
        currentTriggerPath,
        setNode,
        value
      );

      return dispatch.pipelineExperimental.setNormalizedPipeline(updated);
    },

    updatePipelineSettings(value, rootState) {
      dispatch.pipelineExperimental.setPipelineSettings(value);
      dispatch.design.setPendingSave(true);
    },
    async updateConfirmAlert(isUpdatePipeline, rootState) {
      const PIPELINE_UPDATE = true;
      try {
        const { data } = rootState.pipelineExperimental;
        const { realm } = rootState.application.realm;
        if (isUpdatePipeline === PIPELINE_UPDATE) {
          await api.pipeline.confirm({ realm, pipelineId: data.pipeline.id });
          dispatch.pipelineExperimental.resetAlert();
        }
        dispatch.pipelineExperimental.closeDialogAlert();
      } catch (error) {
        return toast.error(error.message);
      }
    }
  }),
  logics: [
    {
      type: 'pipelineExperimental/reset',
      latest: true,
      process(context, dispatch, done) {
        dispatch.cy.reset();
        done();
      }
    },
    {
      type: 'pipelineExperimental/updatePipeline',
      latest: true,
      process({ action, getState }, dispatch, done) {
        dispatch.design.closeSave();
        dispatch.pipelineExperimental.setSaving(false);

        const { realm } = getState().application;
        const { id } = action.payload;

        if (id) {
          const pipelineBaseURL = `/${realm.realm}/design/pipelines`;

          if (
            window.location.pathname.includes(pipelineBaseURL) &&
            window.location.pathname.includes(id) === false
          ) {
            dispatch.router.navigate({
              to: `${pipelineBaseURL}/${id}`
            });
          }

          if (action?.meta?.isUpdateVersion) location.reload();

          toast.success(i18n.t('label.pipeline_saved_msg_success'));
        }

        done();
      }
    },
    {
      type: 'pipelineExperimental/updatePipelineSettings',
      latest: true,
      process({ action, getState }, dispatch, done) {
        dispatch.design.closeSettings();
        const { parameterizedReplica } = action.payload;
        const { realm } = getState().application.realm;
        dispatch.test.setFieldMultiInstance(null);
        if (parameterizedReplica) {
          dispatch.pipeline.findMultiInstance({
            replicaName: parameterizedReplica,
            realm
          });
        }
        done();
      }
    },
    {
      type: 'pipelineExperimental/openTriggerConfiguration',
      latest: true,
      process({ action }, dispatch, done) {
        const { element, component, schema } = action.payload;
        dispatch.cy.setTriggerConfiguration({ element, component, schema });
        done();
      }
    },
    {
      type: ['pipelineExperimental/openTriggerConfiguration'],
      latest: true,
      process(context, dispatch, done) {
        Shortcuts.getScope('design').rules.add(restrictShortcuts);
        done();
      }
    },
    {
      type: ['pipelineExperimental/closeTriggerConfiguration'],
      latest: true,
      process(context, dispatch, done) {
        Shortcuts.getScope('design').rules.remove(restrictShortcuts);

        done();
      }
    },
    {
      type: [
        'pipelineExperimental/setData',
        'pipelineExperimental/updatePipeline'
      ],
      latest: true,
      async transform({ action, getState }, next) {
        const { rendered } = getState().pipelineExperimental;
        return next(rendered ? action : {});
      }
    },
    {
      type: 'pipelineExperimental/setData',
      latest: true,
      process({ action, getState }, dispatch, done) {
        const { pipeline } = action.payload;
        const { realm } = getState().application.realm;
        if (pipeline.parameterizedReplica) {
          dispatch.pipeline.findMultiInstance({
            replicaName: pipeline.parameterizedReplica,
            realm
          });
        } else {
          performance.mark('pipeline-fetch-finish');
        }
        done();
      }
    }
  ]
};

export default LibraryModel;
