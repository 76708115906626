import { isApolloError, gql } from '@apollo/client';
import clientApollo from './clientApollo';
import { MappingResponse, TunnelResponse } from '~/entities/DashboardVPN';

type SortOrder = 'desc' | 'asc';

type DashboardVPNFetchData = {
  realm: string;
  id?: string;
  sort?: string;
  order?: SortOrder;
};

type TunnelFetchAllResult = {
  getTunnels: TunnelResponse[];
};

type MappingFetchAllResult = {
  getMappings: MappingResponse[];
};

const dashboardVPN = {
  getTunnels: async ({
    realm,
    sort,
    order = 'desc'
  }: DashboardVPNFetchData) => {
    try {
      const result = await clientApollo.query<TunnelFetchAllResult>({
        query: gql`
          query getTunnels($realm: String!, $sort: String, $order: String) {
            getTunnels(realm: $realm, sort: $sort, order: $order)
          }
        `,
        variables: {
          realm,
          sort,
          order
        }
      });

      return result.data;
    } catch (e) {
      if (e instanceof Error && isApolloError(e)) throw Error(e.message);
      throw e;
    }
  },
  getTunnelsMOCK: async ({
    realm,
    sort,
    order = 'desc'
  }: DashboardVPNFetchData): Promise<TunnelFetchAllResult> => {
    return {
      getTunnels: [
        {
          metric: {
            host: 'dgb-vpn-gateway-accamargo-1',
            tunnel: '10.11.38.49/32 conn1j',
            status: 'up',
            traffic: {
              download: '1',
              upload: '1'
            },
            lastUpdate: 1.738162561638e9
          }
        },
        {
          metric: {
            host: 'dgb-vpn-gateway-accamargo-1',
            tunnel: '10.111.22.4/32 conn1i',
            status: 'up',
            traffic: {
              download: '1',
              upload: '1'
            },
            lastUpdate: 1.738162561638e9
          }
        },
        {
          metric: {
            host: 'dgb-vpn-gateway-accamargo-1',
            tunnel: '10.111.22.8/32 conn1d',
            status: 'up',
            traffic: {
              download: '1',
              upload: '128.05882352941177'
            },
            lastUpdate: 1.738162561638e9
          }
        },
        {
          metric: {
            host: 'dgb-vpn-gateway-accamargo-1',
            tunnel: '10.11.39.55/32 conn1s',
            status: 'up',
            traffic: {
              download: '128.05882352941177',
              upload: '191.58823529411765'
            },
            lastUpdate: 1.738162561638e9
          }
        },
        {
          metric: {
            host: 'dgb-vpn-gateway-accamargo-2',
            tunnel: '10.11.38.104/32 conn1l',
            status: 'up',
            traffic: {
              download: '1',
              upload: '1'
            },
            lastUpdate: 1.738162561638e9
          }
        },
        {
          metric: {
            host: 'dgb-vpn-gateway-accamargo-2',
            tunnel: '10.11.39.55/32 conn1s',
            status: 'up',
            traffic: {
              download: '1',
              upload: '1'
            },
            lastUpdate: 1.738162561638e9
          }
        },
        {
          metric: {
            host: 'dgb-vpn-gateway-accamargo-2',
            tunnel: '10.11.39.49/32 conn1m',
            status: 'up',
            traffic: {
              download: '1',
              upload: '1'
            },
            lastUpdate: 1.738162561638e9
          }
        },
        {
          metric: {
            host: 'dgb-vpn-gateway-accamargo-2',
            tunnel: '10.11.22.2/32 conn1',
            status: 'up',
            traffic: {
              download: '1',
              upload: '1'
            },
            lastUpdate: 1.738162561638e9
          }
        },
        {
          metric: {
            host: 'dgb-vpn-gateway-accamargo-2',
            tunnel: '10.11.38.103/32 conn1k',
            status: 'up',
            traffic: {
              download: '1',
              upload: '1'
            },
            lastUpdate: 1.738162561638e9
          }
        },
        {
          metric: {
            host: 'dgb-vpn-gateway-accamargo-2',
            tunnel: '10.111.22.8/32 conn1d',
            status: 'up',
            traffic: {
              download: '1',
              upload: '1'
            },
            lastUpdate: 1.738162561638e9
          }
        },
        {
          metric: {
            host: 'dgb-vpn-gateway-accamargo-2',
            tunnel: '10.11.60.40/32 conn1x',
            status: 'up',
            traffic: {
              download: '1',
              upload: '1'
            },
            lastUpdate: 1.738162561638e9
          }
        },
        {
          metric: {
            host: 'dgb-vpn-gateway-accamargo-2',
            tunnel: '10.111.22.5/32 conn1a',
            status: 'up',
            traffic: {
              download: '1',
              upload: '1'
            },
            lastUpdate: 1.738162561638e9
          }
        },
        {
          metric: {
            host: 'dgb-vpn-gateway-accamargo-2',
            tunnel: '10.11.39.103/32 conn1r',
            status: 'up',
            traffic: {
              download: '1',
              upload: '1'
            },
            lastUpdate: 1.738162561638e9
          }
        },
        {
          metric: {
            host: 'dgb-vpn-gateway-accamargo-2',
            tunnel: '10.11.29.67/32 conn1p',
            status: 'down',
            traffic: {
              download: '1',
              upload: '1'
            },
            lastUpdate: 1.738162561638e9
          }
        },
        {
          metric: {
            host: 'dgb-vpn-gateway-accamargo-2',
            tunnel: '10.111.22.7/32 conn1c',
            status: 'down',
            traffic: {
              download: '1',
              upload: '1'
            },
            lastUpdate: 1.738162561638e9
          }
        },
        {
          metric: {
            host: 'dgb-vpn-gateway-accamargo-2',
            tunnel: '10.111.22.4/32 conn1i',
            status: 'down',
            traffic: {
              download: '1',
              upload: '1'
            },
            lastUpdate: 1.738162561638e9
          }
        },
        {
          metric: {
            host: 'dgb-vpn-gateway-accamargo-2',
            tunnel: '10.11.29.151/32 conn1z',
            status: 'down',
            traffic: {
              download: '1',
              upload: '1'
            },
            lastUpdate: 1.738162561638e9
          }
        }
      ]
    };
  },
  getMappings: async ({
    realm,
    sort,
    order = 'desc'
  }: DashboardVPNFetchData) => {
    try {
      const result = await clientApollo.query<MappingFetchAllResult>({
        query: gql`
          query getMappings($realm: String!, $sort: String, $order: String) {
            getMappings(realm: $realm, sort: $sort, order: $order)
          }
        `,
        variables: {
          realm,
          sort,
          order
        }
      });

      return result.data;
    } catch (e) {
      if (e instanceof Error && isApolloError(e)) throw Error(e.message);
      throw e;
    }
  },
  getMappingsMOCK: async ({
    realm,
    sort,
    order = 'desc'
  }: DashboardVPNFetchData): Promise<MappingFetchAllResult> => {
    return {
      getMappings: [
        {
          metric: {
            __name__: 'ipsec_tunnel_nat_status',
            forwarding: '10.11.22.2:1521',
            host: 'dgb-vpn-gateway-accamargo-1',
            instance: 'dgb-vpn-gateway-accamargo-1',
            job: 'gce_instances',
            listen: '10.158.1.200:1527',
            project: 'digibee-core',
            realm: 'accamargo',
            status: 'up',
            type: 'iptables',
            zone: 'southamerica-east1-a'
          },
          value: [1.738162561638e9, '1']
        },
        {
          metric: {
            __name__: 'ipsec_tunnel_nat_status',
            forwarding: '10.11.22.3:1522',
            host: 'dgb-vpn-gateway-accamargo-2',
            instance: 'dgb-vpn-gateway-accamargo-2',
            job: 'gce_instances',
            listen: '10.158.1.201:1528',
            project: 'digibee-core',
            realm: 'accamargo',
            status: 'up',
            type: 'iptables',
            zone: 'southamerica-east1-b'
          },
          value: [1.738162561638e9, '1']
        },
        {
          metric: {
            __name__: 'ipsec_tunnel_nat_status',
            forwarding: '10.11.22.4:1523',
            host: 'dgb-vpn-gateway-accamargo-3',
            instance: 'dgb-vpn-gateway-accamargo-3',
            job: 'gce_instances',
            listen: '10.158.1.202:1529',
            project: 'digibee-core',
            realm: 'accamargo',
            status: 'down',
            type: 'iptables',
            zone: 'southamerica-east1-c'
          },
          value: [1.738162561638e9, '0']
        },
        {
          metric: {
            __name__: 'ipsec_tunnel_nat_status',
            forwarding: '10.11.22.5:1524',
            host: 'dgb-vpn-gateway-prodcorp-1',
            instance: 'dgb-vpn-gateway-prodcorp-1',
            job: 'gce_instances',
            listen: '10.158.1.203:1530',
            project: 'digibee-core',
            realm: 'prodcorp',
            status: 'up',
            type: 'iptables',
            zone: 'southamerica-east1-a'
          },
          value: [1.738162561638e9, '1']
        },
        {
          metric: {
            __name__: 'ipsec_tunnel_nat_status',
            forwarding: '10.11.22.6:1525',
            host: 'dgb-vpn-gateway-prodcorp-2',
            instance: 'dgb-vpn-gateway-prodcorp-2',
            job: 'gce_instances',
            listen: '10.158.1.204:1531',
            project: 'digibee-core',
            realm: 'prodcorp',
            status: 'down',
            type: 'iptables',
            zone: 'southamerica-east1-b'
          },
          value: [1.738162561638e9, '0']
        }
      ]
    };
  }
};

export default dashboardVPN;
