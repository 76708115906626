import {
  DataGrid,
  Tooltip,
  IconButton,
  Icon,
  Box,
  Empty
} from '@digibee/beehive-ui';
import { choose, otherwise, when } from '@digibee/control-statements';
import { useTranslation } from 'react-i18next';

import MembersSidesheet from './components/MembersSidesheet';
import * as Elements from './Organization.elements';
import type { Props } from './Organization.enhancer';
import { OrganizationGroup } from '../../models/organization';

import theme from '~/common/styled/theme';

const Groups = ({
  data,
  loading,
  openModal
}: Props) => {
  const [t] = useTranslation();

  return (
    <>
      <Elements.Container>
        {choose(
          when(!data || !data.length, () => (
            <Empty
              illustration={theme.images.emptyStateList}
              title={t('scenes.consumers.guidance.empty_list_prompt') || ''}
              buttonHide
            />
          )),
          otherwise(() => (
            <DataGrid<OrganizationGroup>
              loading={loading}
              data-testid='governance-organization-groups-list-table'
              data={data || []}
              isPagination
              manualPagination
              autoResetPage={false}
              showRowsPerPage={false}
              totalElements={1}
              pageSize={1}
              pageCount={1}
              page={0}
              columns={[
                {
                  id: 'name',
                  Header: t('scenes.groups.labels.name') as string,
                  accessor: 'name',
                },
                {
                  id: 'members',
                  Header: t('scenes.groups.labels.members') as string,
                  Cell: ({ row }: { row: { original: OrganizationGroup } }) => (
                    <Box>{row.original?.users?.length}</Box>
                  )
                },
                {
                  id: 'action',
                  Header: t('label.actions') as string,
                  Cell: ({ row }: { row: { original: OrganizationGroup } }) => (
                    <>
                      <Tooltip
                        align='center'
                        content={t('scenes.groups.actions.update')}
                        side='bottom'
                      >
                        <IconButton
                          onClick={() => openModal(row.original?.users, row.original?.name, row.original?.realms)}
                          size='medium'
                          data-testid='groups-edit-button'
                        >
                          <Icon icon='edit' size='md' variant='secondary' />
                        </IconButton>
                      </Tooltip>
                    </>
                  )
                }
              ]}
            />
          ))
        )}
      </Elements.Container>
      <MembersSidesheet />
    </>
  );
};

export default Groups;
