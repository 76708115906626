import { createRoot } from 'react-dom/client';
import 'reset-css/reset.css';
import 'react-datepicker/dist/react-datepicker.css';

import App from '~/scenes/Others/scenes/App';

import '~/common/helpers/i18n';
import './index.css';

// import { inspect } from '@xstate/inspect';

// // Habilite o DevTools para integração com o Visualizer
// if (process.env.NODE_ENV === 'development') {
//   inspect({
//     // Endereço do servidor remoto do Visualizer
//     iframe: false // Usa uma janela separada ao invés de um iframe
//   });
// }

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(<App />);
