// import Immutable from 'seamless-immutable';
import { get, isEmpty, omit } from 'lodash';
import { normalize, schema } from 'normalizr';
import { toast } from 'react-toastify';
import SeamlessImmutable from 'seamless-immutable';
import slugify from 'underscore.string/slugify';
import { v4 as uuid } from 'uuid';

import { prepare } from './pipelineExperimental';

import * as api from '~/api';
import addAccountSectionOnFormSchema from '~/common/helpers/addAccountSectionOnFormSchema';
import i18n from '~/common/helpers/i18n';
import getInitialValues from '~/components/_oldComponents/FantaForm/helpers/getInitialValues';

const generatorIconName = (title, extension) =>
  `collectionHeaders/${uuid()}-${slugify(title)}.${extension}`;

const stepNameField = [
  {
    property: 'stepName',
    label: 'Step Name',
    type: 'string',
    input: 'text',
    default: '',
    validations: {
      type: 'string',
      options: [
        {
          type: 'required'
        },
        {
          type: 'max',
          limit: 60
        }
      ]
    }
  }
];

const addStepName = formSchema => {
  const [first, ...rest] = removeStepName(formSchema);
  const formSchemaWithStepName = [
    {
      schema: stepNameField.concat(first.schema)
    },
    ...rest
  ];
  return formSchemaWithStepName;
};

const removeStepName = schemas =>
  schemas.map(schema => ({
    ...schema,
    schema: schema?.schema?.filter(s => s.property !== 'stepName')
  }));

const addAddons = formSchema => {
  const [first, ...rest] = formSchema;
  const addons = {
    intellisense: {
      active: true
    }
  };

  const formSchemaWithAddons = [
    {
      schema: first.schema.map(inputSchema => ({
        ...inputSchema,
        addons
      }))
    },
    ...rest
  ];

  return formSchemaWithAddons;
};

const initialState = SeamlessImmutable({
  accounts: [],
  data: {
    sensitiveFields: {
      logSensitiveFields: []
    },
    pipeline: {
      name: '',
      title: '',
      description: '',
      documentation: '',
      jsonSchema: [
        {
          schema: [
            {
              property: 'params.param_a',
              label: 'PARAM A',
              type: 'string',
              input: 'text',
              default: 'lorem'
            },
            {
              property: 'params.param_b',
              label: 'PARAM B',
              type: 'string',
              input: 'text',
              default: 'ipsum'
            }
          ]
        }
      ],
      canvas: {
        nodes: [],
        edges: []
      },
      inSpec: {},
      outSpec: {},
      state: 'DEVELOPING'
    }
  },
  saveResult: {
    saveCollectionHeader: true
  },
  partialOpen: {
    saveCollection: false,
    saveGroup: false,
    saveCollectionHeader: false,
    createCollectionGroup: false
  },
  loading: {
    saveCollectionHeader: false,
    saveCollection: false
  },
  error: {
    saveCollection: false,
    saveCollectionHeader: false
  },
  result: {
    fetchCollectionGroup: []
  },
  current: {
    saveCollectionHeader: null,
    saveCollection: null,
    createCollectionGroup: null
  },
  mustUpgrade: false,
  saving: false,
  trackingId: uuid()
});

const capsuleModel = {
  name: 'capsule',
  state: initialState,
  reducers: {
    reset() {
      return initialState;
    },
    setSaveResult(state, { path, value }) {
      return state.merge(
        {
          saveResult: {
            [path]: value
          }
        },
        { deep: true }
      );
    },
    clear(state) {
      return state.merge(
        {
          current: {
            saveCollectionHeader: null,
            saveCollection: null,
            createCollectionGroup: null
          }
        },
        { deep: true }
      );
    },
    setErro(state, { path, value }) {
      return state.merge(
        {
          erros: {
            [path]: value
          }
        },
        { deep: true }
      );
    },
    setCurrent(state, { path, value }) {
      return state.merge(
        {
          current: {
            [path]: value
          }
        },
        { deep: true }
      );
    },
    setFetchResult(state, { path, value }) {
      return state.merge(
        {
          result: {
            [path]: value
          }
        },
        { deep: true }
      );
    },
    setPartialOpen(state, { path, value }) {
      return state.merge(
        {
          partialOpen: {
            [path]: value
          }
        },
        { deep: true }
      );
    },
    clearSaveResult(state) {
      return state.merge({
        saveResult: {
          saveCollectionHeader: false
        }
      });
    },
    setLoading(state, { path, value }) {
      return state.merge(
        {
          loading: {
            [path]: value
          }
        },
        { deep: true }
      );
    },
    setData(state, data) {
      return state.merge(
        {
          data: {
            pipeline: get(data, 'capsuleComponents', {
              canvas: {
                edges: [],
                nodes: []
              }
            })
          }
        },
        { deep: true }
      );
    },

    updatePipeline(state, data) {
      return state.merge(
        {
          data: {
            pipeline: data
          }
        },
        { deep: true }
      );
    },

    setSaving(state, saving) {
      return state.merge({ saving });
    },

    setAccounts(state, accounts) {
      return state.setIn(['accounts'], accounts);
    },

    updateCapsuleSettings(
      state,
      { description, inSpec, outSpec, documentation, jsonSchema, accounts }
    ) {
      return state
        .setIn(['accounts'], accounts)
        .setIn(['data', 'pipeline', 'description'], description)
        .setIn(['data', 'pipeline', 'inSpec'], inSpec)
        .setIn(['data', 'pipeline', 'outSpec'], outSpec)
        .setIn(['data', 'pipeline', 'documentation'], documentation)
        .setIn(
          ['data', 'pipeline', 'jsonSchema'],
          addAccountSectionOnFormSchema(jsonSchema, accounts)
        );
    }
  },
  effects: dispatch => ({
    async fetchData({ id, realm }) {
      try {
        const response = await api.capsules.get({ id, realm });
        const { jsonSchema } = response.data.capsuleComponents;
        if (jsonSchema) {
          const accountsSchema = jsonSchema.find(
            section => section.label === 'Accounts'
          );
          if (accountsSchema) {
            const accounts = accountsSchema.schema.map(inputSchema => ({
              name: inputSchema.label,
              description: inputSchema.description
            }));
            dispatch.capsule.setAccounts(accounts);
          }
        }
        dispatch.capsule.setData(response.data);
      } catch (e) {
        return toast.error(e.message);
      }
    },
    async createCollectionGroup(payload, { application }) {
      try {
        const { realm } = application.realm;
        const response = await api.capsules.createCollectionGroup({
          realm,
          collectionId: payload.collectionId,
          group: {
            title: payload.title
          }
        });

        const fetchCollectionGroup = new schema.Entity('fetchCollectionGroup');
        const source = normalize(
          response.createCollectionGroup,
          fetchCollectionGroup
        );
        dispatch.capsules.addNormalizeGroup(source);
        dispatch.capsule.setSaveResult({
          path: 'createCollectionGroup',
          value: {
            ...response?.createCollectionGroup,
            collectionId: payload.collectionId,
            setFieldValue: payload.setFieldValue
          }
        });
      } catch (e) {
        dispatch.capsule.setErro({
          path: 'createCollectionGroup',
          value: e.message
        });
        return toast.error(e.message);
      }
    },
    async fetchCollectionGroup(payload, { application }) {
      try {
        const { realm } = application.realm;
        const response = await api.capsules.fetchCollectionGroup({
          realm,
          collectionId: payload
        });
        dispatch.capsule.setFetchResult({
          path: 'fetchCollectionGroup',
          value: response?.collectionGroups || []
        });
      } catch (e) {
        dispatch.capsule.setErro({
          path: 'fetchCollectionGroup',
          value: e.message
        });
      }
    },
    async editCollectionHeader(payload, { application, authentication }) {
      try {
        const { realm } = application.realm;
        const { token } = authentication.userData;
        const fileName = generatorIconName(payload.title, 'jpg');
        const iconNotBase64 = payload?.icon?.replace(
          /^data:image\/[a-z]+;base64,/,
          ''
        );

        const responseImages = await api.images.save({
          token,
          realm,
          fileName,
          image: iconNotBase64
        });

        const response = await api.capsules.editCollectionHeader({
          realm,
          header: {
            id: payload?.id,
            title: payload.title,
            iconURL: responseImages?.url,
            iconName: responseImages?.contentName
          }
        });
        payload?.formik?.resetForm();
        dispatch.capsule.setSaveResult({
          path: 'saveCollectionHeader',
          value: {
            ...response.createCollectionHeader,
            setFieldValue: payload?.setFieldValue,
            formikHeader: payload.formik
          }
        });
        dispatch.snackbar.create({
          text: i18n.t('noun.collection_header_update_successfully')
        });
      } catch (e) {
        dispatch.capsule.setErro({
          path: 'saveCollectionHeader',
          value: e.message
        });
        dispatch.snackbar.create({
          text: e.message
        });
      }
    },
    async saveCollectionHeader(payload, { application, authentication }) {
      try {
        const { realm } = application.realm;
        const { token } = authentication.userData;
        const fileName = generatorIconName(payload.title, 'jpg');
        const responseImages = await api.images.save({
          token,
          realm,
          fileName,
          image: payload?.icon.replace(/^data:image\/[a-z]+;base64,/, '')
        });
        const response = await api.capsules.saveCollectionHeader({
          realm,
          header: {
            title: payload.title,
            iconURL: responseImages.url,
            iconName: responseImages.contentName
          }
        });
        payload?.formik?.resetForm();
        dispatch.capsule.setSaveResult({
          path: 'saveCollectionHeader',
          value: {
            ...response.createCollectionHeader,
            setFieldValue: payload?.setFieldValue,
            formikHeader: payload.formik
          }
        });
        dispatch.snackbar.create({
          text: i18n.t('noun.collection_header_update_successfully')
        });
      } catch (e) {
        dispatch.capsule.setErro({
          path: 'saveCollectionHeader',
          value: e.message
        });
        return toast.error(e.message);
      }
    },
    async saveCollection(payload, { application }) {
      try {
        const { realm } = application.realm;
        const capsuleCatalog = {
          ...payload,
          name: slugify(payload.title)
        };

        const { data } = await api.capsules.createNewCapsule({
          realm,
          capsule: capsuleCatalog
        });
        if (!payload.id) {
          dispatch.capsules.addCollection(data.createNewCapsule);
        } else {
          dispatch.capsules.editCollection(data.createNewCapsule);
        }
        dispatch.snackbar.create({
          text: payload?.id
            ? i18n.t('scenes.capsules.messages.success.collection_updated')
            : i18n.t('scenes.capsules.messages.success.collection_created')
        });
        dispatch.capsule.setSaveResult({
          path: 'saveCollection',
          value: {
            ...data.createNewCapsule,
            setField: payload?.setField,
            formik: payload.formik,
            type: payload.type
          }
        });
      } catch (e) {
        dispatch.capsule.setErro({
          path: 'saveCollection',
          value: e.message
        });
        return toast.error(e.message);
      }
    },
    async savePipeline(payload, { application, cy, capsule, testLibrary }) {
      const { data } = capsule;
      const { realm } = application.realm;
      const { force, title, description, isNewCapsule, collection, formik } = {
        force: false,
        ...payload
      };
      const capsuleCollectionGroup = payload.group
        ? {
            title: payload?.group?.title,
            id: payload?.group?.value
          }
        : data?.pipeline?.capsuleCollectionGroup;

      const iconName = payload?.icon
        ? payload?.icon?.value
        : data?.pipeline?.iconName;
      omit(payload, 'force');

      const jsonSchema = data.pipeline.jsonSchema || [];
      const capsuleData = {
        ...omit(data.pipeline, ['errorDetail']),
        canvas: prepare(cy.present.getIn(['root'])),
        configSpec: getInitialValues(jsonSchema),
        title: title || data.pipeline.title,
        name: title ? slugify(title) : data.pipeline.name,
        description: description || data.pipeline.description,
        capsuleCollectionId:
          collection?.value || data.pipeline.capsuleCollectionId,
        capsuleCollectionGroup,
        iconName,
        tags: [],
        jsonSchema: addAddons(addStepName(jsonSchema)),
        force,
        testMode: {
          code: testLibrary.field.code,
          autoForm: testLibrary.field.autoForm
        }
      };

      // TODO: improve this condition
      if (isEmpty(payload.title) && typeof capsuleData.id !== 'string')
        return dispatch.design.openSave();

      dispatch.design.setSaving(true);
      try {
        const response = await api.capsules.saveComponent({
          realm,
          capsule: capsuleData
        });
        const { updateCapsule } = response.data;
        dispatch.design.setForceModalOpened(false);
        if (updateCapsule.id) {
          dispatch.router.navigate({
            to: `/${realm}/design/capsules/${updateCapsule.id}`
          });
          toast.success(
            i18n.t('scenes.capsule.messages.prompt.capsule_success_update')
          );
        }
        return dispatch.capsule.updatePipeline(updateCapsule);
      } catch (error) {
        const hasToUpgrade = /code 406/g.test(error.toString());

        if (hasToUpgrade) {
          return dispatch.design.setForceModalOpened(true);
        }

        if (isNewCapsule) {
          formik.setFieldValue('isNewCapsule', false);
          formik.setFieldValue('capsuleID', capsuleData.capsuleCollectionId);
        }

        return toast.error(error.message);
      } finally {
        dispatch.design.setSaving(false);
      }
    },
    updateTrigger(value, rootState) {
      const { normalizedPipeline } = rootState.capsule;
      const setNode = (node = SeamlessImmutable({}), data) => node.merge(data);
      const currentTriggerPath = ['nodes', 0];
      const updated = normalizedPipeline.updateIn(
        currentTriggerPath,
        setNode,
        value
      );
      return dispatch.capsule.setNormalizedPipeline(updated);
    }
  }),
  logics: [
    {
      type: 'capsule/reset',
      latest: true,
      process(context, dispatch, done) {
        dispatch.cy.reset();
        done();
      }
    },
    {
      type: 'capsule/setData',
      latest: true,
      process(context, dispatch, done) {
        const { payload } = context.action;
        dispatch.testLibrary.setFieldCode(
          payload.capsuleComponents.testMode?.code || {
            value: '{}',
            error: false
          }
        );
        dispatch.capsules.setCurrent({
          path: 'capsules',
          value: payload.capsuleComponents.capsuleCollectionId
        });
        dispatch.testLibrary.setFieldAutoForm(
          payload.capsuleComponents.testMode?.autoForm
        );
        done();
      }
    },
    {
      type: 'capsule/savePipeline',
      latest: true,
      process(context, dispatch, done) {
        dispatch.capsule.setSaving(true);
        done();
      }
    },
    {
      type: 'capsule/updateCapsuleSettings',
      latest: true,
      process(context, dispatch, done) {
        dispatch.design.closeSettings();
        done();
      }
    },

    {
      type: 'capsule/updatePipeline',
      latest: true,
      process({ action, getState }, dispatch, done) {
        dispatch.design.closeSave();
        dispatch.pipelineExperimental.setSaving(false);
        const { capsuleCollectionId } = action.payload;
        dispatch.capsules.fetchComponents({
          id: capsuleCollectionId
        });
        done();
      }
    },
    {
      type: 'capsule/saveCollection',
      latest: true,
      process({ action, getState }, dispatch, done) {
        dispatch.capsule.setLoading({
          path: 'saveCollection',
          value: true
        });
        done();
      }
    },
    {
      type: 'capsule/fetchCollectionGroup',
      latest: true,
      process({ action, getState }, dispatch, done) {
        dispatch.capsule.setLoading({
          path: 'fetchCollectionGroup',
          value: true
        });
        done();
      }
    },
    {
      type: 'capsule/saveCollectionHeader',
      latest: true,
      process({ action, getState }, dispatch, done) {
        dispatch.capsule.setLoading({
          path: 'saveCollectionHeader',
          value: true
        });
        done();
      }
    },
    {
      type: 'capsule/setErro',
      latest: true,
      async process({ action }, dispatch, done) {
        const { payload } = action;
        dispatch.capsule.setLoading({
          path: payload.path,
          value: false
        });
        done();
      }
    },
    {
      type: 'capsule/setFetchResult',
      latest: true,
      async process({ action }, dispatch, done) {
        const { payload } = action;
        dispatch.capsule.setLoading({
          path: payload.path,
          value: false
        });
        done();
      }
    },
    {
      type: 'capsule/setSaveResult',
      latest: true,
      async process({ action }, dispatch, done) {
        const { payload } = action;
        if (['saveCollectionHeader'].includes(payload.path)) {
          await dispatch.capsules.fetchCollectionHeader();
          dispatch.capsule.setPartialOpen({
            path: 'saveCollectionHeader',
            value: false
          });
          dispatch.capsules.setModal({
            path: 'collectionHeader',
            value: { isOpen: false, entity: null }
          });
          if (payload?.value?.setFieldValue) {
            payload?.value?.setFieldValue('headerId', {
              label: payload?.value?.title,
              value: payload?.value?.id
            });
          }

          dispatch.capsule.setCurrent({
            path: 'saveCollectionHeader',
            value: payload.value
          });
        }
        if (['createCollectionGroup'].includes(payload.path)) {
          await dispatch.capsule.fetchCollectionGroup(
            payload?.value?.collectionId
          );
          if (payload?.value?.setFieldValue) {
            payload.value.setFieldValue('group', {
              label: payload?.value?.title,
              value: payload?.value.id
            });
          }
          dispatch.capsule.setPartialOpen({
            path: 'createCollectionGroup',
            value: false
          });
          dispatch.capsule.setPartialOpen({
            path: 'saveGroup',
            value: false
          });
        }
        if (['saveCollection'].includes(payload.path)) {
          if (['capsule'].includes(payload?.type)) {
            await dispatch.capsules.fetchCapsules();
            await dispatch.capsule.fetchCollectionGroup(payload?.value?.id);
          }
          if (['capsules'].includes(payload?.value?.type)) {
            dispatch.capsules.setCurrent({
              path: 'capsules',
              value: payload?.value?.id
            });
          }
          dispatch.capsules.setModal({
            path: 'collectionEdit',
            value: {
              isOpen: false,
              entity: null
            }
          });
          if (payload?.value?.setField) {
            payload.value.setField('collection', {
              label: payload?.value?.title,
              value: payload?.value?.id
            });
          }
          dispatch.capsule.setPartialOpen({
            path: payload.path,
            value: false
          });
        }
        dispatch.capsule.setLoading({
          path: payload.path,
          value: false
        });
        done();
      }
    }
  ]
};

export default capsuleModel;
