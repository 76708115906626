import {
  DataGrid,
  IconButton,
  Icon,
  Text,
  SideSheet,
  Dialog,
  Alert,
  Button,
  Box,
  Tooltip,
  Empty
} from '@digibee/beehive-ui';
import { iff } from '@digibee/control-statements';
import { faBook } from '@fortawesome/pro-regular-svg-icons';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import Header from './components/Header'
import * as Elements from './LimitOfUse.elements';
import type { Props } from './LimitOfUse.enhancer';
import { TrafficLight, HelpText } from '../../components';
import Upsell from '../Upsell';

import type { HealthStatus, Limit } from '~/api/license';
// @ts-ignore
import emptyStateList from '~/common/images/empty-state-list.svg';
// @ts-ignore
import pictureNoResult from '~/common/images/empty-state-runtime-no-result.svg';
import SmallSelect from '~/components/SmallSelect';



const LimitOfUse = ({
  loadingLimitOfUse,
  limitOfUse,
  environment,
  environments,
  isLimitDetails,
  isGlobal,
  search,
  setSearch,
  goLimitDetails,
  goBackLimits,
  getSelectedLimit,
  setEnvironment,
  translatedLimits,
  translatedUnits,
}: Props) => {
  const [t] = useTranslation();
  return (
    <>
      <Elements.Container>
        <Elements.Content>
          <Elements.SelectEnviroment>
            <SmallSelect
              options={environments}
              value={environment}
              onChange={setEnvironment}
              align='left'
            />
          </Elements.SelectEnviroment>

          <Text variant='h4'>
            {isGlobal ? t('label.global_capacity') : t('label.local_capacity')}
          </Text>
          <Text
            variant='b2'
            css={{ marginTop: '$2', marginBottom: '$6' }}
            as='p'
          >
            {isGlobal
              ? t('label.global_capacity_explanation')
              : t('label.local_capacity_explanation')}
            <Elements.Link
              target='_blank'
              data-testid='limit-of-use-doc-link'
              href={t('action.capacity_and_quota_doc_url')}
            >
              {t('action.capacity_and_quota_doc_link')}
            </Elements.Link>
          </Text>

          {!isGlobal && <Header onChange={({ pipeline, status }: { pipeline: string, status?: string[] }) => setSearch({ pipeline, status })} />}

          <Box css={{ marginBottom: '$6' }}>
            <Dialog.Root
              id='licesing-limit-of-use-dialog-upsell'
              onOpenChange={goBackLimits}
            >
              {iff(
                limitOfUse?.usageLimits?.some(
                  limit => limit.healthStatus === 'RED'
                ) || false,
                () => (
                  <Alert
                    title={t('label.reached_quota_msg_warning')}
                    variant='warning'
                    right={
                      <Dialog.Trigger asChild>
                        <Button outlined variant='warning'>
                          {t('action.upgrade')}
                        </Button>
                      </Dialog.Trigger>
                    }
                    css={{ marginBottom: '$6' }}
                    size='small'
                  />
                )
              )}

              <Elements.DialogContent>
                <Elements.DialogBody>
                  <Upsell environment={environment} />
                </Elements.DialogBody>
              </Elements.DialogContent>
            </Dialog.Root>
          </Box>

          <DataGrid
            initialState={{
              // @ts-ignore
              hiddenColumns: [
                isGlobal && 'pipeline_project'
              ]
            }}
            isPagination
            pageSize={10}
            loading={loadingLimitOfUse}
            data-testid='licensing-limit-of-use-table'
            data={limitOfUse?.usageLimits || []}
            columns={[
              {
                id: 'resource_name',
                Header: t('label.resource_name'),
                accessor: 'name',
                Cell: ({ value }: { value: string }) =>
                  translatedLimits(value).explanation ? (
                    <HelpText
                      text={t(
                        translatedLimits(value).explanation_tooltip || ''
                      )}
                    >
                      {t(translatedLimits(value).name)}
                    </HelpText>
                  ) : (
                    <>{t(translatedLimits(value).name)}</>
                  )
              },
              {
                id: 'health_status',
                Header: t('label.health_status'),
                accessor: 'healthStatus',
                Cell: ({ value }: { value: HealthStatus }) => (
                  <TrafficLight status={value} />
                )
              },
              {
                id: 'pipeline_project',
                Header: t('label.pipeline'),
                Cell: ({ row }: { row: { original: Limit } }) => (
                  <>
                    <Box css={{ fontSize: '$1', color: '#000' }}>
                      {row?.original?.pipeline}
                    </Box>
                    <Box css={{ fontSize: '$1', color: '$neu300' }}>
                      {row?.original?.project}
                    </Box>
                  </>
                )
              },
              {
                id: 'acquired_quota',
                Header: t('label.limit'),
                accessor: 'myLimit',
                Cell: ({ value }: { value: string | number }) => (
                  <>
                    {Number(value).toLocaleString() === 'NaN'
                      ? value
                      : Number(value).toLocaleString()}
                  </>
                )
              },
              {
                id: 'quota_in_use',
                Header: t('label.in_use'),
                accessor: 'limitUsed',
                Cell: ({ value }: { value: string | number }) =>
                  value ? (
                    <>
                      {Number(value).toLocaleString() === 'NaN'
                        ? value
                        : Number(value).toLocaleString()}
                    </>
                  ) : (
                    '-'
                  )
              },
              {
                id: 'next_update',
                Header: t('label.next_update'),
                accessor: 'nextUpdate',
                Cell: ({ value }: { value: string }) =>
                  value ? (
                    <>
                      <Box css={{ fontSize: '$1', color: '#000' }}>
                        {moment(value).format('HH:mm')}
                      </Box>
                      <Box css={{ fontSize: '$1', color: '$neu300' }}>
                        {moment(value).format('DD/MM/YYYY')}
                      </Box>
                    </>
                  ) : (
                    '-'
                  )
              },
              {
                id: 'unit_of_measurement',
                Header: t('label.unit_of_measurement'),
                accessor: 'unitOfMeasure',
                Cell: ({ value }: { value: string }) => (
                  <>{t(translatedUnits(value))}</>
                )
              },
              {
                id: 'action',
                accessor: 'name',
                Header: t('label.actions'),
                Cell: ({ value, row }: { value: string; row: any }) => (
                  <Tooltip
                    align='center'
                    content={t('label.see_details_tooltip')}
                    side='bottom'
                    data-testid={`licesing-limit-of-use-view-details-${value}-tooltip`}
                  >
                    <IconButton
                      onClick={() => goLimitDetails(value)}
                      size='medium'
                      data-testid={`licesing-limit-of-use-view-details-${value}`}
                      disabled={!row?.original?.healthStatus}
                    >
                      <Icon icon='eye' size='md' />
                    </IconButton>
                  </Tooltip>
                )
              }
            ]}
          />
          {(!limitOfUse?.usageLimits?.length && !loadingLimitOfUse && (!search?.pipeline && !search?.status?.length)) &&
            <Empty
              illustration={emptyStateList}
              title={t('label.no_items_on_list')}
              data-testid='limit-of-use-no-items-empty'
              buttonHide
            />
          }
          {(!limitOfUse?.usageLimits?.length && !loadingLimitOfUse && (search?.pipeline || search?.status?.length)) &&
            <Empty
              illustration={pictureNoResult}
              title={t('label.we_couldnt_find_results_title_empty')}
              message={t('label.we_couldnt_find_results_try_again_msg_empty')}
              data-testid='limit-of-use-no-items-empty'
              buttonHide
            />
          }

        </Elements.Content>
      </Elements.Container>
      <SideSheet
        open={isLimitDetails}
        header={t(getSelectedLimit()?.title || getSelectedLimit()?.name)}
        subHeader={t(getSelectedLimit()?.explanation)}
        onClose={goBackLimits}
        data-testid='licesing-limit-of-use-sidesheet-details'
        size='medium'
        actions={
          <>
            <SideSheet.Actions>
              <Tooltip
                align='end'
                side='bottom'
                content={t('label.open_documentation_tooltip')}
              >
                <a
                  target='_blank'
                  href={t(getSelectedLimit()?.docUrl)} rel='noreferrer'
                >
                  <Elements.IconFontAwesome icon={faBook} />
                </a>
              </Tooltip>
            </SideSheet.Actions>
          </>
        }
      >
        <React.Fragment key='.0'>
          <Outlet />
        </React.Fragment>
      </SideSheet>
    </>
  );
};

export default LimitOfUse;
