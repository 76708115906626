import Flow, { Component, Level } from '@digibee/flow';
import { useActor, useInterpret } from '@xstate/react';
import { CSSProperties, useCallback, useEffect, useState } from 'react';
import { AutoSizer, List } from 'react-virtualized';
import { createContext } from 'use-context-selector';
import { ActorRefFrom } from 'xstate';

import Node, { TreeNode } from './Node';
import treeMachine from './Tree.machine';
import useTreeContext from './utils/useTreeContext';
import { useRegisterActorOnEventBus } from '../../../../../Bus/bus.machine';

import { TriggerSpec } from '~/entities/TriggerSpec';
import featureFlagConstants from '~/common/helpers/featureFlagConstants';
import useFeatureFlag from '~/common/hooks/useFeatureFlag';
import { Connectors } from '@digibee/linter';

export type TreeActor = ActorRefFrom<typeof treeMachine>;

type slotRenderProps = {
  item: any;
};

type FlowTreeSlots = {
  actionsSlot?: (props: slotRenderProps) => JSX.Element;
};

export type FlowTreeContextType = FlowTreeSlots & {
  actor: TreeActor;
  mapConnectorImage: MapConnectorImage;
  onItemDoubleClick: (item: any) => void;
  goToLevel: (node: TreeNode<Level>) => void;
};

export type ShapeType = 'ellipse' | 'diamond' | 'round-pentagon' | 'hexagon';

export const FlowTreeContext = createContext<FlowTreeContextType>({
  actor: {} as TreeActor,
  mapConnectorImage: () => ({ shape: 'ellipse', image: 'ellipse' }),
  onItemDoubleClick: () => {},
  goToLevel: () => {}
});

export type MapConnectorImage = (item: Component | string) => {
  shape: ShapeType;
  image: string;
};

type FlowTreeProps = FlowTreeSlots & {
  flow: Flow;
  currentLevelName: string;
  triggerSpec?: TriggerSpec;
  onInit?: (actor: TreeActor) => any;
  mapConnectorImage: MapConnectorImage;
  onItemDoubleClick?: (item: any) => void;
  goToLevel?: (node: TreeNode<Level>) => void;
  withoutStarterNode?: boolean;
  defaultCollectionData?: Connectors;
  shouldInspectDebugLayout: boolean;
};

const Index = ({
  shouldInspectDebugLayout
}: {
  shouldInspectDebugLayout: boolean;
}) => {
  const actor = useTreeContext(state => state.actor);
  const [state] = useActor(actor);
  const [scrollPosition, setScrolPosition] = useState(0);
  const { list } = state.context;

  const rowRenderer = useCallback(
    ({ index, style }: { index: number; style: CSSProperties }) => {
      const node = list[index];

      return (
        <div key={node.id} style={style}>
          <Node
            node={node}
            shouldInspectDebugLayout={shouldInspectDebugLayout}
          />
        </div>
      );
    },
    [list]
  );

  const handleScroll = useCallback(
    ({
      scrollTop,
      clientHeight,
      scrollHeight
    }: {
      clientHeight: number;
      scrollHeight: number;
      scrollTop: number;
    }) => {
      const isValidPosition =
        scrollTop + clientHeight < scrollHeight ||
        scrollTop === scrollHeight - clientHeight;
      const newScrollPosition = isValidPosition
        ? scrollTop
        : scrollHeight - clientHeight;
      setScrolPosition(newScrollPosition);
    },
    []
  );

  if (state.matches('loading')) return null;

  return (
    <AutoSizer>
      {({ height, width }) => (
        <List
          width={width}
          height={height}
          rowCount={list.length}
          rowHeight={shouldInspectDebugLayout ? 32 : 24}
          rowRenderer={rowRenderer}
          scrollTop={scrollPosition}
          onScroll={handleScroll}
        />
      )}
    </AutoSizer>
  );
};

const FlowTree = ({
  flow,
  currentLevelName,
  triggerSpec,
  onInit,
  actionsSlot,
  mapConnectorImage = () => ({ shape: 'ellipse', image: '' }),
  onItemDoubleClick = () => {},
  goToLevel = () => {},
  withoutStarterNode = false,
  defaultCollectionData,
  shouldInspectDebugLayout
}: FlowTreeProps) => {
  const { BUILD_UNIFIED_CATALOG } = featureFlagConstants;
  const { treatments } = useFeatureFlag([BUILD_UNIFIED_CATALOG]);

  const isUnifiedCatalog = treatments[BUILD_UNIFIED_CATALOG].treatment === 'on';

  const actor = useInterpret(treeMachine, {
    context: { flow, triggerSpec, withoutStarterNode }
  });

  useRegisterActorOnEventBus(actor);

  useEffect(() => {
    onInit?.(actor);
  }, []);

  useEffect(() => {
    actor.send({ type: 'SET_FLOW', flow, levelName: currentLevelName });
  }, [flow]);

  useEffect(() => {
    actor.send({ type: 'SET_CURRENT_LEVEL', levelName: currentLevelName });
  }, [currentLevelName]);

  useEffect(() => {
    if (!isUnifiedCatalog) {
      if (triggerSpec) actor.send({ type: 'SET_TRIGGER_SPEC', triggerSpec });
    }
  }, [triggerSpec, isUnifiedCatalog]);

  useEffect(() => {
    if (isUnifiedCatalog && defaultCollectionData) {
      if (triggerSpec) actor.send({ type: 'SET_TRIGGER_SPEC', triggerSpec });
    }
  }, [triggerSpec, isUnifiedCatalog, defaultCollectionData]);

  return (
    <FlowTreeContext.Provider
      value={{
        actor,
        actionsSlot,
        mapConnectorImage,
        onItemDoubleClick,
        goToLevel
      }}
    >
      <Index shouldInspectDebugLayout={shouldInspectDebugLayout} />
    </FlowTreeContext.Provider>
  );
};

export default FlowTree;
