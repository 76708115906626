import { Component } from '@digibee/flow';

const aiSuggestionExcludeList = [
  'log-connector',
  'throw-error-connector',
  'script-connector',
  'delayer',
  'capsule',
  'library'
];

export function componentToNameMapper(component: Component): string {
  const componentSpec = component.spec();
  const name =
    componentSpec.name?.toLowerCase() || componentSpec.type?.toLowerCase();

  const { type } = componentSpec;

  if (type === 'capsule') return 'capsule';

  return name;
}

export function getPreviousComponents(
  component: Component,
  quantity: number,
  onlySameLevel = false
): Component[] {
  const level = component.parentLevel();

  function getPreviousComponentsRec(
    currentComponent: Component,
    currentQuantity: number,
    previousComponents: Component[]
  ): Component[] {
    if (currentQuantity === 0) return previousComponents;

    const previousComponent = currentComponent.prev();

    if (!previousComponent) return previousComponents;
    if (onlySameLevel && previousComponent.parentLevel() !== level) {
      return previousComponents;
    }
    const isValidConnectorType = !aiSuggestionExcludeList.includes(
      componentToNameMapper(previousComponent)
    );

    if (isValidConnectorType) {
      previousComponents.push(previousComponent);
    }

    return getPreviousComponentsRec(
      previousComponent,
      isValidConnectorType ? currentQuantity - 1 : currentQuantity,
      previousComponents
    );
  }

  const result = getPreviousComponentsRec(component, quantity, []);
  // @ts-ignore
  return result.toReversed();
}

export const getLastComponentsNames = (currentComponent: Component) => {
  const previousComponents = getPreviousComponents(
    currentComponent,
    Infinity,
    true
  );
  const componentName = componentToNameMapper(currentComponent);
  return previousComponents
    .map(componentToNameMapper)
    .concat(
      aiSuggestionExcludeList.includes(componentName) ? [] : componentName
    );
};
