import React from 'react';

import { ConfirmDialogConfig } from '../type/ConfirmDialog';

type ConfirmDialogValue = {
  open: boolean;
  setOpen: (open: boolean) => void;
  config: ConfirmDialogConfig;
  setConfig: (config: ConfirmDialogConfig) => void;
};

export const ConfirmDialogContext = React.createContext<ConfirmDialogValue>({
  open: false,
  setOpen: () => {},
  config: {
    content: '',
    onConfirm: () => {},
    onCancel: () => {},
    title: '',
    cancelText: '',
    confirmText: '',
    danger: false,
    withExplanation: false,
    hideCancel: false,
    explanationPlaceholder: '',
    explanationLabel: '',
    showEnvironmentTag: false
  },
  setConfig: () => {}
});
