/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable no-nested-ternary */
import { debounce } from 'lodash';
import { Dispatch, SetStateAction, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { user as userApi } from '~/api';
import withHooks from '~/common/hoc/withHooks';
import { StoreDispatcher } from '~/scenes/Governance/types/StoreDispatcher';
import { StoreRootState } from '~/scenes/Governance/types/StoreRootState';

export type Props = {
  userForm: UserForm,
  userId: StoreRootState['profile']['user']['id'],
  modal: StoreRootState['organization']['modalOrganization'],
  organization: StoreRootState['organization']['organization'],

  closeModal: () => void,
  loadUsers: (
    search: string,
    options: Array<unknown>,
    { searchPage, page, previousSearch }: { searchPage: number, page: number, previousSearch: string }
  ) => any,
  patchGroup: StoreDispatcher['organization']['patchGroup'],
  setUserForm: Dispatch<SetStateAction<UserForm>>,
}

type User = {
  id: string,
  email: string,
  firstName: string,
  lastName: string,
}

type Option = {
  label: string,
  value: string,
}

type UserForm = {
  realm: Option | null,
  user: Option & { email: string } | null,
}

const enhancer = withHooks(() => {
  const { modal, organization, userId } = useSelector((state: StoreRootState) => ({
    userId: state.profile?.user?.id,
    modal: state.organization.modalOrganization,
    organization: state.organization.organization,
  }))
  const dispath = useDispatch<StoreDispatcher>();
  const [userForm, setUserForm] = useState<UserForm>({
    realm: null,
    user: null,
  });

  const normalizeUsers = (users?: Array<User>): Array<{ label: string, value: string, email: string }> =>
    users?.map((user) => ({ label: user.email, value: user.id, email: user.email })) || []

  async function loadUsers(
    search: string,
    _: Array<unknown>,
    { searchPage, page, previousSearch }: { searchPage: number, page: number, previousSearch: string },
  ) {
    return new Promise((resolve, reject) =>
      debounce(async () => {
        try {
          const request = await userApi.fetch({
            realm: userForm.realm?.value,
            search: {
              beta: true,
              filter: search,
              page: search
                ? previousSearch === search
                  ? ++searchPage
                  : 0
                : ++page,
              size: 10,
            }
          });

          const options = normalizeUsers(request?.data?.searchUsers?.content);

          const hasMore = !request?.data?.searchUsers?.last;

          resolve({
            options,
            hasMore,
            additional: {
              page: !search ? request?.data?.searchUsers?.number : page,
              searchPage: search
                ? previousSearch === search
                  ? request?.data?.searchUsers?.number
                  : 0
                : -1,
              previousSearch: search
            }
          });
        } catch (error) {
          reject();
        }
      }, 1000)()
    );
  }

  const props: Props = {
    modal,
    userId,
    organization,
    loadUsers,
    userForm,
    setUserForm,
    closeModal: () =>
      dispath.organization.setModalOrganization({
        data: [],
        realmsGroup: [],
        selectedGroupName: '',
        loading: false,
        visible: false
      }),
    patchGroup: dispath.organization.patchGroup,
  };

  return props;
});

export default enhancer;
