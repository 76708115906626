// eslint-disable-next-line no-redeclare
/* global document */

import cytoscape from 'cytoscape';
import dagre from 'cytoscape-dagre';
import { get } from 'lodash';
import { v4 as uuid } from 'uuid';

// eslint-disable-next-line import/named
import getDefaultStyle from './canvasStyle';

cytoscape.use(dagre);

const layoutOptions = {
  maxZoom: 1,
  minLen(edge) {
    if (
      get(edge.json().data, 'data.type') !== 'choice' &&
      get(edge.json().data, 'data.type') !== 'parallel-execution-connector'
    )
      return 1;
    return 4;
  },
  name: 'dagre',
  nodeDimensionsIncludeLabels: true,
  rankDir: 'LR'
};

function getStyleConnectors(components) {
  const getSize = shape => {
    switch (shape) {
      case 'round-diamond':
        return {
          width: '90px',
          height: '90px'
        };
      case 'round-rectangle':
        return {
          width: '78px',
          height: '78px'
        };
      case 'diamond':
        return {
          width: '90px',
          height: '90px'
        };
      case 'round-pentagon':
        return {
          width: '84px',
          height: '80px',
          'background-position-y': '2px'
        };
      case 'hexagon':
        return {
          width: '90px',
          height: '76px',
          'background-position-y': '0px'
        };
      default:
        return {};
    }
  };

  return components.map(component => {
    const icon = component.iconURL
      ? component.iconURL
      : `data:image/png;base64,${component.icon}`;
    function renderNode() {
      return `data:image/svg+xml;charset=utf-8,${component.iconURL}`;
    }
    return {
      selector: `node[key = '${component.key}']`,
      style: {
        shape: component.shape,
        'background-image': ['hexagon'].includes(component.shape)
          ? renderNode
          : icon,
        'background-width': '108%',
        'background-height': '108%',
        ...getSize(component.shape)
      }
    };
  });
}

const initialState = {
  components: [],
  libraries: [],
  size: {
    height: '1080px',
    width: '1920px'
  },
  triggerOptions: {
    imageWhenNoConfigured: '/assets/connectors/trigger.svg'
  },
  triggers: []
};

// eslint-disable-next-line func-names
export default function (params) {
  const { components, data, libraries, triggers, triggerOptions, size } =
    Object.assign(initialState, params);

  const container = document.createElement('div');

  container.setAttribute('id', `cy-${uuid()}`);

  container.style.height = size.height;
  container.style.width = size.width;

  container.style.position = 'absolute';
  container.style.left = '-9999px';

  document.body.appendChild(container);

  const style = [
    ...getDefaultStyle(),
    ...getStyleConnectors(components),
    ...getStyleConnectors(libraries),
    ...getStyleConnectors(triggers)
  ];

  style.push({
    selector:
      'node[type = "trigger@not-configured"], node[type = "start-track"]',
    style: {
      'background-image': triggerOptions.imageWhenNoConfigured
    }
  });

  // Options for constructor new instance cytoscape.
  const options = {
    container,
    style
  };

  return new Promise(resolve => {
    const cy = cytoscape(options);

    cy.add(data);
    cy.layout(layoutOptions).run();

    cy.scopeDestroy = cy.destroy;

    cy.destroy = () => {
      cy.scopeDestroy();
      cy.container().parentNode.removeChild(cy.container());
    };

    cy.ready(() => resolve(cy));
  });
}
