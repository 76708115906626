import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Operation from '../Others/scenes/App/Operation';

import featureFlagConstants from '~/common/helpers/featureFlagConstants';
import i18n from '~/common/helpers/i18n';
import useFeatureFlag from '~/common/hooks/useFeatureFlag';
import asyncComponent from '~/components/AsyncComponent';

const Overview = asyncComponent(
  () => import('~/scenes/Monitor/scenes/Overview')
);

const ExecutionFinished = asyncComponent(
  () => import('~/scenes/Monitor/scenes/ExecutionFinished')
);

const ExecutionInflight = asyncComponent(
  () => import('~/scenes/Runtime/scenes/ExecutionInflight')
);

const PipelineLogs = asyncComponent(
  () => import('~/scenes/Monitor/scenes/PipelineLogs')
);

const AlertsConfigMonitorOLD = asyncComponent(
  () => import('~/scenes/Monitor/scenes/AlertsConfigMonitorOLD')
);

const AlertsConfigMonitor = asyncComponent(
  () => import('~/scenes/Monitor/scenes/AlertsConfigMonitor')
);

const Metrics = asyncComponent(() => import('~/scenes/Monitor/scenes/Metrics'));
const MetricsV2 = asyncComponent(
  () => import('~/scenes/Monitor/scenes/MetricsV2')
);

const DashboardVPN = asyncComponent(
  () => import('~/scenes/Monitor/scenes/DashboardVPN')
);
/**
 * This is a wrapper that reloads the page when the user navigates back to the paths specified in the `pathnameEndingsToReload` array.
 * Used as a workaround for the issue that Chart.js tries to create charts before the DOM is fully loaded.
 */
const ReloadWrapper = ({ element }: { element: React.ReactNode }) => {
  const location = useLocation();
  const pathnameEndingsToReload = ['/pipeline-metrics'];

  useEffect(() => {
    const handleNavigation = () => {
      if (
        pathnameEndingsToReload.some(ending =>
          location.pathname.endsWith(ending)
        )
      ) {
        window.location.reload();
      }
    };

    window.addEventListener('popstate', handleNavigation);
  }, [location]);

  return <>{element}</>;
};

export const MonitorRoutes = () => {
  const { application } = useSelector<any, any>(state => state);

  const environment = application.environment;

  const { MONITOR_ALERT_V2, MONITOR_METRICS_V2, MONITOR_DASHBOARD_VPN } =
    featureFlagConstants;
  const { treatments } = useFeatureFlag([
    MONITOR_ALERT_V2,
    MONITOR_METRICS_V2,
    MONITOR_DASHBOARD_VPN
  ]);

  const showAlertV2 = treatments[MONITOR_ALERT_V2].treatment === 'on';
  const showMetricsV2 = treatments[MONITOR_METRICS_V2].treatment === 'on';
  const showDashboardVPN = ['on', 'mock'].includes(
    treatments[MONITOR_DASHBOARD_VPN].treatment
  );

  const links = [
    {
      'data-testid': 'operation-header-monitor-link-overview',
      routeName: 'monitor-overview',
      isNotBorder: true,
      path: `operation/monitor/${environment}/overview`,
      name: i18n.t('scenes.beta.labels.upper_and_capitalize.capitalized', {
        item: `${i18n.t('noun.overview')}`
      })
    },
    {
      'data-testid': 'operation-header-monitor-link-execution_finish',
      routeName: 'monitor-execution-finish',
      path: `operation/monitor/${environment}/execution-finish`,
      name: i18n.t('scenes.beta.labels.upper_and_capitalize.capitalized', {
        item: `${i18n.t('noun.execution_finish_tab_title')}`
      })
    },
    {
      'data-testid': 'operation-header-monitor-link-pipeline_logs',
      routeName: 'monitor-pipeline-logs',
      path: `operation/monitor/${environment}/pipeline-logs`,
      name: i18n.t('scenes.beta.labels.upper_and_capitalize.capitalized', {
        item: `${i18n.t('noun.logs_tab_title')}`
      })
    },
    {
      'data-testid': 'operation-header-monitor-link-pipeline_metrics',
      routeName: 'monitor-pipeline-metrics',
      path: `operation/monitor/${environment}/pipeline-metrics`,
      name: i18n.t('scenes.beta.labels.upper_and_capitalize.capitalized', {
        item: `${i18n.t('noun.pipeline_metrics')}`
      })
    },
    {
      roles: [`ALERT:READ`],
      'data-testid': 'operation-header-monitor-link-alerts-config',
      routeName: 'monitor-alerts-config',
      path: `operation/monitor/alerts-config/${environment}`,
      name: i18n.t('scenes.beta.labels.upper_and_capitalize.capitalized', {
        item: `${i18n.t('noun.alerts_configuration')}`
      })
    }
  ];

  if (showDashboardVPN) {
    links.push({
      roles: [`VPN_METRIC:READ`],
      'data-testid': 'operation-header-monitor-link-dashboard-vpn',
      routeName: 'monitor-dashboard-vpn',
      path: `operation/monitor/dashboard-vpn/${environment}`,
      name: i18n.t('scenes.beta.labels.upper_and_capitalize.capitalized', {
        item: `${i18n.t('noun.dashboard_vpn')}`
      })
    });
  }

  return [
    {
      path: '/:realm/operation/monitor',
      element: (
        <Operation
          links={links}
          environmentSelectRouteWhiteList={[
            'monitor-overview',
            'monitor-execution-finish',
            'monitor-pipeline-logs',
            'monitor-pipeline-metrics',
            'monitor-alerts-config'
          ]}
        />
      ),
      children: [
        {
          path: 'alerts-config/:environment',
          name: 'monitor-alerts-config',
          title: i18n.t('label.alerts_configuration_tab'),
          element: (
            <ReloadWrapper
              element={
                showAlertV2 ? (
                  <AlertsConfigMonitor />
                ) : (
                  <AlertsConfigMonitorOLD />
                )
              }
            />
          )
        },
        {
          path: ':environment/overview',
          name: 'monitor-overview',
          title: i18n.t('label.monitor_overview_tab'),
          element: <ReloadWrapper element={<Overview />} />
        },
        {
          path: ':environment/execution-finish',
          name: 'monitor-execution-finish',
          title: i18n.t('label.completed_exec_tab'),
          element: <ReloadWrapper element={<ExecutionFinished />} />
        },
        {
          path: ':environment/execution-inflight',
          name: 'monitor-execution-inflight',
          title: i18n.t('label.running_exec_tab'),
          element: <ReloadWrapper element={<ExecutionInflight />} />
        },
        {
          path: ':environment/pipeline-logs',
          name: 'monitor-pipeline-logs',
          title: i18n.t('label.pipe_logs_tab'),
          element: <ReloadWrapper element={<PipelineLogs />} />
        },
        {
          path: ':environment/pipeline-metrics',
          name: 'monitor-pipeline-metrics',
          title: i18n.t('label.pipe_metrics_tab'),
          element: (
            <ReloadWrapper
              element={showMetricsV2 ? <MetricsV2 /> : <Metrics />}
            />
          )
        },
        ...(showDashboardVPN
          ? [
              {
                path: 'dashboard-vpn/:environment',
                name: 'dashboard-vpn',
                title: i18n.t('label.dashboard_vpn_tab'),
                element: <ReloadWrapper element={<DashboardVPN />} />
              }
            ]
          : [])
      ]
    }
  ];
};

export default ReloadWrapper;
