import { Empty, Icon, IconButton, SearchBar } from '@digibee/beehive-ui';
import { iff } from '@digibee/control-statements';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as Elements from './UsersList.elements';

import theme from '~/common/styled/theme';
import { OrganizationGroupUser } from '~/scenes/Governance/models/organization';
import { StoreDispatcher } from '~/scenes/Governance/types/StoreDispatcher';

type Props = {
  userId: string,
  loading: boolean,
  groupName: string,
  users: Array<OrganizationGroupUser>,
  patchGroup: StoreDispatcher['organization']['patchGroup'],
}

const UsersList = ({
  users,
  userId,
  loading,
  patchGroup,
  groupName,
}: Props) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');

  const filteredUsers = users.length ? users.filter((user) => user?.email?.includes(search)) : null;

  return (
    <Elements.UsersWrapper direction='column'>
      <Elements.UsersListTitle>
        {t('scenes.groups.labels.assigned_members')}
      </Elements.UsersListTitle>
      <Elements.SearchBarWrapper>
        <SearchBar
          placeholder={t('label.search_user_by_email') || ''}
          onChange={(value) => setSearch(value)}
          textValue={search}
        />
      </Elements.SearchBarWrapper>
      {iff(!users.length, () => (
        <Empty
          illustration={theme.images.emptyStateList}
          title={t('scenes.consumers.guidance.empty_list_prompt') || ''}
          buttonHide
        />
      ))}
      {iff(!!filteredUsers && !filteredUsers.length, () => (
        <Empty
          illustration={theme.images.emptySearch}
          message={`${t('common.guidance.not_found_title')} ${search}`}
          buttonHide
        />
      ))}
      {filteredUsers
        ?.map((user) => (
          <Elements.UserInfo justify='between' align='center' key={user.userId}>
            <Elements.UserData direction='column'>
              <span>{t('common.labels.realm')}: {user.realm}</span>
              <h2>{user.email}</h2>
            </Elements.UserData>
            {iff(userId !== user.userId, () => (
              <IconButton
                disabled={loading}
                onClick={() => {
                  patchGroup({
                    groupName,
                    isRemoving: true,
                    users: users.filter((userToFilter) => userToFilter.userId !== user.userId),
                  });
                }}
              >
                <Icon variant='danger' icon='times' size='lg' />
              </IconButton>
            ))}
          </Elements.UserInfo>
        ))}
    </Elements.UsersWrapper>
  )
}

export default memo(UsersList);
