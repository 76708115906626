import styled, { css } from 'styled-components';

import SmallSelectNative from '~/components/SmallSelect';
import TextNative from '~/components/Text';

export const SmallSelect = styled(SmallSelectNative)`
  text-transform: capitalize;
`;

export const Text = styled(TextNative)<{ active?: boolean }>`
  opacity: 0.5;

  ${props =>
    props.active
      ? css`
          opacity: 1 !important;
          color: ${props.theme.colors.pri500} !important;
        `
      : ''}

  &:hover {
    opacity: 1 !important;
    color: ${props => props.theme.colors.pri500} !important;
  }
`;

export const Actions = styled.div`
  right: 24px;
  bottom: -18px;
  position: absolute;
  z-index: 49;
`;

export const WrapperEnvironment = styled.div`
  height: 40px;
  width: 40px;
  position: absolute;
  bottom: -65px;
  left: 0;
`;

export const WrapperTag = styled.div`
  display: flex;
  align-items: center;
`;

export const WrapperDeployment = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.pri500};
`;
