import { useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';

import FormFirstAccess from './FormFirstAccess';
import FormForgotPassword from './FormForgotPassword';
import FormIdentityProvider from './FormIdentityProvider';
import FormLoginV4 from './FormLoginV4';
import FormRealm from './FormRealm';
import FormResetPassword from './FormResetPassword';
import FormUserBlocked from './FormUserBlocked';
import FormUserTemporaryLocked from './FormUserTemporaryLocked';
import FormUserUnblocked from './FormUserUnblocked';
import TwoFactorAlert from './TwoFactorAlert';

import { StoreRootState } from '~/scenes/Governance/types/StoreRootState';

const RouteComponent = () => {
  const { authentication: { firstaccess } } = useSelector((state: StoreRootState) => state);
  const params = useParams();

  switch (params.actionId) {
    case 'forgotpassword':
      return <FormForgotPassword />;
    case 'changepassword':
      return firstaccess ? <FormFirstAccess /> : <Navigate to='/login' />;
    case 'resetpassword':
      return <FormResetPassword />;
    case 'realm':
      return <FormRealm />;
    case 'error':
      return <FormUserBlocked />;
    case 'success':
      return <FormUserUnblocked />;
    case 'temporary-locked':
      return <FormUserTemporaryLocked />;
    case 'identity-provider':
      return <FormIdentityProvider />;
    case 'two-factor':
      return <TwoFactorAlert />;
    default:
      return <FormLoginV4 />;
  }
};

export default RouteComponent;
