import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

import Header from './components/Header';

const Container = styled.div`
  overflow: hidden;
  height: 100vh;
`;

interface OperationProps {
  links?: any[];
  environmentSelectRouteWhiteList?: string[];
}

const Operation: FC<OperationProps> = ({
  links = [],
  environmentSelectRouteWhiteList = []
}) => (
  <Container>
    <div>
      <Header
        links={links}
        environmentSelectRouteWhiteList={environmentSelectRouteWhiteList}
      />
    </div>
    <Outlet />
  </Container>
);

export default Operation;
