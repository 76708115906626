import { iff } from '@digibee/control-statements';
import { isNumber, isString, isNaN, isFunction } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { v4 as uuid } from 'uuid';

// Locals
import Input from './components/BaseComponent';
import HelperTextNative from './components/HelperText';
import PlaceholderNative from './components/Placeholder';

import { border } from '~/common/styled/input';
import IconNative from '~/components/IconCommunity';

const ContainerIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: space-evenly;
  cursor: pointer;
  padding-right: 4px;
`;
const hover = ({ theme, disabled }) => css`
  ${!disabled &&
  css`
    &:hover {
      color: ${theme.colors.pri500};
    }
  `};
`;

const Icon = styled(IconNative)`
  padding-left: 8px;
  padding-right: 8px;
  margin-top: 2px;
  ${hover};
`;

const Space = styled.div`
  padding-top: 2px;
  width: 100%;
`;
const Placeholder = styled(PlaceholderNative)`
  user-select: none;
  width: auto !important;
`;

const HelperText = styled(HelperTextNative)`
  user-select: none;
`;
const Content = styled.div`
  ${border};
  height: 48px;
  position: relative;
  display: flex;
  align-items: center;
`;

const StyledContainer = styled.div``;

const useControlledState = (value, defaultValue) => {
  if (value === undefined) {
    const [state, setState] = useState(defaultValue);
    return [state, setState, false];
  }
  return [value, () => {}, true]; // just bypass
};

export const changeValue = ({ mod, value, max, min, step }) => {
  const newValue = calcValue(value, step, mod);
  const newStep = applyStep(step);
  const maxAndMinValue = setMaxOrMin(newValue, min, max);
  if (maxAndMinValue !== null) {
    return maxAndMinValue;
  }
  return newStep ? parseFloat(newValue.toFixed(newStep)) : newValue;
};

export const setMaxOrMin = (value, min, max) => {
  if (isNumber(max) && value > max) return max;
  if (isNumber(min) && value <= min) return min;
  return null;
};
export const calcValue = (value, step, mod) => {
  const event = 'up';
  return mod === event ? value + step : value - step;
};

export const applyStep = step => {
  if (!step) return 0;
  return (step.toString().split('.')[1] || []).length;
};
export const parseText = text => {
  if (isNumber(text)) return text;
  if (isString(text)) {
    const newText = text.trim();
    if (!newText) return '';
    const num = parseFloat(text);
    if (!isNaN(num)) {
      return num;
    }
  }
  return '';
};

const InputNumber = ({
  onChange,
  value,
  onKeyDown,
  max,
  min,
  step,
  placeholder,
  id,
  onFocus,
  onBlur,
  helperText,
  disabled,
  ...props
}) => {
  const [text, setText] = useControlledState(value, 0);
  const [isActived, setActived] = useState(false);
  const [isValue, setValue] = useState(isNumber(text));
  const [generatorId] = useState(uuid());
  const inputEl = useRef(null);
  useEffect(() => {
    setText(value);
  }, [value]);
  const move = {
    ArrowUp: () => {
      if (isFunction(onChange)) {
        const newValue = changeValue({
          mod: 'up',
          value: value !== '' ? value : 0,
          max,
          min,
          step
        });
        onChange(newValue);
        setValue(true);
      }
    },
    ArrowDown: () => {
      if (isFunction(onChange)) {
        const newValue = changeValue({
          mod: 'down',
          value: value !== '' ? value : 0,
          max,
          min,
          step
        });
        onChange(newValue);
        setValue(true);
      }
    }
  };
  const handlerBlur = event => {
    setActived(false);
    if (isFunction(onBlur)) {
      onBlur(event);
    }
  };
  const handlerFocus = event => {
    setActived(true);
    if (isFunction(onFocus)) {
      onFocus(event);
    }
  };
  const eventClick = type => {
    inputEl.current.focus();
    if (move[type]) {
      move[type]();
    }
  };
  const handleOnChange = text => {
    setValue(text.length !== 0);
    const value = parseText(text);
    setText(value);
    if (isFunction(onChange)) {
      onChange(value);
    }
  };

  const handleKeydown = event => {
    if (move[event.key]) {
      move[event.key]();
    }

    if (onKeyDown) {
      onKeyDown(event);
    }
  };
  return (
    <StyledContainer>
      <Content {...props} disabled={disabled}>
        <Input
          id={id || generatorId}
          onKeyDown={handleKeydown}
          onChange={handleOnChange}
          innerRef={inputEl}
          disabled={disabled ? 'disabled' : ''}
          value={text}
          onBlur={handlerBlur}
          onFocus={handlerFocus}
          {...props}
        />
        {iff(placeholder, () => (
          <Placeholder
            htmlFor={id || generatorId}
            isActived={isActived}
            isValue={isValue}
            disabled={disabled}
            {...props}
          >
            {placeholder}
          </Placeholder>
        ))}
        <ContainerIcon>
          <Icon
            disabled={disabled}
            name='AngleUp'
            onClick={!disabled ? () => eventClick('ArrowUp') : null}
          />
          <Space />
          <Icon
            disabled={disabled}
            name='AngleDown'
            onClick={!disabled ? () => eventClick('ArrowDown') : null}
          />
        </ContainerIcon>
      </Content>
      {iff(helperText, () => (
        <HelperText
          size='sm'
          align='left'
          disabled={disabled}
          isActived={isActived}
          as='span'
          {...props}
        >
          {helperText}
        </HelperText>
      ))}
    </StyledContainer>
  );
};

InputNumber.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  max: PropTypes.number,
  min: PropTypes.number,
  step: PropTypes.number,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  id: PropTypes.string,
  danger: PropTypes.bool,
  disabled: PropTypes.bool
};

InputNumber.defaultProps = {
  onChange: () => {},
  onKeyDown: () => {},
  danger: false,
  disabled: false,
  step: 1,
  value: 0
};

export default InputNumber;
