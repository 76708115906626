import { useEffect, useState, Dispatch } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useMatch, useParams } from 'react-router-dom';

import { translatedLimits, translatedUnits } from '../../helpers'

import type { LimitOfUse } from '~/api/license';
import withHooks from '~/common/hoc/withHooks';
import { useHeader } from '~/components/HeaderProvider';

export type Props = {
  limitOfUse?: LimitOfUse,
  environment: string,
  environments: Array<Option>,
  loadingLimitOfUse: boolean,
  isLimitDetails: boolean,
  setEnvironment: Dispatch<string>,
  translatedLimits: (key: string) => { name: string, explanation: string, docUrl: string, docLink: string, title?: string, explanation_tooltip?: string },
  translatedUnits: (key: string) => string,
  getSelectedLimit: () => { name: string, explanation: string, docUrl: string, docLink: string, title?: string },
  goBackLimits: () => void,
  goLimitDetails: (limit: string) => void,
  getLimitOfUse: Dispatch<{ environment: string, isGlobal: boolean, pipeline?: string, status?: string[] }>
  isGlobal: boolean,
  search: { pipeline?: string, status?: string[] } | null,
  setSearch: Dispatch<{ pipeline?: string, status?: string[] } | null>
}

export type Option = { label: string, value: string } | null;

const enhancer = withHooks(({ isGlobal }: { isGlobal: boolean }) => {
  const { license, application } = useSelector((state: any) => state);
  const [t] = useTranslation();

  const dispatch: any = useDispatch();
  const [environment, setEnvironment] = useState<string>('');
  const [search, setSearch] = useState<{ pipeline?: string, status?: string[] } | null>(null);
  const route = isGlobal ? 'limits/global' : 'limits/local';
  const isLimitDetails = !!useMatch(
    '/:realm/settings/limits/:typeoflimit/:limit/environment/:environment'
  );
  const { limit } = useParams();

  useHeader({
    configActionButton: [{
      text: t('label.usage_limits')
    }]
  });

  const props: Props = {
    search,
    limitOfUse: license?.limitOfUse,
    loadingLimitOfUse: license?.loadingLimitOfUse,
    environments: application?.environments.allNames?.map((e: string) => ({ label: e, value: e })),
    environment,
    isLimitDetails,
    isGlobal,
    setSearch,
    setEnvironment,
    translatedLimits,
    translatedUnits,
    getLimitOfUse: dispatch.license.getLimitOfUse,
    goLimitDetails: (limit) =>
      dispatch.router.navigate(`/${application.realm.realm}/settings/${route}/${limit}/environment/${environment}`),
    goBackLimits: () =>
      dispatch.router.navigate(`/${application.realm.realm}/settings/${route}`),
    getSelectedLimit: () => translatedLimits(limit || '')
  };

  useEffect(() => {
    if (!environment) setEnvironment(props?.environments[0]?.value || '');
  });

  useEffect(() => {
    if (environment) dispatch.license.getLimitOfUse({
      environment,
      isGlobal,
      pipeline: search?.pipeline,
      status: search?.status
    });
  }, [application?.realm?.realm, environment, isGlobal, search]);

  return {
    ...props,
  }
})

export default enhancer;
