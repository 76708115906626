import withHooks from '~/common/hoc/withHooks';

interface Props {
  onChange: Function;
}

const enhancer = withHooks(({ onChange, ...props }: Props) => {
  const actions = {
    search: async (e: any, tags: any) => {
      const statusArray = Array.isArray(tags) ? tags : tags ? [tags] : [];

      onChange({ search: { name: e, status: statusArray } });
    }
  };
  return {
    ...props,
    ...actions
  };
});

export default enhancer;
