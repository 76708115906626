import Immutable from 'seamless-immutable';
import { dashboardVPN } from '~/api';
import i18n from '~/common/helpers/i18n';
import queryString from 'query-string';
import moment from 'moment';

const getPropsSearch = () => {
  const params = queryString.parse(window.location.search.replace('?', ''));
  const SPECIFIC_DATE_FROM_DEFAULT = new Date();
  return {
    dateTo: params.dateTo || moment().toISOString(),
    dateFrom: params.dateFrom || moment().subtract(1, 'hour').toISOString(),
    interval: params.interval || '1h',
    label: Number(params.label) || 1,
    name: params.name || '1 hour',
    specific: {
      dateFrom: params.dateFrom || SPECIFIC_DATE_FROM_DEFAULT,
      dateTo: params.dateTo || SPECIFIC_DATE_FROM_DEFAULT
    }
  };
};

const initialState = Immutable({
  result: {
    tunnels: [],
    mappings: []
  },
  entities: {
    tunnels: {},
    mappings: {}
  },
  loading: false,
  error: null,
  environment: null,
  interval: getPropsSearch().interval,
  specific: getPropsSearch().specific,
  metrics: {},
  sortConfig: {
    key: '',
    direction: 'ascending'
  }
});

const dashboardVPNMonitorModel = {
  name: 'dashboardVPNMonitor',
  state: initialState,
  reducers: {
    setSort: (state, { key, direction }) =>
      state.merge({
        sortConfig: { key, direction }
      }),
    setSpecific: (state, specific) => state.merge({ specific }),
    setInterval: (state, interval) => state.merge({ interval }),
    setTunnels: (state, payload) => {
      const tunnels = Array.isArray(payload.getTunnels)
        ? payload.getTunnels
        : [];
      const tunnelIds = tunnels.map(tunnel => tunnel.id);
      const tunnelEntities = tunnels.reduce((acc, tunnel) => {
        acc[tunnel.id] = tunnel;
        return acc;
      }, {});
      return state.merge({
        result: {
          ...state.result,
          tunnels: tunnelIds
        },
        entities: {
          ...state.entities,
          tunnels: tunnelEntities
        }
      });
    },
    setMappings: (state, payload) => {
      const mappings = Array.isArray(payload.getMappings)
        ? payload.getMappings
        : [];
      const mappingIds = mappings.map(mapping => mapping.id);
      const mappingEntities = mappings.reduce((acc, mapping) => {
        acc[mapping.id] = mapping;
        return acc;
      }, {});
      return state.merge({
        result: {
          ...state.result,
          mappings: mappingIds
        },
        entities: {
          ...state.entities,
          mappings: mappingEntities
        }
      });
    },
    setLoading: (state, payload) =>
      state.merge({
        loading: payload
      }),
    setSuccess: (state, payload) => state.merge({ success: payload }),
    reset: () => initialState
  },
  effects: dispatch => ({
    async getTunnels({ sort, order = 'asc', mock = false }, rootState) {
      try {
        const { activeRealm: realm, environment } = rootState.application;
        dispatch.dashboardVPNMonitor.setLoading(true);
        let data = [];
        if (mock) {
          data = await dashboardVPN.getTunnelsMOCK({
            realm,
            environment,
            sort,
            order
          });
        } else {
          data = await dashboardVPN.getTunnels({
            realm,
            environment,
            sort,
            order
          });
        }
        data.getTunnels.forEach((item, index) => {
          item.id = index + 1;
        });

        dispatch.dashboardVPNMonitor.setTunnels(data);
      } catch (e) {
        dispatch.dashboardVPNMonitor.setModalLoading(e, {
          snackbar: {
            text: i18n.t('label.vpnlert_not_set_up_msg_vpn'),
            visible: true
          }
        });
      }
    },
    async getMappings({ sort, order = 'asc', mock = false }, rootState) {
      try {
        const { activeRealm: realm, environment } = rootState.application;
        dispatch.dashboardVPNMonitor.setLoading(true);
        let data = [];
        if (mock) {
          data = await dashboardVPN.getMappingsMOCK({
            realm,
            environment,
            sort,
            order
          });
        } else {
          data = await dashboardVPN.getMappings({
            realm,
            environment,
            sort,
            order
          });
        }

        data.getMappings.forEach((item, index) => {
          item.id = index + 1;
        });

        dispatch.dashboardVPNMonitor.setMappings(data);
      } catch (e) {
        dispatch.dashboardVPNMonitor.setModalLoading(e, {
          snackbar: {
            text: i18n.t('label.vpnlert_not_set_up_msg_vpn'),
            visible: true
          }
        });
      }
    }
  })
};

export default dashboardVPNMonitorModel;
