/* eslint-disable */
import { ActionCreators } from 'redux-undo';
import Immutable from 'immutable';
import { v4 as uuid } from 'uuid';

import api from './canvasApi';
import transformCanvas from './canvasTransform';

const EMPTY_CANVAS = Immutable.Map({
  nodes: [],
  edges: []
});

const DEFAULT_NESTED_CANVAS = Immutable.Map({
  edges: [],
  nodes: [
    {
      id: 'start-track',
      type: 'start-track@not-configured',
      data: {
        id: 'start-track',
        key: 'start-track@not-configured',
        type: 'start-track'
      },
      group: 'nodes'
    }
  ]
});

const initialState = Immutable.fromJS({
  canvas: EMPTY_CANVAS,
  canvasId: uuid(),
  connectorConfiguration: {
    component: {},
    element: {},
    schema: [],
    type: ''
  },
  breadcrumbs: [],
  init: true,
  root: EMPTY_CANVAS,
  scale: 1,
  triggerConfiguration: {
    component: {},
    element: {},
    schema: [],
    type: ''
  }
});

const CanvasModel = {
  name: 'cy',
  state: initialState,
  reducers: {
    breadcrumbsDecrement(state, data) {
      return state.updateIn(['breadcrumbs'], breadcrumbs =>
        breadcrumbs.slice(0, data + 1)
      );
    },

    breadcrumbsIncrement(state, data) {
      return state.updateIn(['breadcrumbs'], breadcrumbs =>
        breadcrumbs.push(data)
      );
    },

    breadcrumbsReset(state) {
      return state.set('breadcrumbs', Immutable.List([]));
    },

    resetEntryConfigurations(state) {
      return state.set({
        connectorConfiguration: {
          element: {},
          component: {},
          schema: [],
          type: ''
        },
        triggerConfiguration: {
          element: {},
          component: {},
          schema: [],
          type: ''
        }
      });
    },

    setCanvas(state, canvas) {
      return state.set('canvas', transformCanvas(canvas));
    },

    setInit(state, value) {
      return state.set('init', value);
    },

    setReset() {
      return initialState;
    },

    /**
     * @description Set all pipeline object with nodes and edges on first level.
     *
     * @param  {object} state - Access current state for this reducer;
     * @param  {object} root - New object root for set complete pipeline/library;
     */

    setRoot(state, root) {
      return state.set('root', Immutable.fromJS(root));
    },

    setScale(state, scale) {
      return state.set('scale', scale);
    },

    setConnectorConfiguration(state, data) {
      return state.set('connectorConfiguration', Immutable.fromJS(data));
    },

    resetConnectorConfiguration(state) {
      return state.set(
        'connectorConfiguration',
        Immutable.fromJS({
          component: {},
          element: {},
          schema: [],
          type: ''
        })
      );
    },

    setTriggerConfiguration(state, { component, element, schema, type }) {
      return state.set(
        'triggerConfiguration',
        Immutable.fromJS({
          element,
          component,
          schema,
          type
        })
      );
    },

    updateCanvas(state, canvas) {
      return state.set('canvas', Immutable.fromJS(canvas));
    }
  },
  effects: dispatch => ({
    clearHistory: () => {
      dispatch(ActionCreators.clearHistory());
    },

    init: data => {
      dispatch.cy.updateCanvas(data);
    },

    redo: () => {
      dispatch(ActionCreators.redo());
    },

    reset: () => {
      dispatch(ActionCreators.clearHistory());
      dispatch(dispatch.cy.setReset());
    },

    // eslint-disable-next-line no-unused-vars
    undo: (keepFuture = true) => {
      dispatch(ActionCreators.undo());
      // TODO: Limpar de alguma forma apenas o futuro.
      // if (keepFuture === false) dispatch(ActionCreators.clearHistory());
    },

    updateElements: (data, { cy }) => {
      const canvas = cy.present.getIn(['canvas']);

      if (Immutable.is(canvas, Immutable.fromJS(data))) return;

      dispatch.cy.updateCanvas(data);
    },

    updateElement: (data, { cy }) => {
      const connectorConfiguration = cy.present
        .get('connectorConfiguration')
        .toJS();

      const canvas = cy.present.getIn(['canvas']);

      const configuration =
        data.id === 'trigger' ? data : connectorConfiguration;

      const groupType = (() => {
        if (data.id === 'trigger') return 'nodes';
        return configuration.type === 'node' ? 'nodes' : 'edges';
      })();

      // This is a fix for retrocompatibility in copy & paste.
      const index = canvas
        .getIn([groupType])
        .findIndex(node =>
          [
            node.getIn(['data', 'id']),
            node.getIn(['data', 'data', 'id'])
          ].includes(configuration.element.id)
        );

      // This is path for update data in an specific element (node/edge).
      const crumbs = (() => {
        const arr = [];

        arr.push(groupType, index);
        arr.push('data');

        if (groupType === 'edges') arr.push('data');

        return arr;
      })();

      const value = (() => {
        if (data.id === 'trigger') return Immutable.fromJS(data.element.data);
        if (groupType === 'nodes') return data;
        if (configuration.component.name === 'parallel-execution-connector') {
          return Object.assign({ type: 'parallel-execution-connector' }, data);
        }
        return Object.assign({ type: 'choice' }, data);
      })();

      dispatch.cy.updateCanvas(
        canvas.updateIn(crumbs, current => {
          if (!current) return Immutable.Map(value);
          if (data.id === 'trigger') return value;
          return current.merge(value);
        })
      );
      dispatch.cy.resetEntryConfigurations();
    },

    updateTrigger(element) {
      dispatch.cy.updateElement({ id: 'trigger', element });
    }
  }),
  logics: [
    {
      type: ['cy/breadcrumbsReset'],
      latest: true,
      process({ getState }, dispatch, done) {
        const state = getState().cy.present;

        dispatch.cy.updateCanvas(state.get('root'));

        return done();
      }
    },
    {
      type: ['cy/breadcrumbsIncrement', 'cy/breadcrumbsDecrement'],
      latest: true,
      process({ getState }, dispatch, done) {
        const state = getState().cy.present;

        const data = (() => {
          // This is path for get data in root.
          const crumbs = (() => {
            const arr = [];
            arr.push('root');
            arr.push(
              ...state
                .getIn(['breadcrumbs'])
                .map(({ path }) => path)
                .join('.')
                .split('.')
            );

            return arr;
          })();

          const canvas = state.getIn(crumbs);

          if (Immutable.Map(canvas).isEmpty()) return DEFAULT_NESTED_CANVAS;

          return canvas;
        })();

        return api({
          data: data.toJS()
        }).then(cy => {
          dispatch.cy.updateCanvas({
            edges: cy.edges().jsons(),
            nodes: cy.nodes().jsons()
          });
          cy.destroy();
          return done();
        });
      }
    },
    {
      type: 'cy/setRoot',
      latest: true,
      process({ getState }, dispatch, done) {
        const { init } = getState().cy.present.toJS();

        if (init === true) {
          dispatch.cy.setInit(false);
          dispatch(ActionCreators.clearHistory());
        }

        return done();
      }
    },
    {
      type: 'cy/updateCanvas',
      latest: true,
      process({ getState }, dispatch, done) {
        const { breadcrumbs, root } = getState().cy.present.toJS();
        const canvas = getState().cy.present.getIn(['canvas']);

        if (breadcrumbs.length === 0) return dispatch.cy.setRoot(canvas);

        // This is path for update in root.
        const crumbs = breadcrumbs
          .map(({ path }) => path)
          .join('.')
          .split('.');

        dispatch.cy.setRoot(Immutable.fromJS(root).setIn(crumbs, canvas));

        return done();
      }
    },
    {
      type: ['cy/updateElement'],
      latest: true,
      process({ getState }, dispatch, done) {
        const state = getState().cy.present;

        if (state.get('breadcrumbs').size === 0) {
          dispatch.cy.setRoot(state.get('canvas'));
          return done();
        }

        // This is path for update in root.
        const crumbs = (() => {
          const arr = [];
          arr.push('root');
          arr.push(
            ...state
              .get('breadcrumbs')
              .map(({ path }) => path)
              .join('.')
              .split('.')
          );
          return arr;
        })();

        dispatch.cy.setRoot(
          state.setIn(crumbs, state.get('canvas')).get('root')
        );

        return done();
      }
    },
    {
      type: 'cy/updateTrigger',
      latest: true,
      process({ action }, dispatch, done) {
        dispatch.cy.setTriggerConfiguration(action.payload.data);

        return done();
      }
    },
    {
      type: 'design/closeConnectorConfiguration',
      latest: true,
      process(context, dispatch, done) {
        dispatch.cy.resetConnectorConfiguration();
        return done();
      }
    }
  ]
};

export default CanvasModel;
