import { Button, Flex, Select, SelectAsync, theme } from '@digibee/beehive-ui';

export const Wrapper = theme.styled('div', {
  marginTop: '$4',
  width: '100%'
});

export const FormWrapper = theme.styled(Flex, {
  width: '100%',
  gap: '$3',
  '> div': {
    width: '48%'
  }
});

export const RealmSelect = theme.styled(Select, {
  width: '100%'
});

export const UserSelectAsync = theme.styled(SelectAsync, {
  width: '100%',
});

export const AddButton = theme.styled(Button, {
  marginBottom: '5px',
});
