import Immutable from 'seamless-immutable';

import { StoreDispatcher } from '../types/StoreDispatcher';
import { StoreRootState } from '../types/StoreRootState';

import { organization as api } from '~/api';
import i18n from '~/common/helpers/i18n';

const hasDuplicatedUsers = (users: Array<OrganizationGroupUser>) => {
  const valueArr = users.map((user) => user.userId);
  const isDuplicate = valueArr.some((item, index) => valueArr.indexOf(item) !== index);

  return isDuplicate;
}

export type OrganizationGroupUser = {
  userId?: string,
  realm?: string,
  realmId?: string,
  email?: string,
}

export type OrganizationGroupRealm = {
  id: string,
  name: string,
};

export type OrganizationGroup = {
  name: string,
  description: string,
  custom: false,
  users: Array<OrganizationGroupUser>,
  realms: Array<OrganizationGroupRealm>,
  permissions: Array<string>
}

export type Organization = {
  id: string,
  name: string,
  customerTogaiId: string,
  realmsId: Array<string>,
  groups: Array<OrganizationGroup>
}

export type OrganizationState = {
  loading: boolean,
  error: any,
  organization: Organization | null,
  modalOrganization: {
    visible: boolean,
    loading: boolean,
    selectedGroupName: string,
    realmsGroup: Array<OrganizationGroupRealm> | null,
    data: Array<OrganizationGroupUser> | null,
  },

  merge: (payload: unknown) => OrganizationState
}

const initialState = Immutable({
  loading: false,
  error: null,
  organization: null,
  modalOrganization: {
    visible: false,
    loading: false,
    selectedGroupName: '',
    realmsGroup: null,
    data: null,
  }
}) as OrganizationState;

const organization = {
  name: 'organization',
  state: initialState,
  reducers: {
    setOrganization: (state: OrganizationState, payload?: Organization) => state.merge({ organization: payload }),
    loading: (state: OrganizationState, value: boolean) => state.merge({ loading: value }),
    setModalOrganization: (state: OrganizationState, payload: OrganizationState['modalOrganization']) => state.merge({
      modalOrganization: {
        ...state.modalOrganization,
        ...payload
      }
    }),
    setLoadingModal: (state: OrganizationState, payload: boolean) => state.merge({
      modalOrganization: {
        ...state.modalOrganization,
        loading: payload,
      }
    }),
    setError: (state: OrganizationState, error: any) => state.merge({
      error
    }),

    reset: () => initialState
  },

  effects: (dispatch: StoreDispatcher) => ({
    async fetch(_: unknown, state: StoreRootState) {
      const currentRealm = state.application.realm.realm;
      if (!currentRealm) return;
      try {
        dispatch.organization.loading(true);

        const { data } = await api.fetch(currentRealm, state.authentication.userData.token);

        dispatch.organization.setOrganization(data);
      } catch (error) {
        dispatch.organization.setOrganization(null);
        dispatch.snackbar.create({
          text: i18n.t('label.organization_fetch_error')
        });
      } finally {
        dispatch.organization.loading(false);
      }
    },
    async patchGroup(params: { groupName: string, users: Array<OrganizationGroupUser>, isRemoving: boolean }, state: StoreRootState) {
      if (!params.isRemoving && hasDuplicatedUsers(params.users)) {
        dispatch.snackbar.create({
          text: i18n.t('label.duplicated_user')
        });
        return;
      }

      try {
        dispatch.organization.setLoadingModal(true);

        await api.patchGroup(
          state.realms.getRealm?.name || '',
          state.organization.organization?.name || '',
          params.groupName,
          params.users,
          state.authentication.userData.token
        );

        dispatch.organization.fetch();
        dispatch.organization.setModalOrganization({
          data: params.users,
          loading: false,
          realmsGroup: state.organization.modalOrganization.realmsGroup || [],
          selectedGroupName: state.organization.modalOrganization.selectedGroupName,
          visible: state.organization.modalOrganization.visible,
        });
        dispatch.snackbar.create({
          text: params.isRemoving ? i18n.t('label.success_delete_user') : i18n.t('label.success_add_user')
        });
      } catch (error) {
        dispatch.snackbar.create({
          text: params.isRemoving ? i18n.t('label.error_remove_user') : i18n.t('label.error_add_user')
        });
        dispatch.organization.setError(error);
      } finally {
        dispatch.organization.setLoadingModal(false);
      }
    },
  }),
  logics: []
};

export default organization;
