import { choose, when } from '@digibee/control-statements';
import {
  BaseComponent,
  Condition,
  Execution,
  Level,
  Track
} from '@digibee/flow';
import { memo } from 'react';

import ComponentNode from './nodes/ComponentNode';
import ConditionNode from './nodes/ConditionNode';
import DisconnectedTrackNode from './nodes/DisconnectedTrackNode';
import LevelNode from './nodes/LevelNode';
import StarterNode from './nodes/StarterNode';
import TriggerNode from './nodes/TriggerNode';

export type TreeNode<T = any> = {
  id: string;
  label: string;
  item: T;
  depth: number;
  parentId?: string;
  children?: TreeNode<T>[];
};

type NodeProps = {
  node: TreeNode;
  shouldInspectDebugLayout: boolean;
};

const Node = (props: NodeProps) => {
  const { item } = props.node;

  return choose(
    when(item === 'trigger', () => <TriggerNode {...props} />),
    when(item === 'starter-node', () => <StarterNode {...props} />),
    when(item instanceof Condition || item instanceof Execution, () => (
      <ConditionNode {...props} />
    )),
    when(item instanceof Level, () => <LevelNode {...props} />),
    when(
      item === 'disconnected-group' ||
        (item instanceof Track && item.isDisconnected()),
      () => <DisconnectedTrackNode {...props} />
    ),
    when(item instanceof BaseComponent, () => <ComponentNode {...props} />)
  );
};

export default memo(Node, (prevProps, nextProps) => {
  const { item: prevItem } = prevProps.node;
  const { item: nextItem } = nextProps.node;

  if (prevItem instanceof BaseComponent) {
    return prevItem.spec().stepName === nextItem.spec().stepName;
  }

  return false;
});
