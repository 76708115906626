// import Immutable from 'seamless-immutable';
import { get, isEmpty, omit } from 'lodash';
import { toast } from 'react-toastify';
import SeamlessImmutable from 'seamless-immutable';
import slugify from 'underscore.string/slugify';
import { v4 as uuid } from 'uuid';

import { prepare } from './pipelineExperimental';

import * as api from '~/api';
import addAccountSectionOnFormSchema from '~/common/helpers/addAccountSectionOnFormSchema';
import i18n from '~/common/helpers/i18n';
import getInitialValues from '~/components/_oldComponents/FantaForm/helpers/getInitialValues';

const stepNameField = [
  {
    property: 'stepName',
    label: 'Step Name',
    type: 'string',
    input: 'text',
    default: '',
    validations: {
      type: 'string',
      options: [
        {
          type: 'required'
        },
        {
          type: 'max',
          limit: 60
        }
      ]
    }
  }
];

const addStepName = formSchema => {
  const [first, ...rest] = removeStepName(formSchema);
  const formSchemaWithStepName = [
    {
      schema: stepNameField.concat(first.schema)
    },
    ...rest
  ];
  return formSchemaWithStepName;
};

const removeStepName = schemas =>
  schemas.map(schema => ({
    ...schema,
    schema: schema?.schema?.filter(s => s.property !== 'stepName')
  }));

const addAddons = formSchema => {
  const [first, ...rest] = formSchema;
  const addons = {
    intellisense: {
      active: true
    }
  };

  const formSchemaWithAddons = [
    {
      schema: first.schema.map(inputSchema => ({
        ...inputSchema,
        addons
      }))
    },
    ...rest
  ];

  return formSchemaWithAddons;
};

const initialState = SeamlessImmutable({
  accounts: [],
  data: {
    sensitiveFields: {
      logSensitiveFields: []
    },
    pipeline: {
      name: '',
      title: '',
      description: '',
      documentation: '',
      jsonSchema: [
        {
          schema: [
            {
              property: 'params.param_a',
              label: 'PARAM A',
              type: 'string',
              input: 'text',
              default: 'lorem'
            },
            {
              property: 'params.param_b',
              label: 'PARAM B',
              type: 'string',
              input: 'text',
              default: 'ipsum'
            }
          ]
        }
      ],
      canvas: {
        nodes: [],
        edges: []
      },
      inSpec: {},
      outSpec: {},
      state: 'DEVELOPING'
    }
  },
  mustUpgrade: false,
  saving: false,
  trackingId: uuid()
});

const LibraryModel = {
  name: 'library',
  state: initialState,
  reducers: {
    reset() {
      return initialState;
    },

    setData(state, data) {
      return state.merge(
        {
          data: {
            pipeline: get(data, 'libraryComponents', {
              canvas: {
                edges: [],
                nodes: []
              }
            })
          }
        },
        { deep: true }
      );
    },

    updatePipeline(state, data) {
      return state.merge(
        {
          data: {
            pipeline: data
          }
        },
        { deep: true }
      );
    },

    setSaving(state, saving) {
      return state.merge({ saving });
    },

    setAccounts(state, accounts) {
      return state.setIn(['accounts'], accounts);
    },

    updateLibrarySettings(
      state,
      { description, inSpec, outSpec, documentation, jsonSchema, accounts }
    ) {
      return state
        .setIn(['accounts'], accounts)
        .setIn(['data', 'pipeline', 'description'], description)
        .setIn(['data', 'pipeline', 'inSpec'], inSpec)
        .setIn(['data', 'pipeline', 'outSpec'], outSpec)
        .setIn(['data', 'pipeline', 'documentation'], documentation)
        .setIn(
          ['data', 'pipeline', 'jsonSchema'],
          addAccountSectionOnFormSchema(jsonSchema, accounts)
        );
    }
  },
  effects: dispatch => ({
    async fetchData({ id, realm }) {
      const response = await api.libraries.get({ id, realm });
      const { jsonSchema } = response.data.libraryComponents;
      if (jsonSchema) {
        const accountsSchema = jsonSchema.find(
          section => section.label === 'Accounts'
        );
        if (accountsSchema) {
          const accounts = accountsSchema.schema.map(inputSchema => ({
            name: inputSchema.label,
            description: inputSchema.description
          }));
          dispatch.library.setAccounts(accounts);
        }
      }
      dispatch.library.setData(response.data);
    },

    async savePipeline(payload, { application, cy, library, testLibrary }) {
      const { data } = library;
      const { realm } = application.realm;
      const {
        force,
        title,
        description,
        isNewLibrary,
        newLibrary,
        libraryID,
        formik
      } = { force: false, ...payload };

      omit(payload, 'force');

      const jsonSchema = data.pipeline.jsonSchema || [];

      const libraryData = {
        ...omit(data.pipeline, ['errorDetail']),
        canvas: prepare(cy.present.getIn(['root'])),
        configSpec: getInitialValues(jsonSchema),
        title: title || data.pipeline.title,
        name: title ? slugify(title) : data.pipeline.name,
        description: description || data.pipeline.description,
        tags: [],
        jsonSchema: addAddons(addStepName(jsonSchema)),
        force,
        testMode: {
          code: testLibrary.field.code,
          autoForm: testLibrary.field.autoForm
        }
      };

      try {
        if (!libraryData.idCatalog) {
          if (isNewLibrary) {
            const libraryCatalog = {
              ...newLibrary,
              name: slugify(newLibrary.title)
            };

            const { data } = await api.libraries.createNewLibrary({
              realm,
              library: libraryCatalog
            });

            libraryData.idCatalog = data.createNewLibrary.id;
            dispatch.libraries.fetchLibraries();
          } else {
            libraryData.idCatalog = libraryID;
          }
        }
      } catch (error) {
        return toast.error(error.message);
      }

      // TODO: improve this condition
      if (isEmpty(payload.title) && typeof libraryData.id !== 'string')
        return dispatch.design.openSave();

      dispatch.design.setSaving(true);
      try {
        const response = await api.libraries.saveComponent({
          realm,
          library: libraryData
        });
        const { updateLibrary } = response.data;
        dispatch.design.setForceModalOpened(false);
        if (updateLibrary.id) {
          dispatch.router.navigate({
            to: `/${realm}/design/libraries/${updateLibrary.id}`
          });
          toast.success(
            i18n.t('scenes.library.messages.prompt.capsule_success_update')
          );
        }

        return dispatch.library.updatePipeline(updateLibrary);
      } catch (error) {
        const hasToUpgrade = /code 406/g.test(error.toString());

        if (hasToUpgrade) {
          return dispatch.design.setForceModalOpened(true);
        }

        if (isNewLibrary) {
          formik.setFieldValue('isNewLibrary', false);
          formik.setFieldValue('libraryID', libraryData.idCatalog);
        }

        return toast.error(error.message);
      } finally {
        dispatch.design.setSaving(false);
      }
    },

    updateTrigger(value, rootState) {
      const { normalizedPipeline } = rootState.library;
      const setNode = (node = SeamlessImmutable({}), data) => node.merge(data);
      const currentTriggerPath = ['nodes', 0];
      const updated = normalizedPipeline.updateIn(
        currentTriggerPath,
        setNode,
        value
      );
      return dispatch.library.setNormalizedPipeline(updated);
    }
  }),
  logics: [
    {
      type: 'library/reset',
      latest: true,
      process(context, dispatch, done) {
        dispatch.cy.reset();
        done();
      }
    },
    {
      type: 'library/setData',
      latest: true,
      process(context, dispatch, done) {
        const { payload } = context.action;
        dispatch.testLibrary.setFieldCode(
          payload.libraryComponents.testMode?.code || {
            value: '{}',
            error: false
          }
        );
        dispatch.libraries.setCurrent({
          path: 'libraries',
          value: payload.libraryComponents.idCatalog
        });
        dispatch.testLibrary.setFieldAutoForm(
          payload.libraryComponents.testMode?.autoForm
        );
        done();
      }
    },
    {
      type: 'library/savePipeline',
      latest: true,
      process(context, dispatch, done) {
        dispatch.library.setSaving(true);
        done();
      }
    },
    {
      type: 'library/updateLibrarySettings',
      latest: true,
      process(context, dispatch, done) {
        dispatch.design.closeSettings();
        done();
      }
    },
    {
      type: 'library/updatePipeline',
      latest: true,
      process({ action, getState }, dispatch, done) {
        dispatch.design.closeSave();
        dispatch.pipelineExperimental.setSaving(false);
        const { idCatalog } = action.payload;
        dispatch.libraries.fetchComponents({
          id: idCatalog
        });
        done();
      }
    }
  ]
};

export default LibraryModel;
