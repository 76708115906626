import { Text } from '@digibee/beehive-ui';
import { choose, iff, otherwise, when } from '@digibee/control-statements';
import { Condition, Execution } from '@digibee/flow';
import {
  faChevronRight,
  faChevronDown,
  faCodeBranch
} from '@fortawesome/pro-regular-svg-icons';
import {
  faCaretDown,
  faCaretRight,
  faSplit
} from '@fortawesome/pro-solid-svg-icons';
import { shallowEqual, useSelector } from '@xstate/react';

import { TreeNode } from '../Node';
import * as Elements from '../Tree.elements';
import useTreeContext from '../utils/useTreeContext';

type ConditionNodeProps = {
  node: TreeNode<Condition | Execution>;
  shouldInspectDebugLayout: boolean;
};

const ConditionNode = ({
  node,
  shouldInspectDebugLayout
}: ConditionNodeProps) => {
  const actor = useTreeContext(state => state.actor);
  const actionsSlot = useTreeContext(state => state.actionsSlot);

  const isChildrenExpanded = useSelector(
    actor,
    state => state.context.expandedById[node.id],
    shallowEqual
  );

  const comesFromCurrentLevelComponent = useSelector(actor, state => {
    const { currentLevelComponent } = state.context;
    const component =
      node.item instanceof Condition
        ? node.item.parentChoice()
        : node.item.parentParallel();
    return currentLevelComponent
      ? component.comesFromComponent(currentLevelComponent.id())
      : false;
  });

  const labelValue = useSelector(actor, state => {
    const { flow } = state.context;
    const item = node?.item.spec();

    if (item && item.target.startsWith('api-first:')) {
      const target = flow.getComponent(item.target);
      const spec = target?.spec();
      if (Array.isArray(spec)) {
        return spec[0].stepName;
      }
    }
    return '';
  });

  const emphasis =
    comesFromCurrentLevelComponent && !shouldInspectDebugLayout
      ? 'children'
      : undefined;

  const actionsSlotRendered = actionsSlot?.({
    item: node.item
  });

  const label = node.id.startsWith('api-first:') ? labelValue : node.label;

  return (
    <Elements.Container
      data-testid='flow-tree-node-container'
      shouldInspectDebugLayout={shouldInspectDebugLayout}
      isLevel
    >
      <Elements.Item
        data-testid='build-flow-tree-node-item'
        depthLeft={shouldInspectDebugLayout ? node.depth - 1 : node.depth}
        emphasis={emphasis}
        shouldInspectDebugLayout={shouldInspectDebugLayout}
      >
        <Elements.ToggleWrapper
          onClick={() => actor.send({ type: 'TOGGLE_CHILDREN', id: node.id })}
        >
          {choose(
            when(shouldInspectDebugLayout, () => (
              <Elements.Icon
                icon={isChildrenExpanded ? faChevronDown : faChevronRight}
              />
            )),
            otherwise(() => (
              <Elements.Icon
                icon={isChildrenExpanded ? faCaretDown : faCaretRight}
              />
            ))
          )}
        </Elements.ToggleWrapper>
        <Elements.Icon
          icon={shouldInspectDebugLayout ? faCodeBranch : faSplit}
        />
        <Elements.Label shouldInspectDebugLayout={shouldInspectDebugLayout}>
          <Text
            variant='b3'
            css={{
              fontWeight: 400,
              fontStyle: shouldInspectDebugLayout ? 'italic' : 'normal',
              fontSize: shouldInspectDebugLayout ? '12px' : '14px'
            }}
            disabled
          >
            {label}
          </Text>
        </Elements.Label>

        {iff(!!actionsSlotRendered, () => (
          <Elements.FloatAction
            shouldInspectDebugLayout={shouldInspectDebugLayout}
          >
            {actionsSlotRendered}
          </Elements.FloatAction>
        ))}
      </Elements.Item>
    </Elements.Container>
  );
};

export default ConditionNode;
