import { iff } from '@digibee/control-statements';
import { omit } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { v4 as uuid } from 'uuid';

// Locals
import BaseComponent from './components/BaseComponent';
import HelperText from './components/HelperText';
import Placeholder from './components/Placeholder';

const typography = ({ theme }) => css`
  font-family: ${theme.typography.fontFamily};
`;

const baseStyles = () => css`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  position: relative;
`;

const WrapperLabel = styled.div`
  position: absolute;
  display: flex;
  right: 0;
  left: 0;
  box-sizing: border-box;
  pointer-events: none;
  height: 100%;
`;
const Container = styled.div`
  ${typography};
  ${baseStyles};
`;

const WrapperEndAdornment = styled.div`
  position: absolute;
  right: 1.49%;
  top: 40%;
`;

const TextArea = ({
  id,
  onBlur,
  onFocus,
  onChange,
  actived,
  placeholder,
  disabled,
  helperText,
  labelSecondary,
  endAdornment,
  ...props
}) => {
  const [isActived, setActived] = useState(actived);
  const [isValue, setValue] = useState(!!props.value);
  const [generatorId] = useState(uuid());

  const handlerBlur = event => {
    setActived(false);
    if (onBlur) {
      onBlur(event);
    }
  };
  const handlerFocus = event => {
    setActived(true);
    if (onFocus) {
      onFocus(event);
    }
  };
  const handlerOnchange = event => {
    setValue(event.target.value.length !== 0);
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <Container {...props}>
      <BaseComponent
        disabled={disabled ? 'disabled' : ''}
        isActived={isActived}
        onFocus={handlerFocus}
        onBlur={handlerBlur}
        isValue={isValue}
        id={id || generatorId}
        onChange={handlerOnchange}
        {...omit(props, 'data-testid')}
      />
      {iff(placeholder, () => (
        <WrapperLabel>
          <Placeholder
            htmlFor={id || generatorId}
            isActived={isActived}
            disabled={disabled}
            isValue={isValue}
            secondary={labelSecondary}
            {...omit(props, 'data-testid')}
          >
            {placeholder}
          </Placeholder>
        </WrapperLabel>
      ))}
      {iff(endAdornment, () => (
        <WrapperEndAdornment>{endAdornment}</WrapperEndAdornment>
      ))}
      {iff(helperText, () => (
        <HelperText
          size='sm'
          align='left'
          disabled={disabled}
          isActived={isActived}
          as='span'
          {...omit(props, 'data-testid')}
        >
          {helperText}
        </HelperText>
      ))}
    </Container>
  );
};

TextArea.propTypes = {
  danger: PropTypes.bool,
  id: PropTypes.string,
  actived: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  helperText: PropTypes.string,
  labelSecondary: PropTypes.string,
  endAdornment: PropTypes.node,
  value: PropTypes.string
};

TextArea.defaultProps = {
  id: '',
  placeholder: '',
  // eslint-disable-next-line react/default-props-match-prop-types
  'data-testid': 'input',
  labelSecondary: false,
  helperText: '',
  endAdornment: null,
  value: '',
  danger: false,
  actived: false,
  disabled: false,
  onBlur: () => {},
  onFocus: () => {},
  onChange: () => {}
};

export default TextArea;
