/* eslint-disable react/prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable radix */
/* eslint-disable react/jsx-no-undef */
import {
  Accordion,
  Alert,
  Box,
  Checkbox,
  Button as DSButton,
  Flex,
  Input,
  InputNumber,
  Select,
  SelectAsync,
  SideSheet,
  Skeleton,
  Text
} from '@digibee/beehive-ui';
import { choose, iff, otherwise, when } from '@digibee/control-statements';
import { head } from 'lodash';

import DeploymentAlertV2 from './components/DeploymentAlert/DeploymentAlertV2';
import DeployPipelineDialogV2 from './components/DeployPipelineDialog/DeployPipelineDialogV2';
import DescriptionHelperV2 from './components/DescriptionHelper/DescriptionHelpeV2';
import PipelineToRedeployV2 from './components/PipelineToRedeploy/PipelineToRedeployV2';
import SizePipeline from './components/SizePipeline';
import * as Elements from './DeployPipeline.elements';
import enhancer from './DeployPipeline.enhancer';
import ConfirmPromoteV2 from '../ConfirmPromote/ConfirmPromoteV2';
import TriggerDetails from '../TriggerDetails';

import getFieldError from '~/common/helpers/getFieldError';
import i18n from '~/common/helpers/i18n';
import Slider from '~/components/Slider';
import Switch from '~/components/Switch';

const helperMarksCustom = max => {
  const getLabel = index => {
    if (index === 0) {
      return 'min. 1';
    }
    if (max === index + 1) {
      return `max. ${max}`;
    }
    return '';
  };
  return Array.from({ length: max }, (_, index) => ({
    value: index + 1,
    label: getLabel(index)
  }));
};

const DeployPipeline = ({
  configurations,
  loadPipelines,
  hasAlert,
  validationLicense,
  validationPipeline,
  configurationLastDeploy,
  onChangePipeline,
  versionMinor,
  optionVersionEngine,
  onChangePipelineVersion,
  onChangeConfiguration,
  onChangeDeletePipe,
  sliderProps,
  onChangeConsumer,
  onChangeReplicas,
  onChangeMinReplicas,
  onChangeReplicasDeploy,
  loading,
  replicasDeploy,
  trigger,
  triggers,
  editPipelinePath,
  pipelineValidationError,
  form,
  showPromote,
  enviromentsOptions,
  onChangeEnvironment,
  onChangePipelineVersionEngine,
  openPromote,
  deployment,
  closePromote,
  newDeployment,
  pipelineToPromoteConfirm,
  loadingPromote,
  texts,
  onCloseClick,
  showConfirmDialog,
  closeDialog,
  newDeploy,
  confirmDeploy,
  allowConcurrentScheduling,
  triggerSource,
  pipeline,
  isPipelineEngine,
  onAdvancedSettingsToggle,
  isOpenAdvancedSettings,
  showInactiveTriggersExperience,
  onChangeIsAutoScaling,
  loadingReplicaLimits
}) => (
  <SideSheet
    open
    onClose={() => onCloseClick()}
    header={i18n.t(texts.title)}
    subHeader={showPromote && i18n.t('label.before_promoting_pipe_explanation')}
    size='small'
  >
    <>
      <form onSubmit={form.handleSubmit}>
        <SideSheet.Section>
          <Box css={{ gap: '$3', display: 'flex', flexDirection: 'column' }}>
            <SelectAsync
              isClearable
              cacheOptions
              data-testid='run-create-title-field-pipeline'
              variant='async-paginate'
              placeholder={i18n.t('scenes.runtime.labels.pipeline_singular')}
              value={form.values.pipeline}
              loadOptions={loadPipelines}
              onChange={value => onChangePipeline(value)}
              name='pipeline'
              helperText={i18n.t(
                getFieldError(form.touched, form.errors, 'pipeline.value')
              )}
              additional={{
                page: -1,
                searchPage: -1
              }}
              label={i18n.t(
                'scenes.metrics.labels.upper_and_capitalize.capitalized',
                {
                  item: i18n.t('label.pipeline')
                }
              )}
              className='fs-mask'
              debounceTimeout={500}
              isDisabled={showPromote}
            />
            <Select
              label={i18n.t('scenes.runtime.labels.version')}
              options={versionMinor}
              onChange={value => onChangePipelineVersion(value)}
              name='version'
              value={form.values.version}
              data-testid='run-create-title-field-version'
              isDisabled={showPromote}
              helperText={i18n.t(
                getFieldError(form.touched, form.errors, 'version.value')
              )}
              placeholder={i18n.t('scenes.runtime.labels.version')}
            />
            <Input
              css={{ marginBottom: '$2' }}
              disabled
              placeholder={i18n.t('noun.project')}
              label={i18n.t('noun.project')}
              about=''
              name='project'
              value={form.values.projectName}
              data-testid='run-create-title-field-project'
            />
            {showPromote && (
              <>
                <Select
                  name='environment'
                  onChange={data => {
                    onChangeEnvironment({
                      newEnv: data,
                      replicas: form.values?.replicas,
                      size: form.values?.configuration
                    });
                  }}
                  helperText={i18n.t(
                    'label.select_env_to_promote_pipe_to_assist'
                  )}
                  placeholder={i18n.t(
                    'label.select_the_environment_placeholder'
                  )}
                  options={enviromentsOptions}
                  data-testid='run-environment-select'
                  value={form.values.environment}
                />
                <Box css={{ display: 'flex' }}>
                  <Checkbox
                    checked={form.values.deletePipe}
                    onClick={() => onChangeDeletePipe(!form.values.deletePipe)}
                    data-testid='run-checkbox-delete'
                    label={i18n.t('label.delete_pipe_from_previous_env', {
                      environmentName:
                        deployment?.activeConfiguration?.environment?.name
                    })}
                  />
                </Box>
              </>
            )}
            {iff(replicasDeploy.length, () => (
              <Box top={20} right={16} left={16}>
                <Select
                  data-testid='run-create-title-field-replicasDeploy'
                  isMargin={false}
                  options={replicasDeploy}
                  type='select'
                  className='fs-mask'
                  helperText={i18n.t(
                    getFieldError(
                      form.touched,
                      form.errors,
                      'replicasDeploy.value'
                    )
                  )}
                  label={i18n.t('scenes.runtime.labels.instance')}
                  placeholder={i18n.t('scenes.runtime.labels.instance')}
                  onChange={value =>
                    onChangeReplicasDeploy({
                      ...form.values,
                      pipelineId: form.values?.version?.value,
                      replicasDeploy: value
                    })
                  }
                  name='replicasDeploy'
                  value={form.values.replicasDeploy}
                />
              </Box>
            ))}
            {iff(configurationLastDeploy, () => (
              <Elements.Box top={20}>
                <PipelineToRedeployV2
                  trigger={trigger}
                  title={i18n.t('scenes.runtime.labels.current_deploy')}
                  deployment={configurationLastDeploy}
                  editPipelinePath={editPipelinePath}
                />
              </Elements.Box>
            ))}
          </Box>
        </SideSheet.Section>

        {choose(
          when(
            form.values?.pipeline?.value && !form.values?.version.value,
            () => (
              <>
                <SideSheet.Section>
                  <Skeleton
                    variant='rectangular'
                    css={{
                      width: '100%',
                      height: '113px'
                    }}
                  />
                </SideSheet.Section>
                {isPipelineEngine && (
                  <SideSheet.Section>
                    <Skeleton
                      variant='rectangular'
                      css={{
                        width: '100%',
                        height: '95px'
                      }}
                    />
                  </SideSheet.Section>
                )}
                <SideSheet.Section>
                  <Skeleton
                    variant='rectangular'
                    css={{
                      width: '100%',
                      height: '260px'
                    }}
                  />
                </SideSheet.Section>
              </>
            )
          ),
          when(
            form.values?.pipeline?.value && form.values?.version.value,
            () => (
              <>
                {(loading || trigger) && (
                  <SideSheet.Section>
                    {loading ? (
                      <Skeleton
                        variant='rectangular'
                        css={{
                          width: '100%',
                          height: '113px'
                        }}
                      />
                    ) : (
                      <Box>
                        <Box
                          css={{
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: '12px'
                          }}
                        >
                          <Box
                            css={{
                              display: 'flex',
                              width: '45px',
                              height: '45px'
                            }}
                          >
                            <Box
                              as='img'
                              css={{
                                userDrag: 'none',
                                userSelect: 'none',
                                width: '40px',
                                height: '40px',
                                borderRadius: '50%',
                                border: '1px solid $gra25'
                              }}
                              title={i18n.t(
                                'scenes.beta_pipelines.labels.trigger_icon'
                              )}
                              src={`${trigger.iconURL}`}
                            />
                          </Box>
                          <Text css={{ paddingLeft: '$2' }} variant='b3'>
                            {pipeline?.description}
                          </Text>
                        </Box>
                        <Box css={{ marginTop: '$1' }}>
                          <TriggerDetails
                            source={triggerSource}
                            // @ts-ignore
                            title={head(trigger?.jsonSchema)?.label}
                          />
                        </Box>
                      </Box>
                    )}
                  </SideSheet.Section>
                )}
                {isPipelineEngine && (
                  <>
                    <SideSheet.Section
                      title={
                        <Box css={{ display: 'flex', gap: '$2' }}>
                          {i18n.t('label.pipeline_engine_version')}{' '}
                        </Box>
                      }
                    >
                      <Box
                        css={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between'
                        }}
                      >
                        <DescriptionHelperV2
                          text={i18n.t(
                            'label.pipeline_engine_version_explanation_select'
                          )}
                          linkText={i18n.t('action.learn_more')}
                          link={i18n.t('action.pipe_engine_vers_doc_url')}
                        />
                      </Box>
                      <Box css={{ marginBottom: '$2' }} />
                      <Box
                        css={{
                          gap: '$3',
                          display: 'flex',
                          flexDirection: 'column'
                        }}
                      >
                        <Select
                          label={i18n.t(
                            'scenes.runtime.labels.select_engine_version'
                          )}
                          options={optionVersionEngine}
                          onChange={versionEngine =>
                            onChangePipelineVersionEngine(versionEngine)
                          }
                          name='version'
                          value={optionVersionEngine.find(
                            option =>
                              option.value === form.values?.isPipelineEngine
                          )}
                          data-testid='run-create-title-field-version-select'
                        />
                      </Box>
                    </SideSheet.Section>
                  </>
                )}
                <SideSheet.Section
                  title={i18n.t('scenes.runtime.labels.pipeline_size')}
                >
                  <Box>
                    <DescriptionHelperV2
                      text={i18n.t(
                        'scenes.runtime.labels.pipeline_size_explanation'
                      )}
                      linkText={i18n.t('scenes.runtime.labels.know_more')}
                      link={i18n.t('scenes.runtime.actions.news_url')}
                    />
                    <Box css={{ marginTop: '$2' }}>
                      <SizePipeline
                        loading={!configurations?.length}
                        configurations={configurations}
                        value={form.values?.configuration}
                        name='configuration'
                        onChange={(event, value) =>
                          onChangeConfiguration(event, {
                            ...form.values,
                            pipelineId: form?.values?.version?.value,
                            replicas: form?.values?.replicas,
                            value
                          })
                        }
                      />
                    </Box>
                  </Box>
                </SideSheet.Section>
                {choose(
                  when(!showInactiveTriggersExperience, () => (
                    <>
                      {iff(form?.values?.configuration, () => (
                        <>
                          <SideSheet.Section
                            title={i18n.t(
                              'scenes.runtime.labels.concurrent_executions'
                            )}
                          >
                            <Box>
                              <DescriptionHelperV2
                                text={i18n.t(
                                  'scenes.runtime.labels.concurrent_executions_explanation'
                                )}
                                linkText={i18n.t(
                                  'scenes.runtime.labels.know_more'
                                )}
                                link={i18n.t('scenes.runtime.actions.news_url')}
                              />
                              <Box css={{ marginTop: '$2' }}>
                                <Slider
                                  step={1}
                                  min={1}
                                  max={sliderProps.max}
                                  name='consumer'
                                  marks={helperMarksCustom(sliderProps.max)}
                                  onChange={(event, value) =>
                                    onChangeConsumer(event, value)
                                  }
                                  isInput
                                  value={form.values.consumer}
                                />
                              </Box>
                            </Box>
                          </SideSheet.Section>

                          <SideSheet.Section
                            title={i18n.t('scenes.runtime.labels.replicas')}
                          >
                            <Box>
                              <DescriptionHelperV2
                                text={i18n.t(
                                  'scenes.runtime.labels.replicas_explanation'
                                )}
                                linkText={i18n.t(
                                  'scenes.runtime.labels.know_more'
                                )}
                                link={i18n.t('scenes.runtime.actions.news_url')}
                              />

                              <Box css={{ marginTop: '$3' }}>
                                {loadingReplicaLimits ? (
                                  <Skeleton
                                    variant='rectangular'
                                    style={{ width: '100%', height: '50px' }}
                                  />
                                ) : (
                                  <>
                                    <InputNumber
                                      placeholder={i18n.t(
                                        'scenes.runtime.labels.replicas_placeholder'
                                      )}
                                      label={i18n.t(
                                        'scenes.runtime.labels.replicas_placeholder'
                                      )}
                                      step
                                      invalid={Boolean(form.errors?.replicas)}
                                      helperText={
                                        form.errors?.replicas ||
                                        (form.values?.replicaLimits?.target &&
                                          i18n.t(
                                            'scenes.runtime.labels.input_replicas_assistive_text',
                                            {
                                              limit:
                                                form.values?.replicaLimits
                                                  ?.target
                                            }
                                          ))
                                      }
                                      onChange={value => {
                                        onChangeReplicas({
                                          ...form.values,
                                          pipelineId:
                                            form?.values?.version.value,
                                          configurationId:
                                            form?.values?.configuration,
                                          value
                                        });
                                      }}
                                      name='replicas'
                                      data-testid='run-create-title-field-replicas'
                                      min={1}
                                      max={form.values?.replicaLimits?.target}
                                      value={form.values?.replicas}
                                    />
                                  </>
                                )}
                              </Box>
                            </Box>
                          </SideSheet.Section>

                          <Box>
                            <DeploymentAlertV2
                              hasAlert={hasAlert}
                              configurationLastDeploy={
                                !!configurationLastDeploy
                              }
                              validationLicense={validationLicense}
                              validationPipeline={validationPipeline}
                            />
                          </Box>
                        </>
                      ))}
                    </>
                  )),
                  otherwise(() => (
                    <>
                      {iff(form.values?.configuration, () => (
                        <>
                          <SideSheet.Section>
                            <Flex
                              css={{
                                flexDirection: 'column',
                                gap: '$5'
                              }}
                            >
                              <Box
                                css={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: '32px'
                                }}
                              >
                                <Flex
                                  css={{
                                    flexDirection: 'column',
                                    gap: '$2'
                                  }}
                                >
                                  <Text
                                    css={{
                                      fontSize: '$3',
                                      color: 'black',
                                      fontWeight: '$medium',
                                      lineHeight: '29px'
                                    }}
                                  >
                                    {i18n.t(
                                      'scenes.runtime.labels.autoscaling'
                                    )}
                                  </Text>
                                  <DescriptionHelperV2
                                    text={i18n.t(
                                      'scenes.runtime.labels.autoscaling_explanation'
                                    )}
                                    // linkText={i18n.t(
                                    //   'scenes.runtime.labels.know_more'
                                    // )}
                                    // link={i18n.t(
                                    //   'action.pipe_engine_vers_doc_url'
                                    // )}
                                  />
                                </Flex>
                                <Box
                                  css={{
                                    display: 'flex',
                                    alignItems: 'center'
                                  }}
                                >
                                  <Switch
                                    name='isAutoScaling'
                                    defaultChecked={form.values?.isAutoScaling}
                                    checked={form.values?.isAutoScaling}
                                    onChange={(_, checked) => {
                                      onChangeIsAutoScaling({
                                        value: !checked
                                      });
                                    }}
                                  />
                                  <Text>
                                    {form.values?.isAutoScaling
                                      ? i18n.t('label.active')
                                      : i18n.t('label.inactive')}
                                  </Text>
                                </Box>
                              </Box>
                              <Box>
                                <DescriptionHelperV2
                                  text={i18n.t(
                                    'scenes.runtime.labels.replicas_explanation'
                                  )}
                                  linkText={i18n.t(
                                    'scenes.runtime.labels.know_more'
                                  )}
                                  link={i18n.t(
                                    'scenes.runtime.actions.news_url_autoscaling'
                                  )}
                                />

                                <Flex
                                  css={{
                                    gap: '$5',
                                    marginTop: '$3'
                                  }}
                                >
                                  {loadingReplicaLimits ? (
                                    <Skeleton
                                      variant='rectangular'
                                      style={{ width: '100%', height: '50px' }}
                                    />
                                  ) : (
                                    <>
                                      {form.values.isAutoScaling && (
                                        <Box
                                          css={{
                                            flex: 1,
                                            width: '300px'
                                          }}
                                        >
                                          <InputNumber
                                            label={i18n.t(
                                              'scenes.runtime.labels.minimum_replicas'
                                            )}
                                            step={1}
                                            onChange={value => {
                                              onChangeMinReplicas({
                                                value
                                              });
                                            }}
                                            invalid={Boolean(
                                              form.errors?.minReplicas
                                            )}
                                            helperText={
                                              form.errors?.minReplicas ||
                                              (form.values?.replicaLimits
                                                ?.min >= 0 &&
                                                i18n.t(
                                                  'scenes.runtime.labels.input_replicas_assistive_text',
                                                  {
                                                    limit:
                                                      form.values?.replicaLimits
                                                        ?.min
                                                  }
                                                ))
                                            }
                                            name='minReplicas'
                                            data-testid='run-deploy-min-replicas'
                                            min={0}
                                            max={
                                              form.values?.replicaLimits?.min
                                            }
                                            value={form.values?.minReplicas}
                                          />
                                        </Box>
                                      )}
                                      <Box
                                        css={{
                                          flex: 1,
                                          width: form.values?.isAutoScaling
                                            ? '300px'
                                            : '100%'
                                        }}
                                      >
                                        <InputNumber
                                          label={i18n.t(
                                            form.values.isAutoScaling
                                              ? 'scenes.runtime.labels.maximum_replicas'
                                              : 'scenes.runtime.labels.replicas_placeholder'
                                          )}
                                          step={1}
                                          onChange={value => {
                                            onChangeReplicas({
                                              ...form.values,
                                              pipelineId:
                                                form.values?.version.value,
                                              configurationId:
                                                form.values?.configuration,
                                              value
                                            });
                                          }}
                                          invalid={Boolean(
                                            form.errors?.replicas
                                          )}
                                          helperText={
                                            form.errors?.replicas ||
                                            (() => {
                                              const limit = form.values
                                                ?.isAutoScaling
                                                ? form.values?.replicaLimits
                                                    ?.max
                                                : form.values?.replicaLimits
                                                    ?.target;

                                              return i18n.t(
                                                'scenes.runtime.labels.input_replicas_assistive_text',
                                                { limit }
                                              );
                                            })()
                                          }
                                          name='replicas'
                                          data-testid={
                                            form.values?.isAutoScaling
                                              ? 'run-deploy-max-replicas'
                                              : 'run-deploy-replicas'
                                          }
                                          min={1}
                                          max={
                                            form.values.isAutoScaling
                                              ? form.values?.replicaLimits?.max
                                              : form.values?.replicaLimits
                                                  ?.target
                                          }
                                          value={form.values?.replicas}
                                        />
                                      </Box>
                                    </>
                                  )}
                                </Flex>
                              </Box>
                            </Flex>
                          </SideSheet.Section>

                          {!allowConcurrentScheduling &&
                            (form.values.replicas > 1 ||
                              form.values.consumer > 1) &&
                            pipeline?.triggerSpec?.type === 'scheduler' && (
                              <Box
                                css={{
                                  marginBottom: '$4'
                                }}
                              >
                                <Alert
                                  variant='warning'
                                  title={i18n.t(
                                    'scenes.runtime.labels.conc_scheduling_off_2_replicas_or_conc_exec'
                                  )}
                                  collapsible
                                  content={i18n.t(
                                    'scenes.runtime.labels.conc_scheduling_off_2_replicas_or_conc_exec_explanation'
                                  )}
                                  size='small'
                                />
                              </Box>
                            )}

                          <Accordion
                            title={i18n.t(
                              'scenes.runtime.labels.advanced_settings'
                            )}
                            css={{
                              marginBottom: '24px',
                              border: '1px solid $neu100',
                              borderRadius: '$1',
                              backgroundColor: '$neu25'
                            }}
                            onToggle={onAdvancedSettingsToggle}
                            open={isOpenAdvancedSettings}
                          >
                            <Box>
                              <Text
                                css={{
                                  fontSize: '$3',
                                  color: 'black',
                                  fontWeight: '$medium',
                                  lineHeight: '29px',
                                  marginBottom: '$2'
                                }}
                              >
                                {i18n.t(
                                  'scenes.runtime.labels.concurrent_executions'
                                )}
                              </Text>
                              <Box>
                                <DescriptionHelperV2
                                  text={i18n.t(
                                    'scenes.runtime.labels.concurrent_executions_explanation'
                                  )}
                                  linkText={i18n.t(
                                    'scenes.runtime.labels.know_more'
                                  )}
                                  link={i18n.t(
                                    'scenes.runtime.actions.news_url'
                                  )}
                                />
                                <Slider
                                  step={1}
                                  min={1}
                                  max={sliderProps.max}
                                  name='consumer'
                                  marks={helperMarksCustom(sliderProps.max)}
                                  onChange={(event, value) =>
                                    onChangeConsumer(event, value)
                                  }
                                  isInput
                                  value={form.values.consumer}
                                />
                              </Box>
                            </Box>
                          </Accordion>

                          <Box>
                            <DeploymentAlertV2
                              hasAlert={hasAlert}
                              configurationLastDeploy={
                                !!configurationLastDeploy
                              }
                              validationLicense={validationLicense}
                              validationPipeline={validationPipeline}
                            />
                          </Box>
                        </>
                      ))}
                    </>
                  ))
                )}
              </>
            )
          )
        )}
        <SideSheet.Footer>
          <DSButton
            disabled={
              !!hasAlert ||
              !!pipelineValidationError?.length ||
              !!validationLicense?.length ||
              !form.isValid
            }
            loading={loading}
            onClick={form.submitForm}
            data-testid={texts.datatestidText}
            type='primary'
          >
            {i18n.t(texts.button)}
          </DSButton>
        </SideSheet.Footer>
      </form>
    </>
    {showConfirmDialog && (
      <DeployPipelineDialogV2
        onClose={closeDialog}
        texts={texts}
        dataModal={newDeploy}
        confirmDeploy={confirmDeploy}
      />
    )}
    {openPromote && showPromote && (
      <ConfirmPromoteV2
        loadingPromote={loadingPromote}
        onClose={closePromote}
        dataModal={newDeployment}
        oldDeployment={deployment}
        pipelineToPromoteConfirm={pipelineToPromoteConfirm}
        isDeletePipe={form.values.deletePipe}
        triggers={triggers}
      />
    )}
  </SideSheet>
);

export default enhancer(DeployPipeline);
