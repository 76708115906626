import { Flex, Text, theme } from '@digibee/beehive-ui';

export const SearchBarWrapper = theme.styled(Flex, {
  marginBottom: '$3',
  width: '100%'
});

export const UsersWrapper = theme.styled(Flex, {
  width: '100%',
  marginTop: '$4',
  gap: '$2'
});

export const UserInfo = theme.styled(Flex, {
  width: '100%',
  padding: '$2',
  border: '1px solid $neu200',
  borderRadius: '$1'
});

export const UserData = theme.styled(Flex, {
  gap: '$1',
  fontFamily: '$display',

  'span': {
    fontSize: '$1',
    color: '$neu300'
  },
  'h2': {
    fontSize: '$2',
    color: '$neu500'
  }
});

export const UsersListTitle = theme.styled(Text, {
  fontFamily: '$display',
  fontSize: '$3',
  color: '$neu600',
  marginBottom: '$2'
});
