import moment from 'moment';
import queryString from 'query-string';
import { createSearchParams } from 'react-router-dom';
import Immutable from 'seamless-immutable';

// Locals
import { stats } from '~/api';
import getEnvironmentsToPathName from '~/common/helpers/getEnvironmentsToPathName';
import getRealmToPathName from '~/common/helpers/getRealmToPathName';

const getPropsSearch = () => {
  const params = queryString.parse(window.location.search.replace('?', ''));
  const STRING_EMPTY = '';
  const SPECIFIC_DATE_FROM_DEFAULT = new Date();
  return {
    pipelineName: params.pipelineName || STRING_EMPTY,
    dateTo: params.dateTo || moment().toISOString(),
    dateFrom: params.dateFrom || moment().subtract(15, 'minutes').toISOString(),
    interval: params.interval || '15m',
    label: Number(params.label) || 15,
    name: params.name || '15 minutes',
    specific: {
      dateFrom: params.dateFrom || SPECIFIC_DATE_FROM_DEFAULT,
      dateTo: params.dateTo || SPECIFIC_DATE_FROM_DEFAULT
    }
  };
};

const initialState = Immutable({
  loading: true,
  loadingMetrics: false,
  realm: getRealmToPathName(window.location.pathname),
  data: null,
  error: false,
  environment: getEnvironmentsToPathName(window.location.pathname),
  interval: getPropsSearch(),
  pipeline: '',
  specific: getPropsSearch().specific,
  pipelineName: getPropsSearch().pipelineName,
  ref: null
});

const statsModel = {
  name: 'stats',
  state: initialState,
  reducers: {
    setLoading(state, payload) {
      return state.merge({
        loading: payload
      });
    },
    setLoadingMetrics(state, payload) {
      return state.merge({
        loadingMetrics: payload
      });
    },
    setInterval(state, payload) {
      return state.merge({
        interval: payload
      });
    },
    setSpecific(state, payload) {
      return state.merge({
        specific: payload
      });
    },
    setPipeline(state, payload) {
      return state.merge({
        pipeline: payload
      });
    },
    setRef(state, payload) {
      return state.merge({
        ref: payload
      });
    },
    setData(state, payload) {
      return state.merge({
        data: payload
      });
    },
    setError(state, payload) {
      return state.merge({
        error: payload
      });
    },
    setPipelineName(state, payload) {
      return state.merge({
        pipelineName: payload
      });
    }
  },
  effects: dispatch => ({
    async find({ environment, realm, all }, state) {
      try {
        dispatch.stats.setLoading(true);

        const search = {
          ...state.stats.interval,
          pipelineName: state.stats.pipelineName || ''
        };

        dispatch.router.navigate({
          pathname: window.location.pathname,
          search: `?${createSearchParams(search)}`,
          options: { replace: true }
        });

        const request = await stats.find({
          realm,
          environment,
          interval: state.stats.interval.interval,
          dateFrom: state.stats.interval.dateFrom,
          dateTo: state.stats.interval.dateTo,
          pipelineName: state.stats.pipelineName,
          all
        });

        dispatch.stats.setData(request.data.stats);
        dispatch.stats.setLoading(false);
      } catch (e) {
        dispatch.stats.setError(true);
        dispatch.stats.setLoading(false);
      }
    }
  })
};

export default statsModel;
