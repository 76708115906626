import Immutable from 'seamless-immutable';

import { alertsConfig } from '~/api';
import i18n from '~/common/helpers/i18n';
import { NotificationChannelTypes } from '~/entities/MonitoringAlert';

const initAlertState = {
  id: undefined,
  alertName: '',
  alertStatus: false,
  alertConfig: {
    alertParams: [
      {
        paramName: '',
        paramVersion: 0,
        paramType: 'pipeline'
      }
    ],
    alertMetricName: 'EPS',
    alertMetricThresholdValue: '',
    alertMetricThresholdOperator: '',
    alertIntervalCheck: ''
  },
  notificationConfig: {
    notificationChannels: [
      {
        notificationChannelType: NotificationChannelTypes.Email,
        notificationChannelParams: {
          emailTo: [],
          emailSubject: '',
          emailContent: ''
        }
      },
      {
        notificationChannelType: NotificationChannelTypes.Telegram,
        notificationChannelParams: {
          chatId: ''
        }
      },
      {
        notificationChannelType: NotificationChannelTypes.Webhook,
        notificationChannelParams: {
          url: ''
        }
      },
      {
        notificationChannelType: NotificationChannelTypes.Slack,
        notificationChannelParams: {
          slackUrl: ''
        }
      }
    ]
  }
};

const initialState = Immutable({
  searchText: null,
  alerts: [],
  alertInitialValue: initAlertState,
  metrics: {},
  loading: false,
  modal: {
    visible: false,
    loading: false,
    success: null,
    error: null
  },
  success: false
});

const alertsConfigModel = {
  name: 'alertConfig',
  state: initialState,
  reducers: {
    setSearch: (state, searchText) =>
      state.merge({
        searchText
      }),
    setAlerts: (state, payload) =>
      state.merge({
        alerts: payload
      }),
    setAlertInitialValue: (state, payload) =>
      state.merge({
        alertInitialValue: payload
      }),
    setModal: (state, payload) =>
      state.merge({ modal: { ...state.model, ...payload } }, { deep: true }),
    setModalLoading: (state, loading) =>
      state.merge({
        modal: {
          ...state.modal,
          loading
        }
      }),
    setActionModal: (state, payload) =>
      state.merge({
        modal: {
          ...state.modal,
          ...payload
        }
      }),
    setLoading: (state, payload) =>
      state.merge({
        loading: payload
      }),
    clearAlert: state => state.merge({ alertInitialValue: initAlertState }),
    setSuccess: (state, payload) => state.merge({ success: payload }),
    setMetrics: (state, payload) =>
      state.merge({
        metrics: payload
      }),
    reset: () => initialState
  },
  effects: dispatch => ({
    async createAlert(alert, rootState) {
      const { alertName } = alert;

      try {
        const { activeRealm: realm, environment } = rootState.application;

        const request = await alertsConfig.createAlertsConfig({
          realm,
          environment,
          alert
        });

        dispatch.alertConfig.setModalLoading(request, {
          snackbar: {
            text: i18n.t('label.alert_set_up_msg_success', {
              alertName
            }),
            action: {
              label: i18n.t('action.close')
            }
          }
        });
        dispatch.alertConfig.setSuccess(true);
      } catch (e) {
        dispatch.alertConfig.setModalLoading(e, {
          snackbar: {
            text: i18n.t('label.alert_not_set_up_msg_alert', { alertName }),
            visible: true
          }
        });
      } finally {
        setTimeout(() => {
          dispatch.alertConfig.setSuccess(false);
        }, 1000);
      }
    },
    async updateAlert({ id, ...alert }, rootState) {
      const { alertName } = alert;
      try {
        const { activeRealm: realm, environment } = rootState.application;

        const request = await alertsConfig.updateAlertsConfig({
          realm,
          environment,
          alert,
          id
        });

        dispatch.alertConfig.setModalLoading(request, {
          snackbar: {
            text: i18n.t('label.alert_edited_msg_success', { alertName }),
            action: {
              label: i18n.t('action.close')
            }
          }
        });
        dispatch.alertConfig.setSuccess(true);
      } catch (e) {
        dispatch.alertConfig.setModalLoading(e, {
          snackbar: {
            text: i18n.t('label.alert_not_set_up_msg_alert', { alertName }),
            visible: true
          }
        });
      } finally {
        setTimeout(() => {
          dispatch.alertConfig.setSuccess(false);
        }, 1000);
      }
    },
    async getAlerts({ page, size, alertName, search }, rootState) {
      try {
        const { activeRealm: realm, environment } = rootState.application;

        dispatch.alertConfig.setLoading(true);
        const data = await alertsConfig.getAlertsConfig({
          realm,
          environment,
          page,
          size,
          search
        });

        dispatch.alertConfig.setAlerts(data);
        dispatch.alertConfig.setSearch(search);
      } catch (e) {
        dispatch.alertConfig.setModalLoading(e, {
          snackbar: {
            text: i18n.t('label.alert_not_set_up_msg_alert', { alertName }),
            visible: true
          }
        });
      } finally {
        setTimeout(() => {
          dispatch.alertConfig.setLoading(false);
        }, 1000);
      }
    },
    async getAlertsById({ id, alertName }, rootState) {
      try {
        const { activeRealm: realm, environment } = rootState.application;

        const data = await alertsConfig.getAlertsConfigById({
          realm,
          environment,
          id
        });

        dispatch.alertConfig.setAlertInitialValue(data);
      } catch (e) {
        dispatch.alertConfig.setModalLoading(e, {
          snackbar: {
            text: i18n.t('label.alert_not_set_up_msg_alert', { alertName }),
            visible: true
          }
        });
      }
    },
    async removeAlert({ id, alertName }, rootState) {
      try {
        const { activeRealm: realm, environment } = rootState.application;

        const data = await alertsConfig.removeAlertsConfig({
          realm,
          environment,
          id
        });

        dispatch.alertConfig.setModalLoading(data, {
          snackbar: {
            text: i18n.t('label.alert_deleted_msg_success', { alertName }),
            action: {
              label: i18n.t('action.close')
            }
          }
        });

        dispatch.alertConfig.setSuccess(data);
      } catch (e) {
        dispatch.alertConfig.setModalLoading(e, {
          snackbar: {
            text: i18n.t('label.alert_not_set_up_msg_alert', { alertName }),
            visible: true
          }
        });
        dispatch.alertConfig.setSuccess(false);
      } finally {
        setTimeout(() => {
          dispatch.alertConfig.setSuccess(false);
        }, 1000);
      }
    },
    async activeAlert({ id, alertStatus, alertName }, rootState) {
      try {
        const { activeRealm: realm, environment } = rootState.application;

        const data = await alertsConfig.activeAlertsConfig({
          realm,
          environment,
          id,
          alertStatus
        });

        dispatch.alertConfig.setModalLoading(data, {
          snackbar: {
            text: `${
              alertStatus
                ? i18n.t('label.alert_activated_msg_success', { alertName })
                : i18n.t('label.alert_deactivated_msg_success', { alertName })
            } `,
            visible: true
          }
        });

        dispatch.alertConfig.setSuccess(true);
      } catch (e) {
        dispatch.alertConfig.setModalLoading(e, {
          snackbar: {
            text: i18n.t('label.alert_not_set_up_msg_alert', { alertName }),
            visible: true
          }
        });
        dispatch.alertConfig.setSuccess(false);
      } finally {
        setTimeout(() => {
          dispatch.alertConfig.setSuccess(false);
        }, 1000);
      }
    },
    async getMetrics(_, rootState) {
      try {
        const { activeRealm: realm, environment } = rootState.application;

        const data = await alertsConfig.getMetrics({
          realm,
          environment
        });
        dispatch.alertConfig.setMetrics(data);
      } catch (e) {
        dispatch.alertConfig.setModalLoading(e, {
          snackbar: {
            text: i18n.t('common.messages.error.fetch'),
            visible: true
          }
        });
      }
    }
  })
};

export default alertsConfigModel;
