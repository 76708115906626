import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { OrganizationGroup, OrganizationGroupRealm, OrganizationGroupUser } from '../../models/organization';
import { StoreDispatcher } from '../../types/StoreDispatcher';
import { StoreRootState } from '../../types/StoreRootState';

import i18n from '~/common/helpers/i18n';
import withHooks from '~/common/hoc/withHooks';
import { useHeader } from '~/components/HeaderProvider';

export type Props = {
  loading: boolean,
  data: Array<OrganizationGroup>,
  openModal: (data: Array<OrganizationGroupUser>, selectedGroupName: string, realmsGroup: Array<OrganizationGroupRealm>) => void,
}

const enhancer = withHooks(() => {
  const { organization, loading, realm } = useSelector((state: StoreRootState) => ({
    loading: state.organization.loading,
    organization: state.organization.organization,
    realm: state.application.realm
  }));
  const dispath = useDispatch<StoreDispatcher>();

  const props: Props = {
    loading,
    data: organization?.groups || [],

    openModal: (data, selectedGroupName, realmsGroup) =>
      dispath.organization.setModalOrganization({ visible: true, loading: false, data, selectedGroupName, realmsGroup })
  };

  useEffect(() => {
    dispath.organization.fetch();
  }, [realm]);

  useHeader({
    configActionButton: [
      {
        text: i18n.t('scenes.beta.labels.organization_groups')
      }
    ]
  });

  return props;
});

export default enhancer;
