/* eslint-disable no-useless-catch */
import axios from 'axios';

import { API_KEY, URL } from '~/common/helpers/environment';
import { Organization, OrganizationGroupUser } from '~/scenes/Governance/models/organization';

const organization = {
  async fetch(realm: string, token: string) {
    try {
      const response = await axios.get<Organization>(
        `${URL}/design/companies/realms/${realm}`,
        {
          headers: {
            'Content-Type': 'application/json',
            apikey: API_KEY,
            authorization: token
          }
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  async patchGroup(realm: string, company: string, groupName: string, users: Array<OrganizationGroupUser>, token: string) {
    try {
      const data = [
        {
          group: groupName,
          users,
        }
      ];
      const response = await axios.patch<Organization>(
        `${URL}/design/realms/${realm}/companies/${company}/manage-group-user`,
        data,
        {
          headers: {
            'Content-Type': 'application/json',
            apikey: API_KEY,
            authorization: token
          }
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
};

export default organization;
