import { isApolloError, gql } from '@apollo/client';
import { Pagination } from '~/common/types/Pagination';
import { MonitoringAlert } from '~/entities/MonitoringAlert';
import clientApollo from './clientApollo';

export type Metrics = {
  [key: string]: string;
};

type AlertParamsInput = {
  paramName: string;
  paramVersion: number;
  paramType: string;
};

type AlertConfigInput = {
  alertParams: AlertParamsInput[];
  alertMetricName: string;
  alertMetricThresholdValue: string;
  alertMetricThresholdOperator: string;
  alertIntervalCheck: string;
};

type NotificationChannelsParamsInput = {
  emailTo: string[];
  emailSubject: string;
  emailContent: string;
};

type NotificationChannelsInput = {
  notificationChannelsType: string;
  notificationChannelsParams: NotificationChannelsParamsInput;
};

type NotificationConfigInput = {
  notificationChannels: NotificationChannelsInput[];
};

export type MonitoringAlertsConfigInput = {
  alertConfig: AlertConfigInput;
  notificationConfig: NotificationConfigInput;
  alertName: string;
  alertStatus: boolean;
};

type AlertsConfigSaveData = {
  realm: string;
  environment: string;
  alert: MonitoringAlertsConfigInput;
  id?: string;
};

type SaveAlertsConfigResult = {
  createAlertsConfig: boolean;
};

type UpdatedAlertsConfigResult = {
  updateAlertsConfig: boolean;
};

type AlertsConfigFetchData = {
  realm: string;
  environment: string;
  page?: number;
  size?: number;
  id?: string;
  alertName?: string;
  search?: string;
};

type AlertsConfigFetchAllResult = {
  getAlertsConfig: Pagination<MonitoringAlert>;
};

type AlertsConfigFetchByIdResult = {
  getAlertsConfigById: MonitoringAlert;
};

type AlertsConfigFetchByNameResult = {
  getAlertsConfigByName: MonitoringAlert;
};

type RemoveAlertsConfigData = {
  realm: string;
  environment: string;
  id: string;
};

type RemoveAlertsConfigResult = {
  deleteAlertsConfig: boolean;
};

type ActiveAlertsConfigData = {
  realm: string;
  environment: string;
  id: string;
  alertStatus: boolean;
};

type ActiveAlertsConfigResult = {
  activeAlertConfig: MonitoringAlert;
};

type MetricsResult = {
  getMetrics: Metrics;
};

const alertsConfig = {
  getAlertsConfig: async ({
    realm,
    environment,
    page,
    size = 20,
    search
  }: AlertsConfigFetchData) => {
    try {
      const result = await clientApollo.query<AlertsConfigFetchAllResult>({
        query: gql`
          query getAlertsConfig(
            $realm: String!
            $environment: String!
            $page: Int
            $size: Int
            $search: String
          ) {
            getAlertsConfig(
              realm: $realm
              environment: $environment
              page: $page
              size: $size
              search: $search
            )
          }
        `,
        variables: {
          realm,
          environment,
          page,
          size,
          search
        }
      });

      return result.data?.getAlertsConfig;
    } catch (e) {
      if (e instanceof Error && isApolloError(e)) throw Error(e.message);
      throw e;
    }
  },
  getAlertsConfigById: async ({
    realm,
    environment,
    id
  }: AlertsConfigFetchData) => {
    try {
      const result = await clientApollo.query<AlertsConfigFetchByIdResult>({
        query: gql`
          query getAlertsConfigById(
            $realm: String!
            $environment: String!
            $id: String!
          ) {
            getAlertsConfigById(
              realm: $realm
              environment: $environment
              id: $id
            )
          }
        `,
        variables: {
          realm,
          environment,
          id
        }
      });

      return result.data?.getAlertsConfigById;
    } catch (e) {
      if (e instanceof Error && isApolloError(e)) throw Error(e.message);
    }
  },
  getAlertsConfigByName: async ({
    realm,
    environment,
    alertName
  }: AlertsConfigFetchData) => {
    try {
      const result = await clientApollo.query<AlertsConfigFetchByNameResult>({
        query: gql`
          query getAlertsConfigByName(
            $realm: String!
            $environment: String!
            $alertName: String!
          ) {
            getAlertsConfigByName(
              realm: $realm
              environment: $environment
              alertName: $alertName
            )
          }
        `,
        variables: {
          realm,
          environment,
          alertName
        }
      });

      return result.data?.getAlertsConfigByName;
    } catch (e) {
      if (e instanceof Error && isApolloError(e)) throw Error(e.message);
      throw e;
    }
  },
  createAlertsConfig: async ({
    realm,
    environment,
    alert
  }: AlertsConfigSaveData) => {
    try {
      const result = await clientApollo.mutate<SaveAlertsConfigResult>({
        mutation: gql`
          mutation createAlertsConfig(
            $realm: String!
            $environment: String!
            # TODO: ALTERAR TIPAGEM
            $alert: JSON
          ) {
            createAlertsConfig(
              realm: $realm
              environment: $environment
              alert: $alert
            )
          }
        `,
        variables: {
          realm,
          environment,
          alert
        }
      });
      return result.data?.createAlertsConfig;
    } catch (e) {
      if (e instanceof Error && isApolloError(e)) throw Error(e.message);
      throw e;
    }
  },
  updateAlertsConfig: async ({
    realm,
    environment,
    alert,
    id
  }: AlertsConfigSaveData) => {
    try {
      const result = await clientApollo.mutate<UpdatedAlertsConfigResult>({
        mutation: gql`
          mutation updateAlertsConfig(
            $realm: String!
            $environment: String!
            $id: String!
            # TODO: ALTERAR TIPAGEM
            $alert: JSON
          ) {
            updateAlertsConfig(
              realm: $realm
              environment: $environment
              id: $id
              alert: $alert
            )
          }
        `,
        variables: {
          realm,
          environment,
          alert,
          id
        }
      });
      return result.data?.updateAlertsConfig;
    } catch (e) {
      if (e instanceof Error && isApolloError(e)) throw Error(e.message);
      throw e;
    }
  },
  removeAlertsConfig: async ({
    realm,
    environment,
    id
  }: RemoveAlertsConfigData) => {
    try {
      const result = await clientApollo.mutate<RemoveAlertsConfigResult>({
        mutation: gql`
          mutation deleteAlertsConfig(
            $realm: String!
            $environment: String!
            $id: String!
          ) {
            deleteAlertsConfig(
              realm: $realm
              environment: $environment
              id: $id
            )
          }
        `,
        variables: {
          realm,
          environment,
          id
        }
      });

      return result.data?.deleteAlertsConfig;
    } catch (e) {
      if (e instanceof Error && isApolloError(e)) throw Error(e.message);
      throw e;
    }
  },
  activeAlertsConfig: async ({
    realm,
    environment,
    id,
    alertStatus
  }: ActiveAlertsConfigData) => {
    try {
      const result = await clientApollo.mutate<ActiveAlertsConfigResult>({
        mutation: gql`
          mutation activeAlertsConfig(
            $realm: String!
            $environment: String!
            $id: String!
            $alertStatus: Boolean!
          ) {
            activeAlertsConfig(
              realm: $realm
              environment: $environment
              id: $id
              alertStatus: $alertStatus
            )
          }
        `,
        variables: {
          realm,
          environment,
          id,
          alertStatus
        }
      });

      return result?.data?.activeAlertConfig;
    } catch (e) {
      if (e instanceof Error && isApolloError(e)) throw Error(e.message);
      throw e;
    }
  },
  getMetrics: async ({ realm, environment }: AlertsConfigFetchData) => {
    try {
      const result = await clientApollo.query<MetricsResult>({
        query: gql`
          query getMetrics($realm: String!, $environment: String!) {
            getMetrics(realm: $realm, environment: $environment)
          }
        `,
        variables: {
          realm,
          environment
        }
      });

      return result.data.getMetrics;
    } catch (e) {
      if (e instanceof Error && isApolloError(e)) throw Error(e.message);
      throw e;
    }
  }
};

export default alertsConfig;
