import { Text } from '@digibee/beehive-ui';
import { choose, otherwise, when } from '@digibee/control-statements';
import { Track } from '@digibee/flow';
import {
  faChevronRight,
  faChevronDown
} from '@fortawesome/pro-regular-svg-icons';
import {
  faCaretDown,
  faCaretRight,
  faPlugCircleXmark
} from '@fortawesome/pro-solid-svg-icons';
import { shallowEqual, useSelector } from '@xstate/react';

import { TreeNode } from '../Node';
import * as Elements from '../Tree.elements';
import useTreeContext from '../utils/useTreeContext';

type DisconnectedTrackNodeProps = {
  node: TreeNode<Track | 'disconnected-group' | 'root'>;
  shouldInspectDebugLayout: boolean;
};

const DisconnectedTrackNode = ({
  node,
  shouldInspectDebugLayout
}: DisconnectedTrackNodeProps) => {
  const actor = useTreeContext(state => state.actor);
  const isChildrenExpanded = useSelector(
    actor,
    state => state.context.expandedById[node.id],
    shallowEqual
  );

  const comesFromCurrentLevelComponent = useSelector(actor, state => {
    const { currentLevelComponent } = state.context;

    if (node.item === 'root' || node.parentId === 'root') {
      return false;
    }

    const track =
      node.item === 'disconnected-group'
        ? state.context.flow.getLevel(node.parentId as string)
        : node.item;

    if (!track) {
      return false;
    }

    return currentLevelComponent
      ? track.parentComponent()?.id() === currentLevelComponent.id() ||
          track.comesFromComponent(currentLevelComponent.id())
      : false;
  });

  const emphasis = comesFromCurrentLevelComponent ? 'children' : undefined;

  return (
    <Elements.Container
      data-testid='flow-tree-node-container'
      shouldInspectDebugLayout={shouldInspectDebugLayout}
      isLevel
    >
      <Elements.Item
        data-testid='build-flow-tree-node-item'
        depthLeft={shouldInspectDebugLayout ? node.depth - 1 : node.depth}
        emphasis={emphasis}
        shouldInspectDebugLayout={shouldInspectDebugLayout}
      >
        <Elements.ToggleWrapper
          onClick={() => actor.send({ type: 'TOGGLE_CHILDREN', id: node.id })}
        >
          {choose(
            when(shouldInspectDebugLayout, () => (
              <Elements.Icon
                icon={isChildrenExpanded ? faChevronDown : faChevronRight}
              />
            )),
            otherwise(() => (
              <Elements.Icon
                icon={isChildrenExpanded ? faCaretDown : faCaretRight}
              />
            ))
          )}
        </Elements.ToggleWrapper>
        <Elements.Icon icon={faPlugCircleXmark} />
        <Elements.Label shouldInspectDebugLayout={shouldInspectDebugLayout}>
          <Text
            variant='b3'
            css={{
              fontWeight: 400,
              fontStyle: shouldInspectDebugLayout ? 'italic' : 'normal',
              fontSize: shouldInspectDebugLayout ? '12px' : '14px'
            }}
            disabled
          >
            {node.label}
          </Text>
        </Elements.Label>
      </Elements.Item>
    </Elements.Container>
  );
};

export default DisconnectedTrackNode;
