import axios from 'axios';

import { API_KEY, URL } from '~/common/helpers/environment';

const insights = {
  find: async ({
    dateTo,
    dateFrom,
    environment,
    realm,
    pipelineName,
    pipelineMajorVersion,
    pipelineMinorVersion,
    signal,
    token,
    isInsightsV2
  }) => {
    try {
      const response = await axios.get(
        `${URL}/runtime/realms/${realm}/${isInsightsV2 ? 'v2/' : ''}insights?environment=${environment}&pipelineName=${pipelineName}&dateFrom=${dateFrom}&dateTo=${dateTo}&pipelineMajorVersion=${pipelineMajorVersion}&pipelineMinorVersion=${pipelineMinorVersion}`,
        {
          headers: {
            apikey: API_KEY,
            Authorization: token
          },
          signal
        }
      );

      if (response.status !== 200) {
        throw new Error(
          `Failed to get insights: ${response.status} ${
            response.statusText ? `- ${response.statusText}` : ''
          }`
        );
      }

      return response;
    } catch (e) {
      throw new Error(e);
    }
  }
};

export default insights;
