import { gql, isApolloError } from '@apollo/client';
import { FlowSpec } from '@digibee/flow';
import axios from 'axios';

import clientApollo from './clientApollo';
import { API_KEY } from './variables';

import { Pagination } from '~/common/types/Pagination';
import {
  Capsule,
  CapsuleCollection,
  CapsuleCollectionGroup,
  CapsuleCollectionHeader
} from '~/entities/Capsule';
import { MockType } from '~/entities/Mock';

type BaseParams = {
  realm: string;
};

type CollectionsFetchParams = BaseParams;

export type CollectionsFetchResult = Pagination<CapsuleCollection>;

type CapsuleFetchCollectionHeaderParams = BaseParams;

type CapsuleFetchParams = {
  id: string;
  realm: string;
};

type CollectionGroupsFetchParams = BaseParams & {
  collectionId: string;
};

type CollectionGroupsFetchResult = CapsuleCollectionGroup[];

type CapsuleFetchResult = Capsule;

type CapsuleSaveCollectionHeaderParams = BaseParams & {
  header: Omit<CapsuleCollectionHeader, 'id' | 'disabled'>;
};

type CapsuleSaveCollectionHeaderResult = CapsuleCollectionHeader;

type CapsuleSaveCollectionParams = BaseParams & Omit<CapsuleCollection, 'id'>;

type CapsuleSaveCollectionResult = CapsuleCollection;

type CapsuleSaveGroupParams = BaseParams & {
  collectionId: string;
  group: Omit<CapsuleCollectionGroup, 'id'>;
};

type CapsuleSaveGroupResult = CapsuleCollectionGroup;

export type SaveCapsuleParams = BaseParams & {
  capsule: Omit<
    Capsule,
    | 'id'
    | 'iconURL'
    | 'visual'
    | 'publishable'
    | 'componentType'
    | 'baseIconName'
    | 'baseIconURL'
    | 'baseDisplayTitle'
    | 'realmMaintainer'
    | 'metadata'
  >;
  collectionId: string;
  groupId: string;
  mocks: MockType[];
};

type SaveCapsuleResult = Capsule;

export type ExecuteParams = {
  realm: string;
  flowSpec: FlowSpec;
  payload: string;
  trackingId: string;
  params: {
    params: Record<string, string>;
    accountLabels: Record<string, string>;
  };
  inSpec: Capsule['inSpec'];
  outSpec: Capsule['outSpec'];
  accountLabels: Record<string, string>;
};

type CapsulePublishParams = {
  realm: string;
  collectionId: string;
  capsuleId: string;
};
type CapsuleMoveParams = {
  realm: string;
  capsuleGroupId: string;
  capsuleIds: string[];
};

type IsCapsuleNameValidParams = {
  realm: string;
  capsuleName: string;
  collectionId: string;
};
type CanCapsuleMoveParams = {
  realm: string;
  capsuleId: string;
  destinationGroupId: string;
};
type OnSearchCapsuleParams = {
  realm: string;
  collectionId: string;
  searchTerm: string;
  disabled: boolean;
  capsuleGroupId: string;
  size: number;
  page: number;
};

type CapsulePublishResult = Capsule;

type ConvertJson = {
  message: string | undefined;
  status: number;
  data: any;
  'X-Digibee-Debug': string;
  'X-Digibee-Key': string;
};

export type CanCapsuleMoveResult = {
  status: number;
  data: {
    hasAnotherVersion: boolean;
    hasDisabledVersion: boolean;
  };
};

type GenerateAIDocumentationParams = {
  realm: string;
  id: string;
  title: string;
  name: string;
  description: string;
  outSpec: Record<string, unknown>;
  configExample: Record<string, unknown>;
  jsonSchema: Record<string, unknown>[];
  flowSpec: Record<string, unknown>;
};

type GenerateAIDocumentationResult = {
  generateCapsuleAIDocumentation: string;
};

const controllers: AbortController[] = [];

const convertJson = (stringJson: string | undefined): ConvertJson => {
  if (!stringJson) return {} as ConvertJson;
  return JSON.parse(stringJson);
};

const capsuleV2 = {
  getCollectionHeaderImage: async (imageURL: string, token: string) => {
    try {
      const { data } = await axios.get<Blob>(imageURL, {
        responseType: 'blob',
        headers: {
          authorization: token,
          apikey: API_KEY
        }
      });
      return data;
    } catch (error) {
      if (error instanceof Error) throw Error(error.message);
      throw error;
    }
  },
  fetchCapsulesCollections: async (variables: CollectionsFetchParams) => {
    try {
      const { data } = await clientApollo.query<{
        capsuleCollectionsV2: CollectionsFetchResult;
      }>({
        query: gql`
          query capsuleCollectionsV2($realm: String!) {
            capsuleCollectionsV2(realm: $realm) {
              content
              last
              totalPages
              number
              first
              numberOfElements
              size
              totalElements
            }
          }
        `,
        variables
      });
      return data.capsuleCollectionsV2;
    } catch (error) {
      if (error instanceof Error && isApolloError(error))
        throw Error(error.message);
      throw error;
    }
  },
  fetchCapsulesCollectionsV2: async (variables: CollectionsFetchParams) => {
    try {
      const result = await clientApollo.query<{
        capsuleCollectionsV2: CollectionsFetchResult;
      }>({
        query: gql`
          query capsuleCollectionsV2($realm: String!) {
            capsuleCollectionsV2(realm: $realm) {
              content
              last
              totalPages
              number
              first
              numberOfElements
              size
              totalElements
            }
          }
        `,
        variables
      });
      return result.data.capsuleCollectionsV2;
    } catch (error) {
      if (error instanceof Error && isApolloError(error))
        throw Error(error.message);
      throw error;
    }
  },
  fetchCollectionHeaders: async (
    variables: CapsuleFetchCollectionHeaderParams
  ) => {
    try {
      const result = await clientApollo.query<{
        capsuleCollectionHeaderV2: CapsuleCollectionHeader[];
      }>({
        query: gql`
          query capsuleCollectionHeaderV2($realm: String!) {
            capsuleCollectionHeaderV2(realm: $realm) {
              id
              title
              iconName
              iconURL
              disabled
            }
          }
        `,
        variables
      });

      return result.data.capsuleCollectionHeaderV2;
    } catch (error) {
      if (error instanceof Error && isApolloError(error))
        throw Error(error.message);
      throw error;
    }
  },
  fetchCollectionGroup: async (variables: CollectionGroupsFetchParams) => {
    try {
      const result = await clientApollo.query<{
        capsuleCollectionGroupsV2: CollectionGroupsFetchResult;
      }>({
        query: gql`
          query capsuleCollectionGroupsV2(
            $realm: String!
            $collectionId: String!
          ) {
            capsuleCollectionGroupsV2(
              realm: $realm
              collectionId: $collectionId
            ) {
              id
              title
            }
          }
        `,
        variables
      });

      return result.data.capsuleCollectionGroupsV2;
    } catch (error) {
      if (error instanceof Error && isApolloError(error))
        throw Error(error.message);
      throw error;
    }
  },
  fetchCollectionGroupV3: async (variables: CollectionGroupsFetchParams) => {
    try {
      const { data } = await clientApollo.query<{
        capsuleCollectionGroupsV3: CollectionGroupsFetchResult;
      }>({
        query: gql`
          query capsuleCollectionGroupsV3(
            $realm: String!
            $collectionId: String!
          ) {
            capsuleCollectionGroupsV3(
              realm: $realm
              collectionId: $collectionId
            ) {
              id
              title
            }
          }
        `,
        variables
      });

      return data.capsuleCollectionGroupsV3;
    } catch (error) {
      if (error instanceof Error && isApolloError(error))
        throw Error(error.message);
      throw error;
    }
  },
  get: async (variables: CapsuleFetchParams) => {
    try {
      const result = await clientApollo.query<{
        capsuleV2: CapsuleFetchResult;
      }>({
        query: gql`
          query capsuleV2($realm: String!, $id: String!) {
            capsuleV2(realm: $realm, capsuleId: $id) {
              id
              title
              documentation
              name
              versionMajor
              versionMinor
              versionFix
              disabled
              description
              state
              capsuleKey
              capsuleCollectionId
              capsuleCollectionGroup
              iconName
              inSpec
              outSpec
              configExample
              jsonSchema
              testMode
              flowSpec
              disconnectedFlowSpecs
              errorDetail
              configSpec
              mocks
            }
          }
        `,
        variables
      });

      return {
        data: {
          capsule: result.data.capsuleV2
        }
      };
    } catch (error) {
      if (error instanceof Error && isApolloError(error))
        throw Error(error.message);
      throw error;
    }
  },
  saveCollectionHeader: async (
    variables: CapsuleSaveCollectionHeaderParams
  ) => {
    try {
      const result = await clientApollo.mutate<{
        createCapsuleCollectionHeaderV2: CapsuleSaveCollectionHeaderResult;
      }>({
        mutation: gql`
          mutation createCapsuleCollectionHeaderV2(
            $realm: String!
            $header: CollectionHeaderInput
          ) {
            createCapsuleCollectionHeaderV2(realm: $realm, header: $header) {
              id
              title
            }
          }
        `,
        variables
      });

      return result.data?.createCapsuleCollectionHeaderV2;
    } catch (error) {
      if (error instanceof Error && isApolloError(error))
        throw Error(error.message);
      throw error;
    }
  },
  saveCollection: async (variables: CapsuleSaveCollectionParams) => {
    try {
      const result = await clientApollo.mutate<{
        createCapsuleCollectionV2: CapsuleSaveCollectionResult;
      }>({
        mutation: gql`
          mutation createCapsuleCollectionV2(
            $realm: String!
            $title: String!
            $name: String!
            $description: String!
            $headerId: String!
            $colorDefault: String!
            $colorBackground: String!
            $colorAccent: String!
          ) {
            createCapsuleCollectionV2(
              realm: $realm
              title: $title
              name: $name
              description: $description
              headerId: $headerId
              colorDefault: $colorDefault
              colorBackground: $colorBackground
              colorAccent: $colorAccent
            )
          }
        `,
        variables
      });

      return result.data?.createCapsuleCollectionV2;
    } catch (error) {
      if (error instanceof Error && isApolloError(error))
        throw Error(error.message);
      throw error;
    }
  },
  saveGroup: async (variables: CapsuleSaveGroupParams) => {
    try {
      const result = await clientApollo.mutate<{
        createCapsuleCollectionGroupV2: CapsuleSaveGroupResult;
      }>({
        mutation: gql`
          mutation createCapsuleCollectionGroupV2(
            $realm: String!
            $collectionId: String!
            $group: CollectionGroupInput
          ) {
            createCapsuleCollectionGroupV2(
              realm: $realm
              collectionId: $collectionId
              group: $group
            ) {
              id
              title
            }
          }
        `,
        variables
      });

      return result.data?.createCapsuleCollectionGroupV2;
    } catch (error) {
      if (error instanceof Error && isApolloError(error))
        throw Error(error.message);
      throw error;
    }
  },
  saveGroupV3: async (variables: CapsuleSaveGroupParams) => {
    try {
      const { data } = await clientApollo.mutate<{
        createCapsuleCollectionGroupV3: CapsuleSaveGroupResult;
      }>({
        mutation: gql`
          mutation createCapsuleCollectionGroupV3(
            $realm: String!
            $collectionId: String!
            $group: CollectionGroupInput
          ) {
            createCapsuleCollectionGroupV3(
              realm: $realm
              collectionId: $collectionId
              group: $group
            ) {
              id
              title
            }
          }
        `,
        variables
      });

      return data?.createCapsuleCollectionGroupV3;
    } catch (error) {
      if (error instanceof Error && isApolloError(error))
        throw Error(error.message);
      throw error;
    }
  },
  saveCapsule: async (variables: SaveCapsuleParams) => {
    try {
      const result = await clientApollo.mutate<{
        createOrUpdateCapsuleV2: SaveCapsuleResult;
      }>({
        mutation: gql`
          mutation createOrUpdateCapsuleV2(
            $realm: String!
            $capsule: CapsuleInput!
            $collectionId: String!
            $groupId: String!
            $mocks: JSON
          ) {
            createOrUpdateCapsuleV2(
              realm: $realm
              capsule: $capsule
              collectionId: $collectionId
              groupId: $groupId
              mocks: $mocks
            ) {
              id
              title
              documentation
              name
              versionMajor
              versionMinor
              versionFix
              disabled
              description
              state
              capsuleKey
              capsuleCollectionId
              capsuleCollectionGroup
              iconName
              inSpec
              outSpec
              configExample
              jsonSchema
              testMode
              flowSpec
              disconnectedFlowSpecs
              errorDetail
              mocks
            }
          }
        `,
        variables
      });

      return result.data?.createOrUpdateCapsuleV2 as Capsule;
    } catch (error) {
      if (error instanceof Error && isApolloError(error))
        throw Error(error.message);
      throw error;
    }
  },
  execute: async (variables: ExecuteParams) => {
    try {
      const controller = new window.AbortController();
      controllers.push(controller);

      const response = await clientApollo.mutate<{ executeCapsuleV2: string }>({
        mutation: gql`
          mutation executeCapsuleV2(
            $realm: String!
            $flowSpec: JSON!
            $payload: String!
            $trackingId: String!
            $inSpec: JSON
            $outSpec: JSON
            $params: JSON
            $accountLabels: JSON
          ) {
            executeCapsuleV2(
              realm: $realm
              flowSpec: $flowSpec
              payload: $payload
              trackingId: $trackingId
              params: $params
              inSpec: $inSpec
              outSpec: $outSpec
              accountLabels: $accountLabels
            )
          }
        `,
        variables,
        context: { fetchOptions: { signal: controller.signal } }
      });

      const { data, message, status, ...header } = convertJson(
        response?.data?.executeCapsuleV2
      );

      if ([400, 409, 500].includes(status)) {
        if (typeof message === 'string') throw new Error(message);

        throw new Error('Problem sending to test');
      }

      if ([502, 504].includes(status) || data?.message === 'Timeout') {
        throw new Error('timeout');
      }

      if (!data && message) {
        throw new Error(message);
      }

      return {
        digibeeKey: header['X-Digibee-Key'],
        data
      };
    } catch (e) {
      if (e instanceof Error && isApolloError(e)) throw Error(e.message);

      throw e;
    }
  },
  publish: async (variables: CapsulePublishParams) => {
    try {
      const result = await clientApollo.mutate<{
        publishCapsuleV2: CapsulePublishResult;
      }>({
        mutation: gql`
          mutation publishCapsuleV2(
            $realm: String!
            $collectionId: String!
            $capsuleId: String!
          ) {
            publishCapsuleV2(
              realm: $realm
              collectionId: $collectionId
              capsuleId: $capsuleId
            ) {
              id
              title
              documentation
              name
              versionMajor
              versionMinor
              versionFix
              disabled
              description
              state
              capsuleKey
              capsuleCollectionId
              capsuleCollectionGroup
              iconName
              inSpec
              outSpec
              configExample
              jsonSchema
              testMode
              flowSpec
              disconnectedFlowSpecs
              errorDetail
            }
          }
        `,
        variables
      });

      return result.data?.publishCapsuleV2;
    } catch (error) {
      if (error instanceof Error && isApolloError(error))
        throw Error(error.message);
      throw error;
    }
  },
  move: async (variables: CapsuleMoveParams) => {
    try {
      const result = await clientApollo.mutate({
        mutation: gql`
          mutation moveCapsule(
            $realm: String!
            $capsuleGroupId: String!
            $capsuleIds: [String]
          ) {
            moveCapsule(
              realm: $realm
              capsuleGroupId: $capsuleGroupId
              capsuleIds: $capsuleIds
            )
          }
        `,
        variables
      });

      return result;
    } catch (error) {
      if (error instanceof Error && isApolloError(error))
        throw Error(error.message);
      throw error;
    }
  },
  isCapsuleNameValid: async (variables: IsCapsuleNameValidParams) => {
    try {
      const result = await clientApollo.query<{
        isCapsuleNameValid: boolean;
      }>({
        query: gql`
          query isCapsuleNameValid(
            $realm: String!
            $capsuleName: String!
            $collectionId: String!
          ) {
            isCapsuleNameValid(
              realm: $realm
              capsuleName: $capsuleName
              collectionId: $collectionId
            )
          }
        `,
        variables
      });

      return result;
    } catch (error) {
      if (error instanceof Error && isApolloError(error))
        throw Error(error.message);
      throw error;
    }
  },
  canCapsuleMove: async (variables: CanCapsuleMoveParams) => {
    try {
      const result = await clientApollo.query<{
        canCapsuleMove: CanCapsuleMoveResult;
      }>({
        query: gql`
          query canCapsuleMove(
            $realm: String!
            $capsuleId: String!
            $destinationGroupId: String!
          ) {
            canCapsuleMove(
              realm: $realm
              capsuleId: $capsuleId
              destinationGroupId: $destinationGroupId
            )
          }
        `,
        variables
      });

      return result;
    } catch (error) {
      if (error instanceof Error && isApolloError(error))
        throw Error(error.message);
      throw error;
    }
  },
  onSearchCapsule: async (variables: OnSearchCapsuleParams) => {
    try {
      const result = await clientApollo.query<{
        onSearchCapsule: Pagination<Capsule>;
      }>({
        query: gql`
          query onSearchCapsule(
            $realm: String!
            $collectionId: String!
            $searchTerm: String!
            $disabled: Boolean!
            $capsuleGroupId: String!
            $size: Int!
            $page: Int!
          ) {
            onSearchCapsule(
              realm: $realm
              collectionId: $collectionId
              searchTerm: $searchTerm
              disabled: $disabled
              capsuleGroupId: $capsuleGroupId
              size: $size
              page: $page
            )
          }
        `,
        variables
      });
      const response = {
        success: true,
        data: result.data.onSearchCapsule
      };

      return response;
    } catch (error) {
      if (error instanceof Error && isApolloError(error))
        throw Error(error.message);
      throw error;
    }
  },
  async generateAIDocumentation({
    realm,
    id,
    title,
    name,
    description,
    outSpec,
    configExample,
    jsonSchema,
    flowSpec
  }: GenerateAIDocumentationParams): Promise<string> {
    try {
      const response = await clientApollo.mutate<GenerateAIDocumentationResult>(
        {
          mutation: gql`
            mutation generateCapsuleAIDocumentation(
              $realm: String!
              $id: String!
              $title: String!
              $name: String!
              $description: String!
              $outSpec: JSON!
              $configExample: JSON!
              $jsonSchema: [JSON!]!
              $flowSpec: JSON!
            ) {
              generateCapsuleAIDocumentation(
                realm: $realm
                id: $id
                title: $title
                name: $name
                description: $description
                outSpec: $outSpec
                configExample: $configExample
                jsonSchema: $jsonSchema
                flowSpec: $flowSpec
              )
            }
          `,
          variables: {
            realm,
            id,
            title,
            name,
            description,
            outSpec,
            configExample,
            jsonSchema,
            flowSpec
          }
        }
      );

      return response.data?.generateCapsuleAIDocumentation || '';
    } catch (e) {
      if (e instanceof Error && isApolloError(e)) throw Error(e.message);
      throw e;
    }
  }
};

export default capsuleV2;
