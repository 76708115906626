import styled, { css } from 'styled-components';

export const Container = styled.div<{ isAnimation: boolean }>`
  will-change: transition;
  height: auto;
  background: ${p => p.theme.colors.white};
  width: 100%;
  overflow: inherit;
  ${props =>
    props.isAnimation
      ? css`
          box-shadow: 0px 13px 23px 0px #0000004f;
        `
      : ''};
  transition: box-shadow 0.3s;
  position: relative;
`;

export const Top = styled.div<{ isAnimation?: boolean }>`
  padding-top: ${props => (props.isAnimation ? '16px' : '24px')};
  padding-left: 24px;
  padding-right: 16px;
  display: flex;
  margin-bottom: 5px;
`;

export const Bottom = styled.div`
  width: 100%;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Left = styled(Box)`
  margin-top: 8px;
  width: 25%;
  align-items: flex-start;
`;

export const Center = styled(Box)`
  will-change: transition;
  width: 50%;
  align-items: center;
`;

export const Right = styled(Box)`
  width: 25%;
  align-items: flex-end;
`;

export const Logo = styled.img`
  will-change: transition;
  transition: height 0.5s ease;
  width: 190px;
  padding-right: 14px;
`;
