import { SideSheet } from '@digibee/beehive-ui';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import * as Elements from './MembersSidesheet.elements';
import { Props } from './MembersSidesheet.enhancer';
import UsersList from '../UsersList';

import i18n from '~/common/helpers/i18n';

type Option = {
  label: string,
  value: string
}

const MembersSidesheet = ({
  modal,
  userId,
  closeModal,
  patchGroup,
  loadUsers,
  userForm,
  setUserForm,
}: Props) => {
  const { t } = useTranslation();

  const formValue = useMemo(() => {
    const defaultOption = {
      label: t('label.select_placeholder'),
      value: ''
    };
    const selectedRealm = modal?.realmsGroup?.find((realm) => realm.name === userForm.realm?.label) || null;

    const selectedRealmOption = selectedRealm ? { label: selectedRealm.name, value: selectedRealm.name } : defaultOption;
    const selectedUserOption = userForm.user ? { label: userForm.user.label, value: userForm.user.value } : defaultOption

    return {
      selectedRealmOption,
      selectedUserOption,
    }
  }, [userForm, modal, t]);

  return (
    <SideSheet
      size='small'
      onClose={() => {
        setUserForm({
          realm: null,
          user: null,
        });
        closeModal();
      }}
      open={modal.visible}
      header={i18n.t('scenes.groups.labels.group_edit')}
    >
      <Elements.Wrapper>
        <Elements.FormWrapper direction='row' align='end' wrap='wrap'>
          <Elements.RealmSelect
            label={t('common.labels.realm')}
            data-testid='governance-members-sidesheet-select-realm'
            disabled={modal.loading}
            onChange={(option: Option) => setUserForm((previousValue) => ({
              ...previousValue,
              realm: option,
            }))}
            options={modal.realmsGroup?.map((realm) => ({ label: realm.name, value: realm.id }))}
            value={formValue.selectedRealmOption}
          />

          <Elements.UserSelectAsync
            // @ts-ignore
            defaultOptions
            key={userForm.realm?.value}
            label={t('label.user') || ''}
            placeholder={t('label.select_placeholder') || ''}
            data-testid='governance-members-sidesheet-select-user'
            // @ts-ignore
            isDisabled={modal.loading || !userForm.realm}
            //  @ts-ignore
            loadOptions={loadUsers}
            // @ts-ignore
            onChange={(value) => {
              setUserForm((previousValue) => ({
                ...previousValue,
                user: value,
              }));
            }}
            value={formValue.selectedUserOption}
            debounceTimeout={1000}
            variant='async-paginate'
            additional={{
              page: -1,
              searchPage: -1,
              previousSearch: ''
            }}
            className='fs-mask'
          />
          <Elements.AddButton
            size='small'
            disabled={modal.loading || !userForm.user}
            onClick={() => {
              patchGroup({
                groupName: modal.selectedGroupName,
                users: [...modal.data || [], { realm: userForm.realm?.label, userId: userForm.user?.value, email: userForm.user?.email }]
              });
              setUserForm((previousValue) => ({
                ...previousValue,
                user: null,
              }))
            }}
          >
            {t('label.add_user')}
          </Elements.AddButton>
        </Elements.FormWrapper>

        <UsersList
          userId={userId}
          loading={modal.loading}
          patchGroup={patchGroup}
          users={modal.data || []}
          groupName={modal.selectedGroupName}
        />
      </Elements.Wrapper>
    </SideSheet>
  )
};

export default memo(MembersSidesheet);
