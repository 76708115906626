import Flow, {
  isChoiceSpec,
  isLevelComponentSpec,
  isParallelSpec
} from '@digibee/flow';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import moment from 'moment';
import { toast } from 'react-toastify';
import Immutable from 'seamless-immutable';
import { v4 as uuid } from 'uuid';

// eslint-disable-next-line import/no-cycle
import * as api from '~/api';
import createIconCapsule from '~/common/helpers/createIconCapsule';
import getBase64Url from '~/common/helpers/getBase64Url';
import i18n from '~/common/helpers/i18n';
import WebAnalytics from '~/common/helpers/webAnalytics';
import { mergeDisconnectedFlowsWithFlowSpec } from '~/scenes/Build/helpers';

const connectorsInitialState = {
  triggers: [],
  components: [],
  capsulesConsumers: []
};

const findComponentData = (componentsData, id) => {
  const tracks = Object.keys(componentsData);
  const track = tracks.find(trackItem => componentsData[trackItem][id]);
  return [componentsData[track][id], track];
};

const convertUUIDS = flowSpec =>
  Object.keys(flowSpec).reduce((acc, key) => {
    const track = flowSpec[key];
    return {
      ...acc,
      [key]: [
        ...track.map(step => ({
          ...step,
          id: step.id || step.uuid || uuid(),
          uuid: undefined
        }))
      ]
    };
  }, {});

const trackingId = uuid();
const initialState = Immutable({
  canUpdateInstance: false,
  pipeline: null,
  fetching: {
    pipeline: true,
    connectors: false
  },
  fetched: {
    pipeline: false,
    connectors: false
  },
  saving: false,
  canvasSpec: null,
  componentsData: null,
  connectors: connectorsInitialState,
  replicas: [],
  testModeSize: 32
});

const model = {
  name: 'designPipeline',
  state: initialState,
  reducers: {
    setTestModeSize: (state, payload) => state.merge({ testModeSize: payload }),
    setInstances: (state, payload) => state.merge({ instances: payload }),
    setFetching: (state, name) => state.setIn(['fetching', name], true),
    setFetched: (state, name) =>
      state.setIn(['fetching', name], false).setIn(['fetched', name], true),
    setConnectors: (state, payload) => state.merge({ connectors: payload }),
    setReplicas: (state, payload) => state.merge({ replicas: payload }),
    setComponentsData: (state, payload) =>
      state.setIn(['componentsData'], payload),
    setData(state, payload) {
      return state.merge({
        canUpdateInstance: !payload.pipeline.parameterizedReplica,
        sensitiveFields: payload.sensitiveFields,
        pipeline: payload.pipeline,
        canvasSpec: payload.canvasSpec,
        componentsData: payload.componentsData
      });
    },
    setPipelineSettings: (
      state,
      {
        description,
        sensitiveFields,
        inSpec,
        outSpec,
        parameterizedReplica,
        hasInstance
      }
    ) =>
      state
        .setIn(['pipeline', 'description'], description)
        .setIn(
          ['pipeline', 'parameterizedReplica'],
          hasInstance ? parameterizedReplica : null
        )
        .setIn(['sensitiveFields', 'logSensitiveFields'], sensitiveFields)
        .setIn(['pipeline', 'inSpec'], inSpec || {})
        .setIn(['pipeline', 'outSpec'], outSpec || {}),

    setPipelineMocks: (state, payload) =>
      state.setIn(['pipeline', 'mocks'], payload || []),

    deleteConnectorMocks: (state, connectorId) => {
      const newMocks = state.pipeline?.mocks?.filter(
        mock => mock.connectorId !== connectorId
      );
      return state.setIn(['pipeline', 'mocks'], newMocks || []);
    },
    setSaving: (state, saving) => state.merge({ saving }),
    replacePipeline: (state, pipeline) => state.setIn(['pipeline'], pipeline),
    updatePipeline: (state, pipeline) =>
      state.merge(
        {
          canUpdateInstance: !pipeline.parameterizedReplica,
          pipeline
        },
        { deep: true }
      ),

    removeComponent: (state, id) => {
      const flow = new Flow(state.componentsData.asMutable({ deep: true }));
      const newFlow = flow.removeComponent(id);
      return state.setIn(['componentsData'], newFlow.spec());
    },

    disconnectComponent: (state, id) => {
      const flow = new Flow(state.componentsData.asMutable({ deep: true }));
      const newFlow = flow.disconnectComponent(id);
      return state.setIn(['componentsData'], newFlow.spec());
    },

    removeComponentData: (state, { track, id }) => {
      const newState = state.updateIn(['componentsData', track], val =>
        val?.without(id)
      );
      const isTrackEmpty =
        newState.componentsData[track] &&
        Object.keys(newState.componentsData[track]).length === 0;

      if (isTrackEmpty) {
        return state.updateIn(['componentsData'], val => val.without(track));
      }

      return newState;
    },

    removeParallelExecution: (state, { parallelId, target }) => {
      const [parallelComponentData, track] = findComponentData(
        state.componentsData,
        parallelId
      );

      const executions = parallelComponentData.params.parallelExecutions.filter(
        execution => execution.target !== target
      );

      const path = [track, parallelId, 'params', 'parallelExecutions'];

      return model.reducers.updateComponentData(state, {
        path,
        value: executions
      });
    },

    removeChoiceCondition: (state, { choiceId, target, conditionType }) => {
      const [choiceComponentData, track] = findComponentData(
        state.componentsData,
        choiceId
      );

      const path = [track, choiceId];

      if (conditionType === 'when') {
        const newWhen = choiceComponentData.when.filter(
          when => when.target !== target
        );

        return model.reducers.updateComponentData(state, {
          path,
          value: { ...choiceComponentData, when: newWhen }
        });
      }

      return model.reducers.updateComponentData(state, {
        path,
        value: { ...choiceComponentData, otherwise: undefined }
      });
    },

    updateComponentData: (state, { path, value, trackRemap }) => {
      const newState = state.updateIn(
        ['componentsData', ...path],
        (val, data) => (val ? { ...val, ...data } : data),
        value
      );

      if (trackRemap) {
        const [previous, next] = trackRemap;
        if (previous === next) return newState;
        return newState
          .setIn(['componentsData', next], state.componentsData[previous])
          .updateIn(['componentsData'], val => val.without(previous));
      }

      return newState;
    },

    overrideTrack: (state, { track, newTrack }) => {
      if (!state.componentsData[track]) {
        return state.updateIn(['componentsData'], val => val.without(track));
      }
      return state
        .setIn(['componentsData', newTrack], state.componentsData[track])
        .updateIn(['componentsData'], val => val.without(track));
    },

    moveComponents: (state, { track, ids, newTrack }) =>
      state
        .setIn(
          ['componentsData', newTrack],
          pick(state.componentsData[track], ids)
        )
        .updateIn(['componentsData', track], val => val.without(ids)),

    mergeComponentsData: (state, data) =>
      state.updateIn(['componentsData'], val => val.merge(data)),

    removeTracks: (state, tracks) =>
      state.updateIn(['componentsData'], val => val.without(tracks)),

    mergeTracks: (state, { from, to }) =>
      state
        .updateIn(
          ['componentsData', to],
          (val, data) => (val ? val.merge(data) : data),
          state.componentsData[from]
        )
        .updateIn(['componentsData'], val => val.without(from)),

    reset: () => initialState
  },
  effects: dispatch => ({
    async fetchPipeline({ id, realm }) {
      try {
        performance.mark('pipeline-fetch-start');

        dispatch.designPipeline.setFetching('pipeline');

        const { data } = await api.pipelineV2.getPipeline({ id, realm });

        if (data.pipeline.parameterizedReplica) {
          const { data: multiInstanceData } = await api.pipeline.multiInstance({
            replicaName: data.pipeline.parameterizedReplica,
            realm
          });

          dispatch.designPipeline.setInstances(
            multiInstanceData.replicaInstance.map(({ name }) => ({
              value: name,
              label: name
            }))
          );
        }

        performance.mark('pipeline-fetch-finish');
        const flowSpec = convertUUIDS(data.pipeline.flowSpec);
        const wasModifiedAfterFixLevelComponentsImplementation = moment(
          data.pipeline.lastModified
        ).isAfter(moment('2023-07-01'));
        const fullFlowSpec = mergeDisconnectedFlowsWithFlowSpec(
          flowSpec || {},
          data.pipeline.disconnectedFlowSpecs || [],
          wasModifiedAfterFixLevelComponentsImplementation
        );
        const flow = new Flow(fullFlowSpec);
        const stepsDocumentations = data.pipeline.stepsDocumentations || {};
        const documentationFieldMapper = currentFlow => {
          if (Object.keys(stepsDocumentations).length === 0) return currentFlow;
          const f = currentFlow.mapComponents(componentSpec => {
            if (isChoiceSpec(componentSpec)) {
              return {
                ...componentSpec,
                __documentation__: stepsDocumentations[componentSpec.id],
                when: componentSpec.when.map((config, idx) => ({
                  ...config,
                  __documentation__:
                    stepsDocumentations[`${componentSpec.id}.when.${idx}`]
                })),
                __otherwiseDocumentation__:
                  stepsDocumentations[`${componentSpec.id}.otherwise`]
              };
            }

            if (isParallelSpec(componentSpec)) {
              return {
                ...componentSpec,
                __documentation__: stepsDocumentations[componentSpec.id],
                params: {
                  ...componentSpec.params,
                  parallelExecutions:
                    componentSpec.params.parallelExecutions.map(
                      (config, idx) => ({
                        ...config,
                        __documentation__:
                          stepsDocumentations[
                            `${componentSpec.id}.executions.${idx}`
                          ]
                      })
                    )
                }
              };
            }

            return {
              ...componentSpec,
              __documentation__: stepsDocumentations[componentSpec.id]
            };
          });
          return f;
        };
        const setParamsOnException = currentFlow => {
          const f = currentFlow.mapComponents(componentSpec => {
            if (
              isLevelComponentSpec(componentSpec) &&
              !componentSpec?.params?.onException
            ) {
              return {
                ...componentSpec,
                params: {
                  ...componentSpec.params,
                  onException: `${componentSpec.id}-onExceptionTrack`
                }
              };
            }

            return componentSpec;
          });
          return f;
        };
        const componentsData = flow
          .map(setParamsOnException)
          .map(documentationFieldMapper);

        const triggerSpec = {
          ...data.pipeline.triggerSpec,
          __documentation__: stepsDocumentations.trigger,
          name: data.pipeline.isUsingApiTrigger
            ? 'api'
            : data.pipeline.triggerSpec?.name
        };

        dispatch.designPipeline.setData({
          sensitiveFields: data.sensitiveFields,
          componentsData: componentsData.spec(),
          pipeline: {
            ...data.pipeline,
            triggerSpec,
            inSpec: data.pipeline.inSpec || {},
            outSpec: data.pipeline.outSpec || {},
            flowSpec: componentsData.spec(),
            mocks: data.pipeline.mocks
          }
        });
        dispatch.designPipeline.setFetched('pipeline');
      } catch (error) {
        dispatch.designPipeline.reset();
        dispatch.router.navigate({ to: `/${realm}/operation/build/pipelines` });
      }
    },
    async reload(payload, { designPipeline, application }) {
      if (designPipeline.pipeline) {
        dispatch.designPipeline.fetchPipeline({
          id: designPipeline.pipeline.id,
          realm: application.realm.realm
        });
      }
    },
    async fetchConnectors(_, { application, acls, authentication }) {
      dispatch.designPipeline.setFetching('connectors');

      const { realm } = application.realm;
      const betaShapes = acls.scopes.some(scope =>
        ['BETA:SHAPES'].includes(scope)
      );
      const response = await api.connectors.get({ realm, betaShapes });

      const components = await Promise.all(
        response?.data?.components?.map(async c => {
          const iconURL = c?.iconURL;
          return {
            ...c,
            iconURL: iconURL ? await getBase64Url(iconURL) : null,
            shape: c.shape === 'ellipsis' ? 'ellipse' : c.shape
          };
        })
      ).catch(() => response?.data?.components);

      const restTrigger = response?.data?.triggers.find(
        ({ name }) => name === 'rest'
      );
      const apiTriggerSchema = restTrigger?.jsonSchema?.[0].schema.filter(
        ({ property }) => !['methods', 'uris', 'advanced'].includes(property)
      );
      const apiTrigger = {
        ...restTrigger,
        documentationURL: i18n.t('action.trigger_api_doc'),
        name: 'api',
        jsonSchema: [
          {
            ...restTrigger.jsonSchema[0],
            schema: apiTriggerSchema
          }
        ]
      };
      const triggersWithApiTrigger = [...response?.data?.triggers, apiTrigger];
      const triggers = await Promise.all(
        triggersWithApiTrigger?.map(async c => {
          const iconURL = c?.iconURL;
          return {
            ...c,
            iconURL: iconURL ? await getBase64Url(iconURL) : null
          };
        })
      ).catch(() => triggersWithApiTrigger);

      const capsulesConsumers = await Promise.all(
        response?.data?.capsulesConsumers?.map(async c => {
          const header = await getBase64Url(
            c?.capsuleCollectionHeader?.iconURL,
            authentication?.userData?.token
          );
          return {
            ...c,
            capsuleServices: c.capsuleServices.map(capsule => {
              const iconURL = createIconCapsule({
                header,
                accent: c?.colorAccent,
                background: c?.colorBackground,
                icon: capsule?.iconName
              });

              return {
                ...capsule,
                iconURL
              };
            })
          };
        })
      ).catch(() => response?.data?.capsulesConsumers);

      const librariesConsumers = await Promise.all(
        response?.data?.librariesConsumers?.map(async c => {
          const iconURL = await getBase64Url(c?.iconURL);
          return {
            ...c,
            iconURL: iconURL || null,
            libraryServices: c?.libraryServices?.map(l => ({
              ...l,
              iconURL: iconURL || null
            }))
          };
        })
      ).catch(() => response?.data?.librariesConsumers);

      const libraries = response?.data?.libraries;
      const replicas = response?.data?.replicas;

      const connectors = {
        components,
        triggers,
        capsulesConsumers,
        librariesConsumers,
        libraries
      };

      dispatch.designPipeline.setConnectors(connectors);
      dispatch.designPipeline.setReplicas(
        replicas.map(({ label }) => ({
          label,
          value: label
        }))
      );
      dispatch.designPipeline.setFetched('connectors');
    },
    async fetchReplicas({ realm }) {
      const response = await api.connectors.getReplicas({ realm });
      const replicas = response?.data?.replicas;

      dispatch.designPipeline.setReplicas(
        replicas.map(({ label }) => ({
          label,
          value: label
        }))
      );
    },
    updatePipelineSettings: async (data, rootState) => {
      dispatch.designPipeline.setPipelineSettings(data);

      if (data.parameterizedReplica) {
        const { realm } = rootState.application.realm;
        const { data: multiInstanceData } = await api.pipeline.multiInstance({
          replicaName: data.parameterizedReplica,
          realm
        });

        dispatch.designPipeline.setInstances(
          multiInstanceData.replicaInstance.map(({ name }) => ({
            value: name,
            label: name
          }))
        );
      }
    },
    save: async (
      {
        name,
        description,
        image,
        projectId,
        isUpdateVersion,
        flowSpec,
        componentsCount,
        usedComponents,
        suggestedComponentsIds,
        connectedOnFlowComponentsCount,
        disconnectedFlowSpecs,
        stepsDocumentations,
        triggerSpec,
        onSuccess,
        onFailure,
        restApiTriggerRoutes,
        isUsingApiTrigger,
        mocks
      },
      { designPipeline, application, authentication }
    ) => {
      const {
        lastModified: ___,
        disabled: __,
        canvasVersion: _,
        ...pipeline
      } = designPipeline.pipeline;
      const { realm } = application.realm;

      dispatch.designPipeline.setSaving(true);

      try {
        const saveImage = async () => {
          try {
            return await api.pipelineV2.saveImage({
              token: authentication.userData.token,
              image,
              realm,
              thumbnailName: pipeline.thumbnailName
            });
          } catch (error) {
            return undefined;
          }
        };

        const data = {
          realm,
          trackingId,
          projectId,
          pipeline: {
            ...omit(pipeline, 'mocks'),
            name: pipeline.name === 'Untitled' ? name : pipeline.name,
            description: description || pipeline.description,
            thumbnailName: await saveImage(),
            flowSpec,
            restApiTriggerRoutes,
            isUsingApiTrigger,
            disconnectedFlowSpecs,
            componentsCount,
            connectedOnFlowComponentsCount,
            usedComponents,
            suggestedComponentsIds,
            stepsDocumentations: {
              ...stepsDocumentations,
              // eslint-disable-next-line no-underscore-dangle
              trigger: triggerSpec.__documentation__
            },
            triggerSpec: {
              ...triggerSpec,
              __documentation__: undefined
            }
          },
          mocks
        };

        const updatePipelineV2 = await api.pipelineV2.save(data);

        const sensitiveFieldsData = {
          realm,
          pipelineId: updatePipelineV2.id,
          sensitiveFields:
            designPipeline.sensitiveFields?.logSensitiveFields || []
        };

        await api.pipelineV2.updateSensitiveFields(sensitiveFieldsData);

        onSuccess?.();

        dispatch.designPipeline.updatePipeline({
          ...updatePipelineV2,
          restApiTriggerRoutes
        });

        if (updatePipelineV2.id) {
          const pipelineBaseURL = `/${realm}/design/v2/pipelines`;

          if (
            window.location.pathname.includes(pipelineBaseURL) &&
            window.location.pathname.includes(updatePipelineV2.id) === false
          ) {
            const newURL = `${pipelineBaseURL}/${updatePipelineV2.id}`;
            window.history.replaceState(null, null, newURL);
          }

          if (isUpdateVersion) window.location.reload();

          return toast.success(i18n.t('label.pipeline_saved_msg_success'));
        }

        return false;
      } catch (error) {
        onFailure?.();
        if (error.message.includes('409')) {
          WebAnalytics.sendEvent('[Build] (Pipeline Save) Conflict', {
            errorMessage: error.message,
            data: {
              realm,
              trackingId,
              projectId,
              pipeline: {
                createdAt: pipeline.createdAt,
                draft: pipeline.draft,
                name: pipeline.name === 'Untitled' ? name : pipeline.name,
                id: pipeline.id,
                versionMajor: pipeline.versionMajor,
                versionMinor: pipeline.versionMinor
              }
            }
          });

          return toast.error(
            `${i18n.t(
              'label.update_pipeline_msg_error'
            )} (${error.message.replace('GraphQL error:', 'Error:')})`
          );
        }

        return toast.error(error.message);
      } finally {
        dispatch.designPipeline.setSaving(false);
      }
    },
    revertPipelineCanvasVersion: (_, { application, designPipeline }) => {
      const { realm } = application.realm;
      const { id: pipelineId } = designPipeline.pipeline;

      api.pipelineV2.revertPipelineCanvasVersion({
        realm,
        pipelineId,
        trackingId
      });
    }
  }),
  logics: []
};

export default model;
