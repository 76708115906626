import moment from 'moment';
import Immutable from 'seamless-immutable';

// Locals
import { license } from '~/api';
import i18n from '~/common/helpers/i18n';
import { csvDownload, download } from '~/common/helpers/jsonToCsv';
import { getStorage } from '~/common/helpers/storage';

const storage = getStorage('localstorage');

const storageKeys = {
  licensePlanName: 'licensePlanName',
  consumptionBasedMetricsData: 'consumptionBasedMetricsData',
  consumptionBasedMetricsDataLastUpdatedAt: 'consumptionBasedMetricsDataLastUpdatedAt',
}

const initialState = Immutable({
  current: '',

  getRealm: null,
  license: null,
  usageList: null,
  pipelinesUsage: null,
  limitDetail: null,
  proposalUpsell: null,

  loadingLimitDetail: false,
  loadingPipelineUsage: false,
  loadingExport: false,
  loadingLicense: false,
  loadingLimitOfUse: false,
  loadingProposalUpsell: false,
  loadingConsumptionByProjects: false,
  loadingDownloadCreditsHistory: false,
  loadingDownloadProjectsPerPipeline: false,
  loadingDownloadDailyConsumption: false,

  contacts: null,
  openContacts: false,

  pipelinesSidesheetData: {
    selectedPipelines: null,
    projectName: '',
    projectDescription: '',
    currency: null
  },
  consumptionModelData: null,
  loadingConsumptionModel: false
});

const licenseModel = {
  name: 'license',
  state: initialState,
  reducers: {
    setEntity(state, realms) {
      return state.merge({ ...realms });
    },
    setRealm(state, realm) {
      return state.merge({ current: realm });
    },
    setGetRealm(state, realm) {
      return state.merge({ getRealm: realm });
    },
    setLicense(state, licenseData) {
      return state.merge({ license: licenseData });
    },
    setUsageList(state, usageList) {
      return state.merge({ usageList });
    },
    setLimitOfUse(state, limitOfUse) {
      return state.merge({ limitOfUse });
    },
    setPipelinesUsage(state, pipelinesUsage) {
      return state.merge({ pipelinesUsage });
    },
    setLimitDetail(state, limitDetail) {
      return state.merge({ limitDetail });
    },
    setProposalUpsell(state, proposalUpsell) {
      return state.merge({ proposalUpsell });
    },
    setLoadingPipelineUsage(state, loadingPipelineUsage) {
      return state.merge({ loadingPipelineUsage });
    },
    setLoadingExport(state, loadingExport) {
      return state.merge({ loadingExport });
    },
    setLoadingLicense(state, loadingLicense) {
      return state.merge({ loadingLicense });
    },
    setLoadingLimitOfUse(state, loadingLimitOfUse) {
      return state.merge({ loadingLimitOfUse });
    },
    setLoadingLimitDetail(state, loadingLimitDetail) {
      return state.merge({ loadingLimitDetail });
    },
    setLoadingConsumptionModel(state, loadingConsumptionModel) {
      return state.merge({ loadingConsumptionModel });
    },
    setContacts(state, contacts) {
      return state.merge({ contacts });
    },
    setLoadingProposalUpsell(state, loadingProposalUpsell) {
      return state.merge({ loadingProposalUpsell });
    },
    setLoadingConsumptionByProjects(state, loadingConsumptionByProjects) {
      return state.merge({ loadingConsumptionByProjects });
    },
    setOpenContacts(state, openContacts) {
      return state.merge({ openContacts });
    },
    setConsumptionModelData(state, consumptionModelData) {
      return state.merge({ consumptionModelData });
    },
    setPipelinesSidesheetData(state, pipelinesSidesheetData) {
      return state.merge({ pipelinesSidesheetData });
    },
    setLoadingDownloadCreditsHistory(state, loadingDownloadCreditsHistory) {
      return state.merge({ loadingDownloadCreditsHistory });
    },
    setLoadingDownloadProjectsPerPipeline(
      state,
      loadingDownloadProjectsPerPipeline
    ) {
      return state.merge({ loadingDownloadProjectsPerPipeline });
    },
    setLoadingDownloadDailyConsumption(state, loadingDownloadDailyConsumption) {
      return state.merge({ loadingDownloadDailyConsumption });
    },
    setConsumptionByProjects(state, consumptionByProjects) {
      return state.merge({
        consumptionModelData: {
          ...state.consumptionModelData,
          consumptionByProjects
        }
      });
    }
  },
  effects: dispatch => ({
    async getLicense(params, state) {
      try {
        const licensePlanName = storage.get(storageKeys.licensePlanName);
        dispatch.license.setLoadingLicense(licensePlanName !== 'Consumption Based Model');
        const data = await license.getLicense({
          realm: state.application.realm.realm,
          projectsInformation: params?.projectsInformation,
          environment: 'all',
          token: state.authentication?.userData?.token
        });

        if (data?.planName === 'Consumption Based Model') {
          dispatch.license.getConsumptionBasedMetrics();
        }

        storage.set(storageKeys.licensePlanName, data?.planName);

        dispatch.license.setLicense(data);
        if (params?.projectsInformation) {
          dispatch.license.setUsageList(data?.projectsInformation?.projects);
        }
      } catch (e) {
        dispatch.license.setLicense(null);
      } finally {
        dispatch.license.setLoadingLicense(false);
      }
    },
    async getConsumptionBasedMetrics(_, state) {
      try {
        const now = moment();
        const maxHourToRefetch = 6;
        const storageConsumptionBasedMetrics = storage.get(storageKeys.consumptionBasedMetricsData, true);
        const storageConsumptionBasedMetricsLastUpdatedAt = moment(storage.get(storageKeys.consumptionBasedMetricsDataLastUpdatedAt, true) || new Date());
        const duration = moment.duration(now.diff(storageConsumptionBasedMetricsLastUpdatedAt));

        if (storageConsumptionBasedMetrics && duration.asHours() < maxHourToRefetch) {
          dispatch.license.setConsumptionModelData(storageConsumptionBasedMetrics);
          return;
        }
        dispatch.license.setLoadingConsumptionModel(true);
        const { data } = await license.getConsumptionBasedMetrics(
          state.application.realm.realm
        );
        const consumptionBasedMetrics = data?.consumptionBasedMetrics;

        storage.set(storageKeys.consumptionBasedMetricsData, consumptionBasedMetrics);
        storage.set(storageKeys.consumptionBasedMetricsDataLastUpdatedAt, moment());
        dispatch.license.setConsumptionModelData(consumptionBasedMetrics);
      } catch (e) {
        dispatch.license.setConsumptionModelData(null);
      } finally {
        dispatch.license.setLoadingConsumptionModel(false);
      }
    },
    async downloadCreditsHistory(_, state) {
      try {
        const { realm } = state.application.realm;
        dispatch.license.setLoadingDownloadCreditsHistory(true);
        const { data } = await license.downloadCreditsInformation(realm);
        download(
          data.downloadCreditsInformation,
          `${realm}_${i18n.t('label.credits_information_filename')}_${moment().format(
            'YYYY-MM-DD'
          )}.csv`
        );
        dispatch.snackbar.create({
          text: i18n.t('label.download_credits_success')
        });
      } catch (e) {
        dispatch.snackbar.create({
          text: i18n.t('label.download_credits_error')
        });
      } finally {
        dispatch.license.setLoadingDownloadCreditsHistory(false);
      }
    },
    async downloadProjectsPerPipeline(params, state) {
      try {
        const { realm } = state.application.realm;
        dispatch.license.setLoadingDownloadProjectsPerPipeline(true);
        const data = await license.downloadProjectsPerPipeline(
          params,
          realm,
          state.authentication?.userData?.token
        );
        download(
          data,
          `${realm}_${i18n.t('label.projects_per_pipeline_filename')}_${moment().format(
            'YYYY-MM-DD'
          )}.csv`
        );
        dispatch.snackbar.create({
          text: i18n.t('label.download_projects_per_pipeline_success')
        });
      } catch (e) {
        dispatch.snackbar.create({
          text: i18n.t('label.download_projects_per_pipeline_error')
        });
      } finally {
        dispatch.license.setLoadingDownloadProjectsPerPipeline(false);
      }
    },
    async downloadDailyConsumption(params, state) {
      try {
        const { realm } = state.application.realm;
        dispatch.license.setLoadingDownloadDailyConsumption(true);
        const data = await license.downloadDailyConsumption(
          params,
          realm,
          state.authentication?.userData?.token
        );
        download(
          data,
          `${realm}_${i18n.t(
            'label.projects_daily_consumption_filename'
          )}_${moment().format('YYYY-MM-DD')}.csv`
        );
        dispatch.snackbar.create({
          text: i18n.t('label.download_daily_consumption_success')
        });
      } catch (e) {
        dispatch.snackbar.create({
          text: i18n.t('label.download_daily_consumption_error')
        });
      } finally {
        dispatch.license.setLoadingDownloadDailyConsumption(false);
      }
    },
    async getConsumptionByProjects(params, state) {
      try {
        dispatch.license.setLoadingConsumptionByProjects(true);
        const { data } = await license.getConsumptionByProjects(
          state.application.realm.realm,
          params.names
        );

        dispatch.license.setConsumptionByProjects(data?.consumptionProjects);
      } catch (e) {
        dispatch.license.setConsumptionByProjects(null);
      } finally {
        dispatch.license.setLoadingConsumptionByProjects(false);
      }
    },
    async getLimitOfUse(params, state) {
      try {
        dispatch.license.setLoadingLimitOfUse(true);
        const { data } = await license.getLimitOfUse({
          realm: state.application.realm.realm,
          environment: params?.environment,
          isGlobal: params?.isGlobal,
          pipeline: params?.pipeline,
          status: params?.status
        });
        dispatch.license.setLimitOfUse(data?.limitOfUse);
        dispatch.license.setLoadingLimitOfUse(false);
      } catch (e) {
        dispatch.license.setLimitOfUse(null);
        dispatch.license.setLoadingLimitOfUse(false);
      }
    },
    async getPipelinesUsage(params, state) {
      try {
        dispatch.license.setLoadingPipelineUsage(true);
        const data = await license.getPipelinesUsage({
          realm: state.application.realm.realm,
          environment: params?.environment,
          project: params?.project
        });
        dispatch.license.setPipelinesUsage(data?.pipelinesUsage);
        dispatch.license.setLoadingPipelineUsage(false);
      } catch (e) {
        dispatch.license.setPipelinesUsage(null);
        dispatch.license.setLoadingPipelineUsage(false);
      }
    },
    async getLimitOfUseDetail(params, state) {
      try {
        dispatch.license.setLoadingLimitDetail(true);
        const data = await license.getLimitOfUseDetail({
          realm: state.application.realm.realm,
          environment: params?.environment,
          type: params?.type,
          dateFrom: params?.dateFrom,
          dateTo: params?.dateTo
        });
        dispatch.license.setLimitDetail(data?.limitOfUseDetail);
        dispatch.license.setLoadingLimitDetail(false);
      } catch (e) {
        dispatch.license.setLimitDetail(null);
        dispatch.license.setLoadingLimitDetail(false);
      }
    },
    async getProposalUpsell(params, state) {
      try {
        dispatch.license.setLoadingProposalUpsell(true);
        const data = await license.getProposalUpsell({
          realm: state.application.realm.realm,
          environment: params?.environment
        });
        dispatch.license.setProposalUpsell(data?.proposalUpsell);
        dispatch.license.setLoadingProposalUpsell(false);
      } catch (e) {
        dispatch.license.setProposalUpsell(null);
        dispatch.license.setLoadingProposalUpsell(false);
      }
    },
    async exportUsageList(params, state) {
      try {
        dispatch.license.setLoadingExport(true);
        const data = await license.getPipelinesUsage({
          realm: state.application.realm.realm,
          environment: params?.environment
        });
        dispatch.license.setPipelinesUsage(data?.pipelinesUsage);
        csvDownload({
          data: data?.pipelinesUsage?.deployments?.map(usage => ({
            project: usage?.projectName,
            projectDescription: usage?.projectDescription,
            concurrentExecution: usage?.concurrentExecution,
            pipelineVersion: usage?.pipelineVersion,
            environment: usage?.environment,
            pipeline: usage?.pipelineName,
            size: usage?.size,
            replicas: usage?.replica,
            rtu: usage?.rtu
          })),
          filename: `usage-list | ${new Date().toISOString().split('T')[0]}`,
          delimiter: ',',
          headers: params?.headers
        });
        dispatch.license.setLoadingExport(false);
        dispatch.snackbar.create({
          text: i18n.t('label.download_done_msg_success')
        });
      } catch (e) {
        dispatch.license.setLoadingExport(false);
      }
    },
    async getContacts(_, state) {
      try {
        const { data } = await license.getRealmContacts(
          state.application.realm.realm
        );
        dispatch.license.setContacts(data?.realmContacts);
      } catch (e) {
        dispatch.license.setContacts(null);
      }
    }
  })
};

export default licenseModel;
