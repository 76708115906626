import { Text } from '@digibee/beehive-ui';
import { iff } from '@digibee/control-statements';
import { BaseComponent } from '@digibee/flow';
import { useSelector } from '@xstate/react';
import { useMemo } from 'react';
import { useSelector as useReduxSelector } from 'react-redux';

import { TreeNode } from '../Node';
import * as Elements from '../Tree.elements';
import useTreeContext from '../utils/useTreeContext';

import featureFlagConstants from '~/common/helpers/featureFlagConstants';
import useFeatureFlag from '~/common/hooks/useFeatureFlag';
import MockTag from '~/components/MockTag';
import { MockType } from '~/entities/Mock';
import { useDesignContext } from '~/scenes/Build/components/Design/context/useDesignContext';

type ComponentNodeProps = {
  node: TreeNode<BaseComponent>;
  shouldInspectDebugLayout: boolean;
};

const ComponentNode = ({
  node,
  shouldInspectDebugLayout
}: ComponentNodeProps) => {
  const { BUILD_CONNECTOR_MOCKING, CAPSULE_CONNECTOR_MOCKING_ENABLED_FRONT } =
    featureFlagConstants;
  const { treatments } = useFeatureFlag([
    BUILD_CONNECTOR_MOCKING,
    CAPSULE_CONNECTOR_MOCKING_ENABLED_FRONT
  ]);
  const isPipelineConnectorMockingEnabled =
    treatments[BUILD_CONNECTOR_MOCKING].treatment === 'on';
  const isCapsuleConnectorMockingEnabled =
    treatments[CAPSULE_CONNECTOR_MOCKING_ENABLED_FRONT].treatment === 'on';

  const { capsuleActor, isCapsule } = useDesignContext();
  const isConnectorMockingEnabled = isCapsule
    ? isCapsuleConnectorMockingEnabled
    : isPipelineConnectorMockingEnabled;
  const mocks = isCapsule
    ? useSelector(capsuleActor, state => state.context.mocks)
    : useReduxSelector(
        // @ts-ignore
        state => state.designPipeline.pipeline?.mocks
      );
  const hasMockActive = mocks?.find(
    (mock: MockType) => mock.connectorId === node.id && mock.enabled
  );

  const actor = useTreeContext(state => state.actor);
  const actionsSlot = useTreeContext(state => state.actionsSlot);
  const mapConnectorImage = useTreeContext(state => state.mapConnectorImage);
  const onItemDoubleClick = useTreeContext(state => state.onItemDoubleClick);

  const isCurrentLevelComponent = useSelector(
    actor,
    state => state.context.currentLevelComponent?.id() === node.id
  );

  const comesFromCurrentLevelComponent = useSelector(actor, state =>
    state.context.currentLevelComponent
      ? node.item.comesFromComponent(state.context.currentLevelComponent.id())
      : false
  );

  const emphasis =
    // eslint-disable-next-line no-nested-ternary
    isCurrentLevelComponent && !shouldInspectDebugLayout
      ? 'component'
      : comesFromCurrentLevelComponent && !shouldInspectDebugLayout
        ? 'children'
        : undefined;

  const actionsSlotRendered = actionsSlot?.({
    item: node.item
  });

  const { shape, image } = useMemo(
    () => mapConnectorImage(node.item),
    [node.item]
  );

  return (
    <Elements.Container
      data-testid='flow-tree-node-container'
      shouldInspectDebugLayout={shouldInspectDebugLayout}
    >
      <Elements.Item
        onDoubleClick={() => {
          onItemDoubleClick(node.item);
        }}
        depthLeft={node.depth}
        emphasis={emphasis}
        data-testid='build-flow-tree-node-item'
        shouldInspectDebugLayout={shouldInspectDebugLayout}
      >
        {isConnectorMockingEnabled && shouldInspectDebugLayout && (
          <Elements.MockTagContainer
            shouldInspectDebugLayout={shouldInspectDebugLayout}
            hasMockActive={hasMockActive}
          >
            <MockTag isFlowTree />
          </Elements.MockTagContainer>
        )}
        <Elements.Shape2 shape={shape} image={image} />
        {isConnectorMockingEnabled &&
          hasMockActive &&
          !shouldInspectDebugLayout && (
            <Elements.MockTagContainer
              shouldInspectDebugLayout={shouldInspectDebugLayout}
            >
              <MockTag isFlowTree />
            </Elements.MockTagContainer>
          )}
        <Elements.Label shouldInspectDebugLayout={shouldInspectDebugLayout}>
          <Text
            variant='b3'
            css={{
              fontWeight: 400,
              color: '$neu500',
              fontSize: shouldInspectDebugLayout ? '12px' : '14px',
              _truncate: '$12'
            }}
            title={node.label}
          >
            {node.label}
          </Text>
        </Elements.Label>
        {iff(!!actionsSlotRendered, () => (
          <Elements.FloatAction
            shouldInspectDebugLayout={shouldInspectDebugLayout}
          >
            {actionsSlotRendered}
          </Elements.FloatAction>
        ))}
      </Elements.Item>
    </Elements.Container>
  );
};

export default ComponentNode;
