import { gql } from '@apollo/client';

import clientApollo from './clientApollo';

const accounts = {
  find: variables =>
    clientApollo.query({
      query: gql`
        query accounts($realm: String!, $search: String) {
          accounts(realm: $realm, search: $search) {
            id
            description
            fields
            label
            accountType
          }
        }
      `,
      variables
    }),
  findById: ({ realm, accountId }) =>
    clientApollo.query({
      query: gql`
        query accountById(
          $realm: String!
          $accountId: String
        ) {
          accountById(realm: $realm, accountId: $accountId) {
            id
            description
            fields
            label
            accountType
            expirationDate
          }
        }
      `,
      variables: {
        realm,
        accountId
      }
    }),
  findPaginated: ({
    realm,
    labels,
    page,
    hasExpired,
    hasCloseToExpired,
    exactMatch
  }) =>
    clientApollo.query({
      query: gql`
        query accountsPaginated(
          $realm: String!
          $labels: String
          $page: Int
          $hasExpired: Boolean
          $hasCloseToExpired: Boolean
          $exactMatch: Boolean
        ) {
          accountsPaginated(
            realm: $realm
            labels: $labels
            page: $page
            hasExpired: $hasExpired
            hasCloseToExpired: $hasCloseToExpired
            exactMatch: $exactMatch
          ) {
            content {
              id
              description
              fields
              label
              accountType
              expirationDate
              expirationFlag
            }
            totalElements
            totalPages
            first
            last
            number
            size
          }
        }
      `,
      variables: {
        realm,
        labels,
        page,
        hasExpired,
        hasCloseToExpired,
        exactMatch
      }
    }),
  findPaginatedaccountsPipelines: ({ realm, accountId, page }) => clientApollo.query({
    query: gql`
        query accountsAssociatedPipelines($realm: String!, $accountId: String, $page: Int) {
          accountsAssociatedPipelines(realm: $realm, accountId: $accountId, page: $page) {
            content {
              pipeline {
                id
                name
                versionMajor
                versionMinor
                canvasVersion
              }
              project {
                name
              }
              environments {
                description
                name
                id
              }
            }
            totalElements
            totalPages
            first
            last
            number
            size
          }
        }
      `,
    variables: {
      realm,
      accountId,
      page
    }
  }),
  findPaginatedDeployments: ({ realm, accountId, page }) => clientApollo.query({
    query: gql`
        query accountsAssociatedDeployments($realm: String!, $accountId: String, $page: Int) {
          accountsAssociatedDeployments(realm: $realm, accountId: $accountId, page: $page) {
            content {
              pipeline
              project
              environment
              activeConfiguration
              id
              status
              startTime
              availableReplicas
              oomCount
              lastError
              errorCount
              lastOMM
              endpoint
              trigger
              metadata
              licenses
              replicaName
              deploymentStatus
            }
            totalElements
            totalPages
            first
            last
            number
            size
          }
        }
      `,
    variables: {
      realm,
      accountId,
      page
    }
  }),
  accountsNotification({ realm, accountId, environment, deployments }) {
    return clientApollo.mutate({
      mutation: gql`
          mutation accountsNotification($realm: String!, $accountId: String, $environment: String!,  $deployments:JSON) {
            accountsNotification(realm: $realm, accountId: $accountId, environment: $environment,deployments: $deployments)
          }
        `,
      variables: {
        realm,
        accountId,
        environment,
        deployments
      }
    });
  },
  findTypes: variables =>
    clientApollo.query({
      query: gql`
        query accountTypes($realm: String!) {
          accountTypes(realm: $realm) {
            name
            providerName
            providers
            fields
            sensitive
          }
        }
      `,
      variables
    }),

  async remove({ realm, id }) {
    return clientApollo.mutate({
      mutation: gql`
        mutation deleteAccount($realm: String!, $accountId: String!) {
          deleteAccount(realm: $realm, accountId: $accountId)
        }
      `,
      variables: {
        realm,
        accountId: id
      }
    });
  },

  async create(variables) {
    return clientApollo.mutate({
      mutation: gql`
        mutation createAccount(
          $realm: String!
          $accountId: String
          $account: AccountInput
        ) {
          createAccount(realm: $realm, accountId: $accountId, account: $account)
        }
      `,
      variables
    });
  },

  edit({ realm, account, id }) {
    return clientApollo.mutate({
      mutation: gql`
        mutation editGlobal(
          $realm: String!
          $accountObject: AccountInput
          $accountId: String
        ) {
          editGlobal(
            realm: $realm
            accountObject: $accountObject
            accountId: $accountId
          )
        }
      `,
      variables: {
        realm,
        accountObject: account,
        accountId: id
      }
    });
  },
};

export default accounts;
