// V2
import Immutable from 'seamless-immutable';

import { alertsConfigV2 } from '~/api';
import i18n from '~/common/helpers/i18n';

const initAlertState = {
  config: {
    pipelines: [],
    metric: {
      name: '',
      category: '',
      description: ''
    },
    metricThresholdOperator: '',
    intervalCheck: '',
    allPipelines: false,
    levels: {
      ERROR: {
        metricThresholdValue: ''
      }
    }
  },
  name: '',
  active: false,
  id: undefined,
  notificationConfig: {
    channels: {
      EMAIL: {
        emailTo: [],
        emailSubject: '',
        emailContent: ''
      },
      SLACK: {
        slackUrl: null
      },
      TELEGRAM: {
        chatId: null
      },
      WEBHOOK: {
        url: null
      }
    }
  }
};

const CHANNEL_CONFIGS = {
  EMAIL: {
    keys: ['emailTo', 'emailSubject', 'emailContent'],
    condition: values => values.emailTo?.length > 0
  },
  SLACK: {
    keys: ['slackUrl'],
    condition: values => values.slackUrl
  },
  TELEGRAM: {
    keys: ['chatId'],
    condition: values => values.chatId?.trim()
  },
  WEBHOOK: {
    keys: ['url'],
    condition: values => values.url
  }
};

const cleanNotificationChannels = channels =>
  Object.entries(channels || {}).reduce(
    (cleanedChannels, [channel, values]) => {
      if (!CHANNEL_CONFIGS[channel]) return cleanedChannels;

      const { keys, condition } = CHANNEL_CONFIGS[channel];
      if (!condition(values)) return cleanedChannels;

      const cleanedValues = keys.reduce((acc, key) => {
        if (values[key]) acc[key] = values[key];
        return acc;
      }, {});

      if (Object.keys(cleanedValues).length > 0) {
        cleanedChannels[channel] = cleanedValues;
      }

      return cleanedChannels;
    },
    {}
  );

const initialState = Immutable({
  result: {
    alertsConfig: []
  },
  entities: {
    alertsConfig: {}
  },
  loading: false,
  loadingMetrics: false,
  search: '',
  error: null,
  modal: {
    visible: false,
    loading: false,
    success: null,
    error: null,
    monitoringAlert: initAlertState
  },
  modalAlertsConfig: {
    visible: false,
    loading: false
  },
  environment: null,
  pagination: {
    totalElements: 0,
    totalPages: 0,
    number: 0,
    size: 10
  },
  metrics: {},
  formMetrics: {},
  sortConfig: {
    key: '',
    direction: 'ascending'
  },
  vpn: false
});

const alertsConfigMonitorModel = {
  name: 'alertsConfigMonitor',
  state: initialState,
  reducers: {
    removeAlertFromState: (state, { id }) => {
      const newIds = state.result.alertsConfig.filter(
        alertId => alertId !== id
      );

      const { [id]: removed, ...remainingAlerts } = state.entities.alertsConfig;

      return state.merge({
        result: {
          alertsConfig: newIds
        },
        entities: {
          alertsConfig: remainingAlerts
        }
      });
    },
    updateAlertStatus: (state, { id, active }) => {
      if (state.entities.alertsConfig[id]) {
        return state.merge({
          entities: {
            alertsConfig: {
              ...state.entities.alertsConfig,
              [id]: {
                ...state.entities.alertsConfig[id],
                active
              }
            }
          }
        });
      }
      return state;
    },
    setPipelineStatus: (state, status) =>
      state.merge({ pipelineStatus: status }),
    setPipelineValue: (state, value) => state.merge({ pipelineValue: value }),
    setSort: (state, { key, direction }) =>
      state.merge({
        sortConfig: { key, direction }
      }),
    setSearch: (state, search) =>
      state.merge({
        search
      }),
    setSelectedMetric: (state, selectedMetric) =>
      state.merge({ selectedMetric }),
    setSelectedChannel: (state, selectedChannel) =>
      state.merge({ selectedChannel }),
    setAlerts: (state, payload) => {
      const alerts = Array.isArray(payload.content) ? payload.content : [];
      const alertIds = alerts.map(alert => alert.id);
      const alertEntities = alerts.reduce((acc, alert) => {
        acc[alert.id] = alert;
        return acc;
      }, {});

      return state.merge({
        result: {
          alertsConfig: alertIds
        },
        entities: {
          alertsConfig: alertEntities
        },
        pagination: {
          totalElements: payload.totalElements,
          totalPages: payload.totalPages,
          number: payload.number,
          size: payload.size
        }
      });
    },
    setAlertInitialValue: (state, payload) =>
      state.merge({
        alertInitialValue: payload
      }),
    setModal: (state, payload) => {
      const newState = state.merge({
        modal: {
          visible: payload.visible,
          loading: payload.loading || false,
          success: payload.success || null,
          error: payload.error || null,
          monitoringAlert: payload.monitoringAlert || initAlertState
        }
      });
      return newState;
    },
    setModalLoading: (state, loading) =>
      state.merge({
        modal: {
          ...state.modal,
          loading
        }
      }),
    setActionModal: (state, payload) =>
      state.merge({
        modal: {
          ...state.modal,
          ...payload
        }
      }),
    setLoading: (state, payload) =>
      state.merge({
        loading: payload
      }),
    setLoadingMetrics: (state, payload) =>
      state.merge({
        loadingMetrics: payload
      }),
    setLoadingFormMetrics: (state, payload) =>
      state.merge({
        formLoadingMetrics: payload
      }),
    clearAlert: state => state.merge({ alertInitialValue: initAlertState }),
    setSuccess: (state, payload) => state.merge({ success: payload }),
    setMetrics: (state, payload) => {
      // Organiza as métricas por categoria se necessário
      const metricsGrouped = (payload || []).reduce((acc, metric) => {
        if (!acc[metric.category]) {
          acc[metric.category] = [];
        }
        acc[metric.category].push(metric);
        return acc;
      }, {});

      return state.merge({
        metrics: payload,
        metricsGrouped
      });
    },
    setFormMetrics: (state, payload) => {
      const formMetricsGrouped = (payload || []).reduce((acc, metric) => {
        if (!acc[metric.category]) {
          acc[metric.category] = [];
        }
        acc[metric.category].push(metric);
        return acc;
      }, {});

      return state.merge({
        formMetrics: payload,
        formMetricsGrouped
      });
    },
    setVpn: (state, payload) => state.merge({ vpn: payload }),
    reset: () => initialState
  },
  effects: dispatch => ({
    async createAlert(alert, rootState) {
      const alertName = alert.name;

      try {
        const { activeRealm: realm, environment } = rootState.application;

        const alertToSave = {
          config: {
            pipelines:
              alert.config?.pipelines?.map(pipeline => ({
                name: pipeline.name
              })) || [],
            metric: alert.config?.metric?.name || '',
            metricThresholdOperator:
              alert.config?.metricThresholdOperator || '',
            intervalCheck: alert.config?.intervalCheck || '',
            allPipelines: alert.config?.allPipelines || false,
            levels: {
              ERROR: {
                metricThresholdValue:
                  alert.config?.levels?.ERROR?.metricThresholdValue || ''
              }
            }
          },
          name: alert.name || '',
          active: alert.active || false,
          type:
            !alert.config?.allPipelines &&
            (
              alert.config?.pipelines?.map(pipeline => ({
                name: pipeline.name
              })) || []
            ).length === 0
              ? 'vpn'
              : 'pipeline',
          notificationConfig: {
            channels: cleanNotificationChannels(
              alert.notificationConfig?.channels
            )
          }
        };

        const request = await alertsConfigV2.createAlertsConfig({
          realm,
          environment,
          alert: alertToSave
        });

        dispatch.alertsConfigMonitor.setModalLoading(request, {
          snackbar: {
            text: i18n.t('label.alert_set_up_msg_success', {
              alertName
            }),
            action: {
              label: i18n.t('action.close')
            }
          }
        });

        dispatch.alertsConfigMonitor.getAlerts({
          page: rootState.alertsConfigMonitor.pagination.number,
          search: rootState.alertsConfigMonitor.search,
          metric: rootState.alertsConfigMonitor.selectedMetric,
          channel: rootState.alertsConfigMonitor.selectedChannel,
          sort: rootState.alertsConfigMonitor.sortConfig?.key,
          order: rootState.alertsConfigMonitor.sortConfig?.direction
        });

        dispatch.alertsConfigMonitor.setSuccess(true);
      } catch (e) {
        dispatch.alertsConfigMonitor.setModalLoading(e, {
          snackbar: {
            text: i18n.t('label.alert_not_set_up_msg_alert', { alertName }),
            visible: true
          }
        });
      } finally {
        setTimeout(() => {
          dispatch.alertsConfigMonitor.setSuccess(false);
        }, 1000);
      }
    },
    async updateAlert({ id, ...alert }, rootState) {
      const alertName = alert.name;
      try {
        const { activeRealm: realm, environment } = rootState.application;

        const alertToSave = {
          config: {
            pipelines:
              alert.config?.pipelines?.map(pipeline => ({
                name: pipeline.name
              })) || [],
            metric: alert.config?.metric?.name || '',
            metricThresholdOperator:
              alert.config?.metricThresholdOperator || '',
            intervalCheck: alert.config?.intervalCheck || '',
            allPipelines: alert.config?.allPipelines || false,
            levels: {
              ERROR: {
                metricThresholdValue:
                  alert.config?.levels?.ERROR?.metricThresholdValue || ''
              }
            }
          },
          name: alert.name || '',
          active: alert.active || false,
          notificationConfig: {
            channels: cleanNotificationChannels(
              alert.notificationConfig?.channels
            )
          }
        };

        const request = await alertsConfigV2.updateAlertsConfig({
          realm,
          environment,
          alert: alertToSave,
          id
        });

        dispatch.alertsConfigMonitor.setModalLoading(request, {
          snackbar: {
            text: i18n.t('label.alert_edited_msg_success', { alertName }),
            action: {
              label: i18n.t('action.close')
            }
          }
        });

        dispatch.alertsConfigMonitor.getAlerts({
          page: rootState.alertsConfigMonitor.pagination.number,
          search: rootState.alertsConfigMonitor.search,
          metric: rootState.alertsConfigMonitor.selectedMetric,
          channel: rootState.alertsConfigMonitor.selectedChannel,
          sort: rootState.alertsConfigMonitor.sortConfig?.key,
          order: rootState.alertsConfigMonitor.sortConfig?.direction
        });

        dispatch.alertsConfigMonitor.setSuccess(true);
      } catch (e) {
        dispatch.alertsConfigMonitor.setModalLoading(e, {
          snackbar: {
            text: i18n.t('label.alert_not_set_up_msg_alert', { alertName }),
            visible: true
          }
        });
      } finally {
        setTimeout(() => {
          dispatch.alertsConfigMonitor.setSuccess(false);
        }, 1000);
      }
    },
    async getAlerts(
      {
        page,
        size = 10,
        alertName,
        search,
        metric,
        channel,
        sort,
        order = 'asc',
        vpn = false
      },
      rootState
    ) {
      try {
        const { activeRealm: realm, environment } = rootState.application;
        const searchTerm = search || rootState.alertsConfigMonitor.search;
        dispatch.alertsConfigMonitor.setLoading(true);
        const data = await alertsConfigV2.getAlertsConfig({
          realm,
          environment,
          page,
          size,
          search: searchTerm,
          metric,
          channel,
          sort,
          order,
          vpn: vpn || rootState.alertsConfigMonitor.vpn
        });

        dispatch.alertsConfigMonitor.setAlerts(data);

        dispatch.alertsConfigMonitor.setSearch(search);
      } catch (e) {
        dispatch.alertsConfigMonitor.setModalLoading(e, {
          snackbar: {
            text: i18n.t('label.alert_not_set_up_msg_alert', { alertName }),
            visible: true
          }
        });
      } finally {
        setTimeout(() => {
          dispatch.alertsConfigMonitor.setLoading(false);
        }, 1000);
      }
    },
    async getAlertsById({ id, alertName }, rootState) {
      try {
        const { activeRealm: realm, environment } = rootState.application;

        const data = await alertsConfigV2.getAlertsConfigById({
          realm,
          environment,
          id
        });

        dispatch.alertsConfigMonitor.setAlertInitialValue(data);
      } catch (e) {
        dispatch.alertsConfigMonitor.setModalLoading(e, {
          snackbar: {
            text: i18n.t('label.alert_not_set_up_msg_alert', { alertName }),
            visible: true
          }
        });
      }
    },
    async removeAlert({ id, name }, rootState) {
      try {
        const currentState = rootState.alertsConfigMonitor;

        const { activeRealm: realm, environment } = rootState.application;

        dispatch.alertsConfigMonitor.removeAlertFromState({ id });

        const data = await alertsConfigV2.removeAlertsConfig({
          realm,
          environment,
          id
        });

        dispatch.alertsConfigMonitor.setModalLoading(data, {
          snackbar: {
            text: i18n.t('label.alert_deleted_msg_success', {
              alertName: name
            }),
            action: {
              label: i18n.t('action.close')
            }
          }
        });

        dispatch.alertsConfigMonitor.setSuccess(data);
      } catch (e) {
        dispatch.alertsConfigMonitor.getAlerts({
          page: rootState.alertsConfigMonitor.pagination.number,
          search: rootState.alertsConfigMonitor.search,
          metric: rootState.alertsConfigMonitor.selectedMetric,
          channel: rootState.alertsConfigMonitor.selectedChannel,
          sort: rootState.alertsConfigMonitor.sortConfig?.key,
          order: rootState.alertsConfigMonitor.sortConfig?.direction,
          vpn: rootState.alertsConfigMonitor.vpn
        });

        dispatch.alertsConfigMonitor.setModalLoading(e, {
          snackbar: {
            text: i18n.t('label.alert_not_set_up_msg_alert', {
              alertName: name
            }),
            visible: true
          }
        });
        dispatch.alertsConfigMonitor.setSuccess(false);
      } finally {
        setTimeout(() => {
          dispatch.alertsConfigMonitor.setSuccess(false);
        }, 1000);
      }
    },
    async activeAlert({ id, alertStatus, alertName }, rootState) {
      try {
        const { activeRealm: realm, environment } = rootState.application;
        const data = await alertsConfigV2.activeAlertsConfig({
          realm,
          environment,
          id,
          alertStatus
        });

        dispatch.alertsConfigMonitor.setModalLoading(data, {
          snackbar: {
            text: `${
              alertStatus
                ? i18n.t('label.alert_activated_msg_success', { alertName })
                : i18n.t('label.alert_deactivated_msg_success', { alertName })
            } `,
            visible: true
          }
        });
        dispatch.alertsConfigMonitor.setSuccess(true);

        dispatch.alertsConfigMonitor.updateAlertStatus({
          id,
          active: alertStatus
        });
      } catch (e) {
        dispatch.alertsConfigMonitor.setModalLoading(e, {
          snackbar: {
            text: i18n.t('label.alert_not_set_up_msg_alert', { alertName }),
            visible: true
          }
        });
        dispatch.alertsConfigMonitor.setSuccess(false);
      } finally {
        setTimeout(() => {
          dispatch.alertsConfigMonitor.setSuccess(false);
        }, 1000);
      }
    },
    async getMetrics({ vpn }, rootState) {
      try {
        const { activeRealm: realm } = rootState.application;

        dispatch.alertsConfigMonitor.setLoadingMetrics(true);
        const data = await alertsConfigV2.getMetrics({
          realm,
          vpn
        });
        dispatch.alertsConfigMonitor.setMetrics(data);
      } catch (e) {
        dispatch.alertsConfigMonitor.setModalLoading(e, {
          snackbar: {
            text: i18n.t('common.messages.error.fetch'),
            visible: true
          }
        });
      }
      dispatch.alertsConfigMonitor.setLoadingMetrics(false);
    },
    async getFormMetrics({ vpn }, rootState) {
      try {
        const { activeRealm: realm } = rootState.application;

        dispatch.alertsConfigMonitor.setLoadingFormMetrics(true);
        const data = await alertsConfigV2.getMetrics({
          realm,
          vpn
        });
        dispatch.alertsConfigMonitor.setFormMetrics(data);
      } catch (e) {
        dispatch.alertsConfigMonitor.setModalLoading(e, {
          snackbar: {
            text: i18n.t('common.messages.error.fetch'),
            visible: true
          }
        });
      }
      dispatch.alertsConfigMonitor.setLoadingFormMetrics(false);
    }
  })
};

export default alertsConfigMonitorModel;
