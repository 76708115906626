/* eslint-disable */

import Immutable from 'seamless-immutable';
import { v4 as uuid } from 'uuid';
import * as api from '~/api';
import Shortcuts from '~/common/helpers/shortcuts';
import { commands } from '~/common/jsons';
import getBase64Url from '~/common/helpers/getBase64Url';
import createIconCapsule from '~/common/helpers/createIconCapsule';

const INITIAL_CANVAS_ID = uuid();

const EMPTY_CANVAS = {
  nodes: [],
  edges: []
};

const initialState = Immutable({
  connectorConfiguration: {
    opened: false
  },
  connectorsListing: {
    opened: true
  },
  data: {
    components: [],
    libraries: [],
    replicas: [],
    sensitiveFields: {
      logSensitiveFields: []
    },
    triggers: [],
    librariesConsumers: [],
    capsulesConsumers: []
  },
  normalizedPipeline: EMPTY_CANVAS,
  save: {
    id: INITIAL_CANVAS_ID,
    opened: false,
    pending: false
  },
  saving: false,
  settings: {
    opened: false
  },
  shortcuts: {
    opened: false,
    commands: commands
  },
  error: null,
  loading: false,
  trackingId: uuid(),
  triggerConfiguration: {
    opened: false
  },
  forceModalOpened: false,
  isOpenTestMode: false
});

function restrictShortcuts(ev, element, combo) {
  // Se o atalho for um toggle do modal de atalhos.
  if (['ctrl+;', 'command+;'].includes(combo)) return false;

  return true;
}

const DesignModel = {
  name: 'design',
  state: initialState,
  reducers: {
    closeAll() {
      return initialState;
    },

    closeConnectorConfiguration(state) {
      return state.merge(
        {
          connectorConfiguration: {
            opened: false
          }
        },
        { merge: true }
      );
    },
    setOpenTestMode(state, payload = true) {
      return state.merge(
        {
          isOpenTestMode: payload
        },
        { merge: true }
      );
    },

    closeConnectorsListing(state) {
      return state.merge(
        {
          connectorsListing: {
            opened: false
          }
        },
        { merge: true }
      );
    },

    closePreventClose(state) {
      return state.merge(
        {
          preventClose: {
            opened: false
          }
        },
        { merge: true }
      );
    },

    closeSave(state) {
      return state.merge(
        {
          save: {
            ...state.save,
            opened: false
          }
        },
        { merge: true }
      );
    },

    closeSettings(state) {
      return state.merge({
        settings: {
          opened: false
        }
      });
    },

    closeShortcuts(state) {
      return state.merge(
        {
          shortcuts: {
            ...state.shortcuts,
            opened: false
          }
        },
        { merge: true }
      );
    },
    closeTriggerConfiguration(state) {
      return state.merge(
        {
          triggerConfiguration: {
            opened: false
          }
        },
        { merge: true }
      );
    },
    openConnectorConfiguration(
      state,
      { component, formValue, isUpdate, schema }
    ) {
      return state.merge(
        {
          connectorConfiguration: {
            component,
            formValue,
            isUpdate,
            schema,
            opened: true
          }
        },
        { merge: true }
      );
    },

    openConnectorsListing(state) {
      return state.merge(
        {
          connectorsListing: {
            opened: true
          }
        },
        { merge: true }
      );
    },

    openSave(state) {
      return state.merge(
        {
          save: {
            ...state.save,
            opened: true
          }
        },
        { merge: true }
      );
    },

    openSettings(state) {
      return state.merge({
        settings: {
          opened: true
        }
      });
    },

    openShortcuts(state) {
      return state.merge(
        {
          shortcuts: {
            ...state.shortcuts,
            opened: true
          }
        },
        { merge: true }
      );
    },

    openPreventClose(state) {
      return state.merge(
        {
          preventClose: {
            opened: true
          }
        },
        { merge: true }
      );
    },

    openTriggerConfiguration(state) {
      return state.merge(
        {
          triggerConfiguration: {
            opened: true
          }
        },
        { merge: true }
      );
    },

    setData(state, data) {
      return state.merge(
        {
          data
        },
        { deep: true }
      );
    },
    setLoading(state, payload) {
      return state.merge({
        loading: payload
      });
    },
    setError(state, payload) {
      return state.merge({
        error: payload
      });
    },
    setNormalizedPipeline(state, normalizedPipeline) {
      return state.merge({ normalizedPipeline });
    },

    setPendingSave(state, pending = true) {
      return state.merge(
        {
          save: {
            ...state.save,
            pending
          }
        },
        { merge: true }
      );
    },

    setSaving(state, saving) {
      return state.merge({ saving });
    },

    setForceModalOpened(state, value) {
      return state.set('forceModalOpened', value);
    },

    setTriggerConfiguration(state, { element }) {
      return state.merge(
        {
          triggerConfiguration: {
            ...state.triggerConfiguration,
            element
          }
        },
        { deep: true }
      );
    },

    updateCanvas(state, canvas) {
      return state.merge({ canvas }, { deep: true });
    },
    setReplicas(state, replicas) {
      return state.setIn(['data', 'replicas'], replicas);
    }
  },
  effects: dispatch => ({
    async fetchConnectors(value, { application, acls, authentication }) {
      try {
        const { realm } = application.realm;
        const betaShapes = acls.scopes.some(scope =>
          ['BETA:SHAPES'].includes(scope)
        );
        const response = await api.connectors.get({ realm, betaShapes });

        const components = await Promise.all(
          response?.data?.components?.map(async (c, i) => {
            const iconURL = c?.iconURL;
            return Object.assign({}, c, {
              iconURL: iconURL ? await getBase64Url(iconURL) : null
            });
          })
        ).catch(() => {
          return response?.data?.components;
        });

        const triggers = await Promise.all(
          response?.data?.triggers?.map(async (c, i) => {
            const iconURL = c?.iconURL;
            return Object.assign({}, c, {
              iconURL: iconURL ? await getBase64Url(iconURL) : null
            });
          })
        ).catch(() => {
          return response?.data?.triggers;
        });
        const capsulesConsumers = await Promise.all(
          response?.data?.capsulesConsumers?.map(async (c, i) => {
            const header = await getBase64Url(
              c?.capsuleCollectionHeader?.iconURL,
              authentication?.userData?.token
            );
            return {
              ...c,
              capsuleServices: c.capsuleServices.map(capsule => {
                const iconURL = createIconCapsule({
                  header,
                  accent: c?.colorAccent,
                  background: c?.colorBackground,
                  icon: capsule?.iconName
                });
                return {
                  ...capsule,
                  iconURL
                };
              })
            };
          })
        ).catch(() => {
          return response?.data?.capsulesConsumers;
        });
        const librariesConsumers = await Promise.all(
          response?.data?.librariesConsumers?.map(async (c, i) => {
            const iconURL = await getBase64Url(c?.iconURL);
            return Object.assign({}, c, {
              iconURL: iconURL ? iconURL : null,
              libraryServices: c?.libraryServices?.map((l, i) => {
                return Object.assign({}, l, {
                  iconURL: iconURL ? iconURL : null
                });
              })
            });
          })
        ).catch(() => {
          return response?.data?.librariesConsumers;
        });

        response.data.components = components || [];
        response.data.triggers = triggers || [];
        response.data.librariesConsumers = librariesConsumers || [];
        response.data.capsulesConsumers = capsulesConsumers || [];
        dispatch.design.setData(response.data);
      } catch (e) {
        dispatch.design.setError(e);
      }
    },

    async fetchReplicas(_, { application }) {
      const { realm } = application.realm;
      const response = await api.connectors.getReplicas({ realm });
      const replicas = response?.data?.replicas;
      dispatch.design.setReplicas(replicas);
    },

    toggleConnectorsListing(payload, state) {
      if (state.design.connectorsListing.opened) {
        dispatch.design.closeConnectorsListing();
        return;
      }
      dispatch.design.openConnectorsListing();
    },

    toggleShortcuts(payload, state) {
      if (state.design.shortcuts.opened) {
        dispatch.design.closeShortcuts();
        return;
      }
      dispatch.design.openShortcuts();
    },

    updateTrigger(value, rootState) {
      const { normalizedPipeline } = rootState.canvas;
      const setNode = (node = Immutable({}), data) => node.merge(data);
      const currentTriggerPath = ['nodes', 0];
      const updated = normalizedPipeline.updateIn(
        currentTriggerPath,
        setNode,
        value
      );

      dispatch.design.setNormalizedPipeline(updated);
    }
  }),
  logics: [
    {
      type: 'cy/updateCurrentCanvas',
      latest: true,
      process(context, dispatch, done) {
        // Prevenção de recarregamento e atualização de rota.
        window.onbeforeunload = () => '';

        // Aplica prevenção para ação de swipe para voltar ou recarregar pagina com mousetrack.
        document.body.style.overscrollBehavior = 'none';

        done();
      }
    },
    {
      type: ['cy/updateElement'],
      latest: true,
      process(context, dispatch, done) {
        // Prevenção de recarregamento e atualização de rota.
        window.onbeforeunload = () => '';

        // Aplica prevenção para ação de swipe para voltar ou recarregar pagina com mousetrack.
        document.body.style.overscrollBehavior = 'none';

        // dispatch.design.setCurrentCanvasId(uuid());
        dispatch.design.closeConnectorConfiguration();

        done();
      }
    },
    {
      type: 'design/openSave',
      latest: true,
      process(context, dispatch, done) {
        dispatch.design.setSaving(false);
        done();
      }
    },
    {
      type: 'design/openTriggerConfiguration',
      latest: true,
      process({ action }, dispatch, done) {
        const { element, component, schema } = action.payload;
        dispatch.cy.setTriggerConfiguration({ element, component, schema });
        done();
      }
    },
    {
      type: 'design/openConnectorConfiguration',
      latest: true,
      process({ action }, dispatch, done) {
        const { element, type, component } = action.payload;

        dispatch.cy.setConnectorConfiguration({
          component,
          element,
          type
        });

        done();
      }
    },
    {
      type: [
        'design/openConnectorConfiguration',
        'design/openSave',
        'design/openPreventClose',
        'design/openSettings',
        'design/openShortcuts',
        'design/openTriggerConfiguration'
      ],
      latest: true,
      process(context, dispatch, done) {
        Shortcuts.getScope('design').rules.add(restrictShortcuts);

        done();
      }
    },
    {
      type: [
        'design/closeConnectorConfiguration',
        'design/closePreventClose',
        'design/closeSave',
        'design/closeSettings',
        'design/closeShortcuts',
        'design/closeTriggerConfiguration'
      ],
      latest: true,
      process(context, dispatch, done) {
        Shortcuts.getScope('design').rules.remove(restrictShortcuts);

        done();
      }
    },
    {
      type: ['design/openSettings'],
      latest: true,
      process({ getState }, dispatch, done) {
        dispatch.design.setOpenTestMode(false);
        done();
      }
    },
    {
      type: ['design/fetchConnectors'],
      latest: true,
      process({ getState }, dispatch, done) {
        dispatch.design.setLoading(true);
        done();
      }
    },
    {
      type: ['design/setData', 'design/setError'],
      latest: true,
      process({ getState }, dispatch, done) {
        dispatch.design.setLoading(false);
        done();
      }
    }
  ]
};

export default DesignModel;
