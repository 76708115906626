import { Alert, Box } from '@digibee/beehive-ui';
import map from 'lodash/map';
import React, { FC } from 'react';
import styled from 'styled-components';

// Locals
import ValidationPropTypes from '../../common/ValidationPropTypes';

const List = styled.div``;

const Item = styled.div`
  margin-bottom: 5px;
`;

interface AlertObjectsProps {
  objects?: string[];
}

const AlertObjects: FC<AlertObjectsProps> = ({ objects = [] }) => (
  <List>{objects?.map(object => <Item key={object}>{object}</Item>)}</List>
);

type ValidationPropTypes = {
  code: string;
  message: string;
  details: [
    {
      level: string;
      details: string[];
      code: string;
      message: string;
      objects: string[];
    }
  ];
};

interface DeploymentValidationV2Props {
  validation?: ValidationPropTypes[];
}

const DeploymentValidationV2: FC<DeploymentValidationV2Props> = ({
  validation
}) => (
  <>
    {map(validation, item =>
      map(item?.details, detail => (
        <Box key={detail?.message} css={{ marginBottom: '$2' }}>
          <Alert
            key={detail?.message}
            // @ts-ignore
            variant={
              detail?.level?.toLowerCase() === 'error'
                ? 'danger'
                : detail?.level?.toLowerCase()
            }
            collapsible
            // @ts-ignore
            content={<AlertObjects objects={detail?.objects} />}
            title={`${detail?.message}`}
          />
        </Box>
      ))
    )}
  </>
);

export default DeploymentValidationV2;
