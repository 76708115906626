// ALERT CONFIG MONITOR V2
import { isApolloError, gql } from '@apollo/client';

import clientApollo from './clientApollo';

import { Pagination } from '~/common/types/Pagination';
import { MonitoringAlert } from '~/entities/MonitoringAlertV2';

export type Metrics = {
  name: string;
  category: string;
  description: string;
};

type AlertParamsInput = {
  paramName: string;
  paramVersion: number;
  paramType: string;
};

type AlertConfigInput = {
  pipelines: PipelineInput[];
  alertParams: AlertParamsInput[];
  metric: string;
  levels: LevelsInput;
  metricThresholdOperator: string;
  intervalCheck: string;
  allPipelines: boolean;
};

type PipelineInput = {
  name: string;
};

type LevelsInput = {
  ERROR: {
    metricThresholdValue: string;
  };
};

type NotificationConfigInput = {
  channels: ChannelsInput[];
};

type ChannelsInput = {
  TELEGRAM: TelegramParamsInput;
  WEBHOOK: WebhookParamsInput;
  EMAIL: EmailParamsInput;
  SLACK: SlackParamsInput;
};

type TelegramParamsInput = {
  chatId: string;
  token: string;
};

type WebhookParamsInput = {
  url: string;
};

type EmailParamsInput = {
  emailTo: string[];
  emailSubject: string;
  emailContent: string;
};

type SlackParamsInput = {
  url: string;
};

export type MonitoringAlertsConfigInput = {
  config: AlertConfigInput;
  notificationConfig: NotificationConfigInput;
  name: string;
  active: boolean;
};

type AlertsConfigSaveData = {
  realm: string;
  environment: string;
  alert: MonitoringAlertsConfigInput;
  id?: string;
};

type SaveAlertsConfigResult = {
  createAlertsConfig: boolean;
};

type UpdatedAlertsConfigResult = {
  updateAlertsConfig: boolean;
};

type AlertsConfigFetchData = {
  realm: string;
  environment: string;
  page?: number;
  size?: number;
  id?: string;
  alertName?: string;
  search?: string;
  metric?: string;
  channel?: string;
  sort?: string;
  order?: string;
  vpn?: boolean;
};

type AlertsConfigFetchAllResult = {
  getAlertsConfigV2: Pagination<MonitoringAlert>;
};

type AlertsConfigFetchByIdResult = {
  getAlertsConfigById: MonitoringAlert;
};

type AlertsConfigFetchByNameResult = {
  getAlertsConfigByName: MonitoringAlert;
};

type RemoveAlertsConfigData = {
  realm: string;
  environment: string;
  id: string;
};

type RemoveAlertsConfigResult = {
  deleteAlertsConfig: boolean;
};

type ActiveAlertsConfigData = {
  realm: string;
  environment: string;
  id: string;
  alertStatus: boolean;
};

type ActiveAlertsConfigResult = {
  activeAlertConfig: MonitoringAlert;
};

type MetricsResult = {
  getMetricsV2: Metrics[];
};

const alertsConfigV2 = {
  getAlertsConfig: async ({
    realm,
    environment,
    page,
    size = 10,
    search,
    metric,
    channel,
    sort,
    order = 'desc',
    vpn = false
  }: AlertsConfigFetchData) => {
    try {
      const result = await clientApollo.query<AlertsConfigFetchAllResult>({
        query: gql`
          query getAlertsConfigV2(
            $realm: String!
            $environment: String!
            $page: Int
            $size: Int
            $search: String
            $metric: String
            $channel: String
            $sort: String
            $order: String
            $vpn: Boolean
          ) {
            getAlertsConfigV2(
              realm: $realm
              environment: $environment
              page: $page
              size: $size
              search: $search
              metric: $metric
              channel: $channel
              sort: $sort
              order: $order
              vpn: $vpn
            )
          }
        `,
        variables: {
          realm,
          environment,
          page,
          size,
          search,
          metric,
          channel,
          sort,
          order,
          vpn
        }
      });

      return result.data?.getAlertsConfigV2;
    } catch (e) {
      if (e instanceof Error && isApolloError(e)) throw Error(e.message);
      throw e;
    }
  },
  getAlertsConfigById: async ({
    realm,
    environment,
    id
  }: AlertsConfigFetchData) => {
    try {
      const result = await clientApollo.query<AlertsConfigFetchByIdResult>({
        query: gql`
          query getAlertsConfigById(
            $realm: String!
            $environment: String!
            $id: String!
          ) {
            getAlertsConfigById(
              realm: $realm
              environment: $environment
              id: $id
            )
          }
        `,
        variables: {
          realm,
          environment,
          id
        }
      });

      return result.data?.getAlertsConfigById;
    } catch (e) {
      if (e instanceof Error && isApolloError(e)) throw Error(e.message);
      throw e;
    }
  },
  getAlertsConfigByName: async ({
    realm,
    environment,
    alertName
  }: AlertsConfigFetchData) => {
    try {
      const result = await clientApollo.query<AlertsConfigFetchByNameResult>({
        query: gql`
          query getAlertsConfigByName(
            $realm: String!
            $environment: String!
            $alertName: String!
          ) {
            getAlertsConfigByName(
              realm: $realm
              environment: $environment
              alertName: $alertName
            )
          }
        `,
        variables: {
          realm,
          environment,
          alertName
        }
      });

      return result.data?.getAlertsConfigByName;
    } catch (e) {
      if (e instanceof Error && isApolloError(e)) throw Error(e.message);
      throw e;
    }
  },
  createAlertsConfig: async ({
    realm,
    environment,
    alert
  }: AlertsConfigSaveData) => {
    try {
      const result = await clientApollo.mutate<SaveAlertsConfigResult>({
        mutation: gql`
          mutation createAlertsConfigV2(
            $realm: String!
            $environment: String!
            $alert: JSON
          ) {
            createAlertsConfigV2(
              realm: $realm
              environment: $environment
              alert: $alert
            )
          }
        `,
        variables: {
          realm,
          environment,
          alert
        }
      });
      return result.data?.createAlertsConfig;
    } catch (e) {
      if (e instanceof Error && isApolloError(e)) throw Error(e.message);
      throw e;
    }
  },
  updateAlertsConfig: async ({
    realm,
    environment,
    alert,
    id
  }: AlertsConfigSaveData) => {
    try {
      const result = await clientApollo.mutate<UpdatedAlertsConfigResult>({
        mutation: gql`
          mutation updateAlertsConfigV2(
            $realm: String!
            $environment: String!
            $id: String!
            $alert: JSON
          ) {
            updateAlertsConfigV2(
              realm: $realm
              environment: $environment
              id: $id
              alert: $alert
            )
          }
        `,
        variables: {
          realm,
          environment,
          alert,
          id
        }
      });

      return result.data?.updateAlertsConfig;
    } catch (e) {
      if (e instanceof Error && isApolloError(e)) throw Error(e.message);
      throw e;
    }
  },
  removeAlertsConfig: async ({
    realm,
    environment,
    id
  }: RemoveAlertsConfigData) => {
    try {
      const result = await clientApollo.mutate<RemoveAlertsConfigResult>({
        mutation: gql`
          mutation deleteAlertsConfig(
            $realm: String!
            $environment: String!
            $id: String!
          ) {
            deleteAlertsConfig(
              realm: $realm
              environment: $environment
              id: $id
            )
          }
        `,
        variables: {
          realm,
          environment,
          id
        }
      });

      return result.data?.deleteAlertsConfig;
    } catch (e) {
      if (e instanceof Error && isApolloError(e)) throw Error(e.message);
      throw e;
    }
  },
  activeAlertsConfig: async ({
    realm,
    environment,
    id,
    alertStatus
  }: ActiveAlertsConfigData) => {
    try {
      const result = await clientApollo.mutate<ActiveAlertsConfigResult>({
        mutation: gql`
          mutation activeAlertsConfig(
            $realm: String!
            $environment: String!
            $id: String!
            $alertStatus: Boolean!
          ) {
            activeAlertsConfig(
              realm: $realm
              environment: $environment
              id: $id
              alertStatus: $alertStatus
            )
          }
        `,
        variables: {
          realm,
          environment,
          id,
          alertStatus
        }
      });

      return result?.data?.activeAlertConfig;
    } catch (e) {
      if (e instanceof Error && isApolloError(e)) throw Error(e.message);
      throw e;
    }
  },
  getMetrics: async ({ realm, vpn = false }: AlertsConfigFetchData) => {
    try {
      const result = await clientApollo.query<MetricsResult>({
        query: gql`
          query getMetricsV2($realm: String!, $vpn: Boolean) {
            getMetricsV2(realm: $realm, vpn: $vpn)
          }
        `,
        variables: {
          realm,
          vpn
        }
      });

      return result.data.getMetricsV2;
    } catch (e) {
      if (e instanceof Error && isApolloError(e)) throw Error(e.message);
      throw e;
    }
  }
};

export default alertsConfigV2;
