import { head } from 'lodash';
import { normalize, schema } from 'normalizr';
import Immutable from 'seamless-immutable';

// Locals
import * as api from '~/api';
import i18n from '~/common/helpers/i18n';
import normalizeError from '~/common/helpers/normalizeError';

const normalizeData = ({ entity, data }) => {
  const Schema = new schema.Entity(entity);
  const mySchema = { [entity]: [Schema] };
  return normalize(data, mySchema);
};

const removeById = (allIds, byId, id) => {
  const newProject = allIds.filter(x => x !== id);
  const newById = newProject.reduce(
    (acc, current) => ({
      ...acc,
      [current]: {
        ...byId[current]
      }
    }),
    {}
  );
  return {
    result: {
      project: newProject
    },
    entities: {
      project: {
        ...newById
      }
    }
  };
};

const initialState = Immutable({
  loading: {
    save: false
  },
  result: {
    project: []
  },
  entities: {
    project: {}
  },
  error: {
    fetch: null
  },
  modal: {
    save: {
      idOpen: false,
      id: null,
      entity: null
    }
  },
  success: {
    create: null
  },
  nextCurrent: null,
  current: null,
  itemWithIndex: null
});

const projectModal = {
  name: 'project',
  state: initialState,
  reducers: {
    reset() {
      return initialState;
    },
    setLoading(state, { path, value }) {
      return state.merge({ loading: { [path]: value } }, { deep: true });
    },
    setModal(state, { path, value }) {
      return state.setIn(['modal'], { [path]: value });
    },
    setFetch(state, data) {
      return state.merge(data, { deep: true });
    },
    setItemWithIndex(state, payload) {
      return state.merge(
        {
          itemWithIndex: payload
        },
        { deep: true }
      );
    },
    setUpdate(state, { entities }) {
      return state.merge(
        {
          entities: {
            ...entities
          }
        },
        { deep: true }
      );
    },
    setError(state, { path, value }) {
      return state.setIn(['error'], { [path]: value });
    },
    setCurrent(state, payload) {
      return state.setIn(['current'], payload);
    },
    setNextCurrent(state, payload) {
      return state.setIn(['nextCurrent'], payload);
    },
    setCountPipelineProject(state, { path, value }) {
      return state.merge({
        entities: {
          project: {
            ...state.entities.project,
            [path]: {
              ...state.entities.project[path],
              amountOfPipelines:
                (state?.entities.project[path]?.amountOfPipelines || 0) + value
            }
          }
        }
      });
    },
    setRemove(state, payload) {
      return state.merge({ ...payload }, { deep: true });
    },
    setSuccess(state, payload) {
      return state.merge(
        {
          entities: {
            project: {
              ...payload.entities.project
            }
          },
          result: {
            project: [...state.result.project, payload.result]
          }
        },
        { deep: true }
      );
    },
    clear: state => state.merge({ ...initialState, current: state.current })
  },
  effects: dispatch => ({
    async fetch(context, { application, authentication }, meta) {
      const { realm } = application.realm;
      const token = authentication?.userData?.token;

      try {
        const data = await api.project.fetch({
          realm,
          token
        });

        const source = normalizeData({
          entity: 'project',
          data
        });
        dispatch.project.setFetch(source, { ...context, ...meta });
      } catch (e) {
        dispatch.project.setError({
          path: 'fetch',
          value: e.message
        });
      }
    },
    async save(params, { application, authentication }) {
      try {
        const { realm } = application.realm;
        const { userData } = authentication;
        const { data } = await api.project.save({
          realm,
          project: { ...params, owner: userData.email }
        });
        const project = new schema.Entity('project');
        const source = normalize(data.createProject, project);
        dispatch.project.setSuccess(source);
        dispatch.snackbar.create({
          text: i18n.t('label.create_project_msg_success', {
            projectName: params.name
          }),
          action: { label: i18n.t('common.labels.ok') }
        });
      } catch (e) {
        dispatch.project.setError({ path: 'save', value: e });
        dispatch.snackbar.create({
          text: normalizeError.onGraphQL(e.message)
        });
      }
    },
    async remove(params, { application, project }) {
      try {
        const { realm } = application.realm;
        const currentProject = project.entities.project[params.projectId];
        const undo = async () => {
          await api.project.undo({
            realm,
            projectId: params.projectId
          });
          const projectModel = new schema.Entity('project');
          const source = normalize(currentProject, projectModel);
          dispatch.project.setSuccess(source);
          dispatch.snackbar.create({
            text: i18n.t('noun.undone_actions'),
            action: { label: i18n.t('common.labels.ok') }
          });
        };
        await api.project.remove({
          realm,
          projectId: params.projectId
        });

        const source = removeById(
          project.result.project,
          project.entities.project,
          params.projectId
        );

        dispatch.project.setRemove(source);
        dispatch.snackbar.create({
          text: i18n.t('label.archive_project_msg', {
            projectName: currentProject.name
          }),
          action: {
            label: i18n.t('noun.undo'),
            callback: undo
          }
        });
      } catch (e) {
        dispatch.project.setError({ path: 'remove', value: e });
        dispatch.snackbar.create({
          text: normalizeError.onGraphQL(e.message)
        });
      }
    },
    async onMove(params, { application, pipelines, project }, meta) {
      const undo = async () => {
        const resetAction = () => {
          try {
            const clonePipelines = pipelines.result.pipelines.asMutable();
            project.itemWithIndex.forEach(item =>
              clonePipelines.splice(item.index, 0, item.id)
            );
            dispatch.pipelines.removeResult(clonePipelines);
          } catch (e) {
            throw new Error(e);
          }
        };

        const objectInvertedProject = {
          ...params,
          toId: params?.current,
          current: params?.toId
        };
        await dispatch.project.onMove(objectInvertedProject, {
          isUndo: true
        });
        resetAction();
      };
      try {
        const { realm } = application.realm;
        const { pipelines: byIds } = pipelines.entities;
        const { project: projectByIds } = project.entities;
        const newPipelines = params?.ids?.map(id => ({
          pipelineName: byIds[id]?.name,
          pipelineVersionMajor: byIds[id]?.versionMajor
        }));
        await api.project.move({
          realm,
          pipelines: newPipelines,
          projectId: params?.toId
        });
        const lastProject = projectByIds[params?.toId];
        const beforeProject = projectByIds[params?.current];
        if (!meta?.isUndo) {
          dispatch.snackbar.create({
            text: `${i18n.t('noun.amount_moved_pipelines')}: ${
              newPipelines.length
            }. ${i18n.t('noun.from_project')}  ${beforeProject?.name} ${i18n.t(
              i18n.t('noun.to_project')
            )} ${lastProject?.name}.`,
            action: {
              label: i18n.t('noun.undo'),
              callback: undo
            }
          });
        } else {
          dispatch.snackbar.create({
            text: i18n.t('noun.undone_actions')
          });
        }
        dispatch.project.setCountPipelineProject({
          path: params.toId,
          value: newPipelines.length
        });
        dispatch.project.setCountPipelineProject({
          path: project?.current,
          value: -newPipelines.length
        });
      } catch (e) {
        if (!meta?.isUndo) undo();
        dispatch.snackbar.create({
          text: `${i18n.t('noun.failed_moved_projects')}`
        });
      }
    },
    async edit(params, { application, ...state }) {
      try {
        const { save } = state.project.modal;
        const { realm } = application.realm;
        const { data } = await api.project.edit({
          project: {
            ...params,
            id: save?.entity.id
          },
          realm
        });
        const project = new schema.Entity('project');
        const source = normalize(data.editProject, project);
        dispatch.project.setUpdate(source);
        dispatch.snackbar.create({
          text: i18n.t('label.edit_project_msg_success', {
            projectName: params.name
          }),
          action: { label: i18n.t('common.labels.ok') }
        });
      } catch (e) {
        dispatch.project.setError({ path: 'save', value: e });
        dispatch.snackbar.create({
          text: normalizeError.onGraphQL(e.message)
        });
      }
    },
    async getProject(params, { application }) {
      try {
        const { realm } = application.realm;
        const { data } = await api.project.getProject({
          realm,
          ...params
        });
        dispatch.project.setModal({
          path: 'save',
          value: { isOpen: true, entity: data.getProject }
        });
      } catch (e) {
        dispatch.project.setError({ path: 'getProject', value: e });
        dispatch.snackbar.create({
          text: normalizeError.onGraphQL(e.message)
        });
      }
    }
  }),
  logics: [
    {
      type: 'project/fetch',
      latest: true,
      process({ action }, dispatch, done) {
        const { meta } = action;
        dispatch.project.setLoading({
          path: 'fetch',
          value: !!meta?.loading
        });
        done();
      }
    },
    {
      type: ['project/setFetch'],
      latest: true,
      process({ action, getState }, dispatch, done) {
        const { result } = action.payload;
        const { project } = getState();

        if (
          (!action?.meta?.isSetCurrent || !project.current) &&
          action?.meta?.loading
        ) {
          const current = head(result?.project);
          dispatch.project.setCurrent(current);
        }
        if (action?.meta?.isSetNextCurrent) {
          const nextProjectExists = result?.project?.includes(
            project?.nextCurrent
          );
          if (nextProjectExists) {
            dispatch.project.setCurrent(project?.nextCurrent);
          }
        }
        dispatch.project.setLoading({ path: 'fetch', value: false });
        done();
      }
    },

    {
      type: ['project/setError'],
      latest: true,
      process({ action }, dispatch, done) {
        const { payload } = action;
        dispatch.project.setLoading({ path: payload?.path, value: false });
        done();
      }
    },

    {
      type: ['project/onSelectEdit'],
      latest: true,
      process(context, dispatch, done) {
        dispatch.project.setModal({ path: 'edit', value: true });
        done();
      }
    },
    {
      type: ['project/setUpdate'],
      latest: true,
      process(context, dispatch, done) {
        dispatch.project.setModal({ path: 'save', value: false });
        dispatch.project.setLoading({ path: 'save', value: false });
        done();
      }
    },
    {
      type: ['project/setCurrent'],
      latest: true,
      process(context, dispatch, done) {
        done();
      }
    },
    {
      type: ['project/setSuccess'],
      latest: true,
      process({ action }, dispatch, done) {
        const { payload } = action;
        dispatch.project.setLoading({ path: 'save', value: false });
        dispatch.project.setModal({
          path: 'save',
          value: { isOpen: false, id: null }
        });
        dispatch.project.setNextCurrent(payload?.result);
        dispatch.project.fetch(null, {
          loading: true,
          isSetCurrent: true,
          isSetNextCurrent: true
        });
        done();
      }
    },
    {
      type: ['project/setRemove'],
      latest: true,
      process({ action }, dispatch, done) {
        const { payload } = action;
        dispatch.project.setCurrent(head(payload.result.project));
        done();
      }
    },
    {
      type: ['project/save'],
      latest: true,
      process(context, dispatch, done) {
        dispatch.project.setLoading({ path: 'save', value: true });
        done();
      }
    },
    {
      type: ['project/edit'],
      latest: true,
      process(context, dispatch, done) {
        dispatch.project.setLoading({ path: 'save', value: true });
        done();
      }
    }
  ]
};

export default projectModal;
