import {
  Box,
  Button,
  Flex,
  GroupButton,
  Icon,
  SearchBar
} from '@digibee/beehive-ui';
import { faTable } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

import featureFlagConstants from '~/common/helpers/featureFlagConstants';
import i18n from '~/common/helpers/i18n';
import useFeatureFlag from '~/common/hooks/useFeatureFlag';
import Search from '~/components/Search';

type View = 'card' | 'table';

interface HeaderProps {
  find?: () => void;
  realm?: string;
  environment?: string;
  setSearch?: (searchTerm: string) => void;
  search: Function;
  status: string[];
  selectedTags: string[];
  onViewButtonClick?: (view: View) => void;
}

const Header: React.FC<HeaderProps> = ({
  search,
  selectedTags,
  onViewButtonClick
}) => {
  const [searchParams] = useSearchParams();
  const [activeView, setActiveView] = React.useState(
    searchParams.get('activeView') ||
      localStorage.getItem('activeView') ||
      'card'
  );
  const { RUN_LIST_DESIGNSYSTEM } = featureFlagConstants;
  const { treatments } = useFeatureFlag([RUN_LIST_DESIGNSYSTEM]);
  const showDesignSystemComponents =
    treatments[RUN_LIST_DESIGNSYSTEM].treatment === 'on';

  const tags = [
    {
      label: i18n.t('scenes.runtime.labels.deployed'),
      value: 'SERVICE_ACTIVE'
    },
    {
      label: i18n.t('scenes.runtime.labels.status_error'),
      value: 'SERVICE_ERROR'
    },
    {
      label: i18n.t('scenes.runtime.labels.redeploy'),
      value: 'REDEPLOY'
    },
    {
      label: i18n.t('scenes.runtime.labels.starting'),
      value: 'STARTING'
    }
  ];

  const statusTags = searchParams.get('status')
    ? searchParams.get('status')?.split(',') || []
    : [];

  const handleViewButtonClick = (view: View) => {
    setActiveView(view);

    onViewButtonClick?.(view);
  };

  return (
    <Flex
      css={{
        width: '100%',
        height: '66px',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Box
        css={{
          flex: 1
        }}
      />
      <Box
        css={{
          width: '50vw',
          '@media only screen and (max-width: 761px)': {
            width: '100%',
            padding: '0 15px'
          }
        }}
      >
        {showDesignSystemComponents ? (
          <SearchBar
            data-testid='run-input-search'
            placeholder={i18n.t('common.actions.search')}
            placeholderTags={i18n.t('label.all')}
            textValue={searchParams.get('pipelineName') || ''}
            onSearch={(e, tags) => search(e, tags)}
            // @ts-ignore
            tagsSource={tags}
            tagsValue={statusTags}
            multiple={true}
          />
        ) : (
          <Search
            placeholder={i18n.t('scenes.runtime.actions.search')}
            data-testid='run-input-search'
            onSearch={(e: any, tags: any) => search(e, tags)}
            tagsSource={tags}
            zIndexValue='auto'
            tagsValue={selectedTags}
            onTextChange={() => {}}
            onTagsChange={() => {}}
            onChange={() => {}}
            textValue={undefined}
            multiple={true}
          />
        )}
      </Box>
      <Box
        css={{
          flex: 1
        }}
      >
        <Flex
          css={{
            width: 'fit-content',
            position: 'relative',
            backgroundColor: '$primary',
            marginInlineStart: 'auto'
          }}
          data-active={activeView}
        >
          <GroupButton>
            <Button
              variant={activeView === 'card' ? 'primary' : 'text'}
              data-testid='run-card-view-button'
              onClick={() => handleViewButtonClick('card')}
            >
              <Icon icon='grip-vertical' />
            </Button>
            <Button
              variant={activeView === 'table' ? 'primary' : 'text'}
              data-testid='run-table-view-button'
              onClick={() => handleViewButtonClick('table')}
            >
              <FontAwesomeIcon icon={faTable} />
            </Button>
          </GroupButton>
        </Flex>
      </Box>
    </Flex>
  );
};

export default Header;
