import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { compose } from 'recompose';

import withAcls from '~/common/hoc/withAcls';
import withHooks from '~/common/hoc/withHooks';

const enhancer = compose(
  withAcls,
  withHooks(props => {
    const [configActionButtonRoles, setConfigActionButtonRoles] = useState([]);

    const { application } = useSelector(state => state);
    const state = {
      realm: application.realm.realm,
      environment: application.environment,
      environments: application.environments.allNames,
      hiddenPages: application.hiddenPages || []
    };

    useEffect(() => {
      setConfigActionButtonRoles(
        props.configActionButton.reduce(
          (acc, actionButton) => acc.concat(actionButton.role),
          []
        )
      );
    }, [props.configActionButton]);

    return {
      ...props,
      ...state,
      configActionButtonRoles
    };
  })
);

export default enhancer;
