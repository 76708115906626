import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import withHooks from '~/common/hoc/withHooks';
import { StoreDispatcher } from '~/scenes/Governance/types/StoreDispatcher';
import { StoreRootState } from '~/scenes/Governance/types/StoreRootState';

export type Props = {
  codeExpirationInSeconds: number,
  validateVerificationCode: (verificationCode: string) => void,
  loading: boolean,
  validationError: boolean | undefined,
  attemptsRemaining: number | undefined,
  resendCode: () => void,
};

const enhancer = withHooks(() => {
  const { authentication: { error, loading } } = useSelector((state: StoreRootState) => state);
  const dispatch = useDispatch<StoreDispatcher>();

  const navigate = useNavigate();
  const [codeExpirationInSeconds, setCodeExpirationInSeconds] = useState(0);
  const [expirationTime, setExpirationTime] = useState<number>(0);

  function startExpirationCountdown() {
    const now = new Date().getTime();
    const difference = (expirationTime - now) / 1000;
    if (difference < 0) {
      setCodeExpirationInSeconds(0);
    } else {
      setCodeExpirationInSeconds(Math.floor(difference));
      setTimeout(() => startExpirationCountdown(), 1000);
    }
  }

  function generateCodeExpiration() {
    const expiration = new Date();
    expiration.setSeconds(expiration.getSeconds() + 60);
    setExpirationTime(expiration.getTime());
  }

  function validateVerificationCode(verificationCode: string) {
    dispatch.authentication.validateVerificationCode({ verificationCode });
  }

  function resendCode() {
    dispatch.authentication.sendVerificationCode();
    generateCodeExpiration();
  }

  useEffect(() => {
    startExpirationCountdown();
  }, [expirationTime]);

  useEffect((): void => {
    if (!error?.lockAuth) {
      dispatch.authentication.resetError();
      navigate('/login');
    } else {
      generateCodeExpiration();
    }
  }, []);

  const props: Props = {
    codeExpirationInSeconds,
    validateVerificationCode,
    loading,
    validationError: error?.verificationCodeError,
    attemptsRemaining: error?.attemptsRemaining,
    resendCode,
  };

  return props;
});

export default enhancer;
