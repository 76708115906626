import { ApolloError } from '@apollo/client';
import { Dialog, Button, Text } from '@digibee/beehive-ui';
import { choose, when } from '@digibee/control-statements';
import React from 'react';
import { useSelector } from 'react-redux';

import { useIsAutoscaling } from '../../../Run/hooks/useIsAutoscaling';
import { isAutoScalingDeploy } from '../../../Run/utils/isAutoScalingDeploy';

import api from '~/api/runtime';
import i18n from '~/common/helpers/i18n';
import useSnackbar from '~/common/hooks/useSnackbar';
import { Store } from '~/common/types/Store';
import { EnvironmentTag } from '~/components/EnvironmentTag';
import { HistoricType } from '~/entities/HistoryDeployment';
import CardLicense, {
  LicenseStatusType
} from '~/scenes/Runtime/components/CardLicense/CardLicense';

type Props = {
  opened: boolean;
  onCancel: () => void;
  licenseStatus: LicenseStatusType | null;
  history: HistoricType | null;
  environment: string;
};

const ShowDialogLicenseRevert = ({
  opened,
  onCancel,
  licenseStatus,
  history,
  environment
}: Props) => {
  const { realm } = useSelector(({ application }: Store) => application.realm);
  const snackbar = useSnackbar();
  const [loading, setLoading] = React.useState(false);

  const showNewAutoScalingExperience =
    useIsAutoscaling() &&
    isAutoScalingDeploy({
      minReplicaCount: history?.runtimeConfiguration?.minReplicaCount,
      replicaCount: history?.runtimeConfiguration?.replicaCount
    });

  async function revertDeploy() {
    if (!history) return;
    try {
      setLoading(true);
      await api.revertDeploy({
        realm,
        deployHistoryId: history.id,
        environment
      });
      snackbar.createSnack(
        i18n.t('label.restore_minor_version_pipe_deploy_msg_success', {
          versNumber: `${history.pipeline.versionMajor}.${history.pipeline.versionMinor}`
        })
      );
      onCancel();
    } catch (error: unknown) {
      if (
        error instanceof ApolloError &&
        // @ts-ignore
        error.cause?.extensions?.code === 'MAXIMUM_ALLOWED_VALUE'
      ) {
        snackbar.createSnack(
          i18n.t('label.maximum_replicas_allowed_error', {
            maxReplicas: error.message.split(':')[1].trim()
          })
        );
      } else {
        snackbar.createSnack(
          i18n.t('label.couldnt_restore_minor_version_pipe_deploy_msg_alert', {
            versNumber: `${history.pipeline.versionMajor}.${history.pipeline.versionMinor}`
          })
        );
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <Dialog.Root open={opened} onOpenChange={open => !open && onCancel()}>
      {/* @ts-ignore */}
      <Dialog.Content css={{ padding: '$3' }}>
        <Dialog.Header>
          <EnvironmentTag environment={environment} />
          <Text as='h1' variant='h4'>
            {history &&
              i18n.t('', {
                pipeName: history.pipeline.name,
                versNumber: `${history.pipeline.versionMajor}.${history.pipeline.versionMinor}`
              })}
          </Text>
        </Dialog.Header>
        <Dialog.Body css={{ paddingBottom: '$1' }}>
          <Text css={{ marginBottom: '$2' }}>
            {i18n.t('label.restore_pipe_deploy_minor_vers_msg')}
          </Text>
          {choose(
            when(showNewAutoScalingExperience, () => null),
            when(!!licenseStatus, () => (
              <CardLicense
                licenseStatus={licenseStatus!}
                environment={history?.environment?.name.toString() || ''}
                licenses={0}
                isRedeploy={false}
              />
            ))
          )}
        </Dialog.Body>
        <Dialog.Footer>
          <Button
            size='medium'
            variant='primary'
            disabled={loading}
            data-testid='revert-deploy-button'
            onClick={() => {
              revertDeploy();
            }}
          >
            {i18n.t('action.restore')}
          </Button>
          <Dialog.Close asChild>
            <Button outlined size='medium' variant='primary'>
              {i18n.t('action.cancel')}
            </Button>
          </Dialog.Close>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog.Root>
  );
};

export default ShowDialogLicenseRevert;
