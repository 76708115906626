import Flow, {
  BaseComponent,
  ChoiceComponent,
  Component,
  Level,
  LevelComponent,
  LevelType,
  Nullish,
  ParallelComponent
} from '@digibee/flow';
import {
  faCirclePlus,
  faCog,
  faRotate,
  faRotateExclamation,
  faTrash,
  faSparkles
} from '@fortawesome/pro-regular-svg-icons';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';
import { createContext, useContext } from 'use-context-selector';

import { sendToBus } from '../../Bus';
import { FlowCanvasMachine } from '../../FlowCanvas/FlowCanvas.machine';
import { DataType } from '../components/Connector/Connector';
import { ElementsMenuConfig } from '../components/ElementsMenu';
import { DesignActor } from '../Design.machine';
import { ComponentSuggestionActor } from '../machines/ComponentSuggestion.machine';
import { DebuggerActor } from '../panels/Debugger/Debugger.machine';

import featureFlagConstants from '~/common/helpers/featureFlagConstants';
import i18n from '~/common/helpers/i18n';
import WebAnalytics from '~/common/helpers/webAnalytics';
import useFeatureFlag from '~/common/hooks/useFeatureFlag';
import {
  componentToNameMapper,
  getPreviousComponents
} from '~/scenes/AI/machines/helpers/smartConnectorSuggestion';
import { getConnector, Connectors } from '~/scenes/Build/helpers';
import { getConnectorByCatalog } from '~/scenes/Build/helpers/getConnectorByCatalog';
import { CapsuleActor } from '~/scenes/Build/scenes/Capsule/Capsule.machine';

export type DesignActors = {
  designActor: DesignActor;
  debuggerActor: DebuggerActor;
  componentSuggestionActor: ComponentSuggestionActor;
  flowCanvasActor: FlowCanvasMachine.Actor;
  capsuleActor: CapsuleActor;
};

type DesignContext = DesignActors & {
  connectors: Connectors;
  setFlowCanvasActor: (actor: FlowCanvasMachine.Actor) => void;
  readOnly: boolean;
  currentLevel: Nullish | Level;
  onStarterNodeConfigClick?: () => void;
  isCapsule: boolean;
  componentMenuConfig?: ElementsMenuConfig[];
  connectionsMenu?: ElementsMenuConfig[];
  openSmartConnectorOnConnector: (source: DataType) => void;
  shouldDisplaySuggestions: boolean;
  openSmartConnectorOnConnection: (sourceId: string, targetId: string) => void;
};

export const DesignContext = createContext<DesignContext>({
  designActor: {} as DesignActor,
  debuggerActor: {} as DebuggerActor,
  connectors: {} as Connectors,
  flowCanvasActor: {} as FlowCanvasMachine.Actor,
  componentSuggestionActor: {} as ComponentSuggestionActor,
  capsuleActor: {} as CapsuleActor,
  componentMenuConfig: [],
  connectionsMenu: [],
  readOnly: false,
  currentLevel: null,
  isCapsule: false,
  shouldDisplaySuggestions: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setFlowCanvasActor: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onStarterNodeConfigClick: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  openSmartConnectorOnConnector: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  openSmartConnectorOnConnection: () => {}
});

export function useDesignContext() {
  return useContext(DesignContext);
}

export function DesignProvider({
  value,
  children
}: {
  children: React.ReactNode;
  value: Partial<DesignContext>;
}) {
  const [flowCanvasActor, setFlowCanvasActor] = useState(
    {} as FlowCanvasMachine.Actor
  );

  const connectors = value.connectors || ({} as Connectors);
  const designActor = value.designActor || ({} as DesignActor);
  const debuggerActor = value.debuggerActor || ({} as DebuggerActor);
  const capsuleActor = value.capsuleActor || ({} as CapsuleActor);
  const componentSuggestionActor =
    value.componentSuggestionActor || ({} as ComponentSuggestionActor);

  const debuggerMachineState = debuggerActor && debuggerActor.getSnapshot();
  const designMachineState = designActor && designActor.getSnapshot();
  const isOnDebugMode =
    designMachineState?.context.currentFlowRef === 'debugMode' || false;
  const readOnly = value.readOnly || false;

  const [componentMenuConfig, setComponentMenuConfig] =
    useState<ElementsMenuConfig[]>();
  const [connectionsMenu, setConnectionsMenu] =
    useState<ElementsMenuConfig[]>();
  const [shouldDisplaySuggestions, setShouldDisplaySuggestions] =
    useState(false);

  const {
    AI_CHAT_ASSISTANT_ACTIONS,
    BUILD_CANVAS_AI_COPILOT,
    BUILD_TEST_MODE_SCALE_TO_ZERO,
    SMART_CONNECTOR,
    SMART_CONNECTOR_AI_SUGGESTIONS,
    SMART_CONNECTOR_ADD_STEP,
    BUILD_API_FIRST,
    BUILD_CONNECTOR_MOCKING,
    BUILD_UNIFIED_CATALOG,
    BUILD_DESIGN_HOOK
  } = featureFlagConstants;
  const { treatments } = useFeatureFlag([
    AI_CHAT_ASSISTANT_ACTIONS,
    BUILD_CANVAS_AI_COPILOT,
    BUILD_TEST_MODE_SCALE_TO_ZERO,
    SMART_CONNECTOR,
    SMART_CONNECTOR_AI_SUGGESTIONS,
    SMART_CONNECTOR_ADD_STEP,
    BUILD_API_FIRST,
    BUILD_CONNECTOR_MOCKING,
    BUILD_UNIFIED_CATALOG,
    BUILD_DESIGN_HOOK
  ]);

  const isAiCopilotEnabled =
    treatments[BUILD_CANVAS_AI_COPILOT].treatment === 'on';
  const isSmartConnectorEnabled =
    treatments[SMART_CONNECTOR].treatment === 'on';
  const isSmartConnectorAiEnabled =
    treatments[SMART_CONNECTOR_AI_SUGGESTIONS].treatment === 'on';

  const isApiFirst = treatments[BUILD_API_FIRST].treatment === 'on';

  const isUnifiedCatalog = treatments[BUILD_UNIFIED_CATALOG].treatment === 'on';
  const isSmartConnectorAddStepEnabled =
    treatments[SMART_CONNECTOR_ADD_STEP].treatment === 'on';

  const startersWithoutTrigger = ['start-track', 'starter-node'];
  const starters = [...startersWithoutTrigger, 'trigger'];
  const aiSuggestionExcludeList = [
    'log-connector',
    'throw-error-connector',
    'script-connector',
    'delayer',
    'capsule',
    'library'
  ];

  const dispatch = useDispatch();
  const isUsingDesignHook = treatments[BUILD_DESIGN_HOOK].treatment === 'on';

  const checkElementWillOpenSmartConnectorOutOfBounds = (
    elementData: DOMRect
  ) => {
    const { top, right, bottom } = elementData;
    const bottomValue = window.innerHeight - bottom;
    const rightValue = window.innerWidth - right;

    const smartMenuSize = 400;
    const offset = 65;
    const yTotalOffset = smartMenuSize / 2 + offset;
    const xTotalOffset = smartMenuSize + offset;

    const topSpace = top < yTotalOffset ? top - yTotalOffset : 0;
    const y =
      bottomValue < smartMenuSize / 2
        ? smartMenuSize / 2 - bottomValue
        : topSpace;
    const x = rightValue < xTotalOffset ? xTotalOffset - rightValue : 0;

    return {
      x,
      y
    };
  };

  const getBoundingClientRect = (elementName: string) => {
    const element = document.querySelector(`[data-id="${elementName}"]`);
    return element?.getBoundingClientRect();
  };

  const moveCanvasAndOpenSmartConnectorMenu = (element: DOMRect) => {
    const panValues = checkElementWillOpenSmartConnectorOutOfBounds(element);
    sendToBus({
      event: {
        type: 'MOVE_CANVAS',
        ...panValues
      },
      to: 'flowCanvas'
    });
  };

  function isCopilotActionDisabled(
    data: {
      id: string;
      component: Component | null | undefined;
    },
    checkForSmartConnectorAi = true
  ): boolean {
    const { id, component } = data;
    const triggerName = designActor.getSnapshot()?.context.triggerSpec?.name;

    if (id === 'trigger') {
      return !triggerName;
    }

    if (
      checkForSmartConnectorAi &&
      isSmartConnectorEnabled &&
      isSmartConnectorAiEnabled
    )
      return true;

    if (!checkForSmartConnectorAi && startersWithoutTrigger.includes(id))
      return true;

    if (
      !isAiCopilotEnabled ||
      readOnly ||
      debuggerMachineState?.matches('debugMode.enabled')
    ) {
      return true;
    }

    if (component?.canConnect() === false) return true;

    return false;
  }

  const openSmartConnector = (id: string) => {
    designActor.send({
      type: 'SHOW_SMART_CONNECTOR_MENU',
      elementId: id
    });
    WebAnalytics.sendEvent('[Build] (Smart Connector) Open Add Step Dialog');
  };

  function onNextComponentSuggestionActionClick(
    data: DataType,
    isSmartConnector = false
  ) {
    const { id, component } = data;

    const getLastComponentsNames = (currentComponent: Component) => {
      const previousComponents = getPreviousComponents(
        currentComponent,
        Infinity,
        true
      );
      const componentName = componentToNameMapper(currentComponent);
      return previousComponents
        .map(componentToNameMapper)
        .concat(
          aiSuggestionExcludeList.includes(componentName) ? [] : componentName
        );
    };

    const lastComponentsNames = component
      ? getLastComponentsNames(component)
      : [];

    componentSuggestionActor.send({
      type: isSmartConnector
        ? 'FETCH_SUGGESTIONS_SMART_CONNECTOR'
        : 'TOGGLE_SUGGESTIONS',
      componentId: id,
      triggerName: designActor?.getSnapshot()?.context.triggerSpec?.name,
      lastComponentsNames
    });
  }

  const openSmartConnectorOnConnector = async (source: DataType) => {
    const { id, component } = source;

    designActor.send('CLOSE_PALETTE');

    const nodeId =
      typeof component === 'string' ? component : component?.id() || id;
    const elementData = getBoundingClientRect(nodeId);

    if (elementData) {
      setTimeout(() => {
        moveCanvasAndOpenSmartConnectorMenu(elementData);

        setTimeout(() => {
          openSmartConnector(id);
        }, 100);
      }, 1);
    }

    if (isSmartConnectorAiEnabled && !isCopilotActionDisabled(source, false)) {
      setShouldDisplaySuggestions(true);
      onNextComponentSuggestionActionClick(source, true);
    } else {
      setShouldDisplaySuggestions(false);
    }
  };

  const openSmartConnectorOnConnection = (
    sourceId: string,
    targetId: string
  ) => {
    designActor.send('CLOSE_PALETTE');

    const elementData = getBoundingClientRect(
      `add-step-${sourceId}:${targetId}`
    );

    if (elementData) {
      moveCanvasAndOpenSmartConnectorMenu(elementData);

      setTimeout(() => {
        openSmartConnector(`${sourceId}:${targetId}`);
      }, 100);
    }

    if (
      isSmartConnectorAiEnabled &&
      !isCopilotActionDisabled({ id: sourceId, component: undefined }, false)
    ) {
      setShouldDisplaySuggestions(true);
      onNextComponentSuggestionActionClick(
        { id: sourceId, component: undefined },
        true
      );
    } else {
      setShouldDisplaySuggestions(false);
    }
  };

  function checkSmartConnectorDisabled({
    id,
    component,
    flow,
    currentLevelName
  }: {
    id: string;
    component: Component | null | undefined;
    flow: Flow;
    currentLevelName: string;
  }): boolean {
    const startersWithoutStartTrack = ['starter-node', 'trigger'];
    const isStarter = startersWithoutStartTrack.includes(id);
    const startTrackFirstComponent = flow.getTrack('start')?.spec()[0];
    const isUsingApiTrigger =
      startTrackFirstComponent?.id.startsWith('api-first');

    if (isApiFirst && isUsingApiTrigger && currentLevelName === 'start') {
      return id === 'trigger' || id.startsWith('api-first');
    }

    if (isStarter) {
      return !!flow.getTrack('start')?.spec().length;
    }

    if (id === 'start-track') {
      return !!flow.getTrack(currentLevelName)?.spec().length;
    }

    if (
      !isAiCopilotEnabled ||
      readOnly ||
      debuggerMachineState?.matches('debugMode.enabled')
    ) {
      return true;
    }

    if (component?.canConnect() === false) return true;

    return false;
  }

  const smartConnectorMenuConfig = {
    icon: faCirclePlus,
    name: 'smart-connector',
    label: 'Smart Connector',
    onClick: openSmartConnectorOnConnector,
    disabled:
      !isSmartConnectorEnabled ||
      isSmartConnectorAddStepEnabled ||
      checkSmartConnectorDisabled
  };

  const onChangeLevel = (nodeId: string | undefined, levelType: LevelType) => {
    if (levelType === 'start' && !value.currentLevel?.isStart()) {
      return designActor.send({
        type: 'CHANGE_LEVEL',
        levelType: 'start'
      });
    }

    return designActor.send({
      type: 'CHANGE_LEVEL',
      componentId: nodeId,
      levelType
    });
  };

  const onDeleteNodes = (id: string) => {
    sendToBus({
      event: {
        type: 'ON_DELETE_NODES',
        ids: [id]
      },
      to: 'flowCanvas'
    });

    // @ts-ignore
    dispatch.designPipeline.deleteConnectorMocks(id);
  };

  function disabledConfig(data: { id: string }) {
    if (isApiFirst) {
      const { id } = data;
      return id.includes('api-first:');
    }

    return false;
  }

  function isCopilotActionsHighlighted({ id }: { id: string }) {
    return (
      componentSuggestionActor.getSnapshot()?.context.currentComponentId === id
    );
  }

  function disabledApiFirst(data: { id: string; currentLevelName: string }) {
    const disabledValueDefault = readOnly || isOnDebugMode;
    if (isApiFirst) {
      const { id, currentLevelName } = data;
      if (
        currentLevelName.startsWith('api-first:') &&
        currentLevelName.endsWith('Track')
      ) {
        return disabledValueDefault;
      }
      return id.includes('api-first:') || disabledValueDefault;
    }
    return disabledValueDefault;
  }

  function buildComponentMenuConfig() {
    const allItensMenu: ElementsMenuConfig[] = [
      {
        predicate: ({ component, id }) => {
          const { type = '', name } = component?.spec() || {};
          const isLibrary = type === 'library';
          const connector = isUnifiedCatalog
            ? getConnectorByCatalog(connectors, type, name || '')
            : getConnector(connectors, type, name);
          return (!starters.includes(id) && !connector.exists) || isLibrary;
        },
        actions: [
          {
            disabled: readOnly || isOnDebugMode,
            icon: faTrash,
            type: 'confirmation',
            name: 'delete-component',
            label: i18n.t('label.component_delete_tooltip'),
            onClick: ({ id }) => onDeleteNodes(id)
          }
        ]
      },
      {
        predicate: ({ component }) => component instanceof LevelComponent,
        actions: [
          {
            icon: faCog,
            name: 'open-component-config',
            label: i18n.t('label.component_configurations_tooltip'),
            disabled: disabledConfig,
            onClick: ({ id }) =>
              designActor.send({
                type: 'OPEN_CONFIG',
                id,
                itemType: 'component',
                isUnifiedCatalog
              })
          },
          {
            icon: faRotate,
            name: 'enter-on-process',
            label: i18n.t('label.component_on_process_tooltip'),
            onClick: ({ id }) => onChangeLevel(id, 'onProcess')
          },
          {
            icon: faRotateExclamation,
            name: 'enter-on-exception',
            label: i18n.t('label.component_on_exception_tooltip'),
            onClick: ({ id }) => onChangeLevel(id, 'onException')
          },
          {
            icon: faSparkles,
            name: 'open-component-suggestions',
            label: i18n.t('label.ai_suggestions_tooltip'),
            disabled: isCopilotActionDisabled,
            highlighted: isCopilotActionsHighlighted,
            onClick: onNextComponentSuggestionActionClick
          },
          {
            disabled: disabledApiFirst,
            icon: faTrash,
            type: 'confirmation',
            name: 'delete-component',
            label: i18n.t('label.component_delete_tooltip'),
            onClick: ({ id }) => onDeleteNodes(id)
          },
          smartConnectorMenuConfig
        ]
      },
      {
        predicate: ({ component }) => component instanceof BaseComponent,
        actions: [
          {
            icon: faCog,
            name: 'open-component-config',
            label: i18n.t('label.component_configurations_tooltip'),
            onClick: ({ id, component }) => {
              const spec = component?.spec();
              if (
                isApiFirst &&
                spec?.type === 'choice' &&
                id.startsWith('api-first:')
              ) {
                return value.onStarterNodeConfigClick?.();
              }
              return designActor.send({
                type: 'OPEN_CONFIG',
                id,
                itemType: 'component',
                name: spec?.name,
                isUnifiedCatalog
              });
            }
          },
          {
            icon: faSparkles,
            name: 'open-component-suggestions',
            label: i18n.t('label.ai_suggestions_tooltip'),
            disabled: isCopilotActionDisabled,
            highlighted: isCopilotActionsHighlighted,
            onClick: onNextComponentSuggestionActionClick
          },
          {
            disabled: disabledApiFirst,
            icon: faTrash,
            type: 'confirmation',
            name: 'delete-component',
            label: i18n.t('label.component_delete_tooltip'),
            onClick: ({ id }) => onDeleteNodes(id)
          },
          smartConnectorMenuConfig
        ]
      },
      {
        predicate: ({ id }) => !value.isCapsule && id !== 'start-track',
        actions: [
          {
            icon: faCog,
            name: 'open-component-config',
            label: i18n.t('label.component_configurations_tooltip'),
            onClick: () => value.onStarterNodeConfigClick?.()
          },
          smartConnectorMenuConfig
        ]
      },
      {
        predicate: ({ id }) => startersWithoutTrigger.includes(id),
        actions: [smartConnectorMenuConfig]
      },
      {
        actions: []
      }
    ];
    return allItensMenu;
  }

  function buildConnectionMenu() {
    const connectionsMenuConfig: ElementsMenuConfig[] = [
      {
        predicate: ({ component }) => component instanceof ChoiceComponent,
        actions: [
          {
            icon: faCog,
            name: 'open-choice-connection-config',
            label: i18n.t('label.component_configurations_tooltip'),
            disabled: disabledApiFirst,
            onClick: ({ targetId }) =>
              designActor.send({
                type: 'OPEN_CONFIG',
                id: targetId as string,
                itemType: 'condition'
              })
          },
          {
            disabled: disabledApiFirst,
            icon: faTrash,
            type: 'confirmation',
            name: 'delete-choice-connection',
            label: i18n.t('label.component_delete_tooltip'),
            onClick: ({ targetId }) =>
              sendToBus({
                event: {
                  type: 'ON_DELETE_EDGES',
                  ids: [targetId]
                },
                to: 'flowCanvas'
              })
          }
        ]
      },
      {
        predicate: ({ component }) => component instanceof ParallelComponent,
        actions: [
          {
            icon: faCog,
            name: 'open-parallel-connection-config',
            label: i18n.t('label.component_configurations_tooltip'),
            onClick: ({ targetId }) =>
              designActor.send({
                type: 'OPEN_CONFIG',
                id: targetId as string,
                itemType: 'execution'
              })
          },
          {
            disabled: disabledApiFirst,
            icon: faTrash,
            type: 'confirmation',
            name: 'delete-parallel-connection',
            label: i18n.t('label.component_delete_tooltip'),
            onClick: ({ targetId }) =>
              sendToBus({
                event: {
                  type: 'ON_DELETE_EDGES',
                  ids: [targetId]
                },
                to: 'flowCanvas'
              })
          }
        ]
      },
      {
        actions: [
          {
            disabled: disabledApiFirst,
            icon: faTrash,
            type: 'confirmation',
            name: 'delete-connection',
            label: i18n.t('label.component_delete_tooltip'),
            onClick: ({ targetId }) =>
              sendToBus({
                event: {
                  type: 'ON_DELETE_EDGES',
                  ids: [targetId]
                },
                to: 'flowCanvas'
              })
          }
        ]
      }
    ];
    return connectionsMenuConfig;
  }

  useDeepCompareEffect(() => {
    if (isUsingDesignHook) {
      setComponentMenuConfig(buildComponentMenuConfig());
      setConnectionsMenu(buildConnectionMenu());
    }
  }, [isOnDebugMode, isUsingDesignHook]);

  return (
    <DesignContext.Provider
      value={{
        designActor,
        debuggerActor,
        componentSuggestionActor,
        connectors,
        flowCanvasActor,
        setFlowCanvasActor,
        componentMenuConfig,
        openSmartConnectorOnConnector,
        connectionsMenu,
        shouldDisplaySuggestions,
        isCapsule: value.isCapsule || false,
        readOnly,
        currentLevel: value.currentLevel,
        openSmartConnectorOnConnection,
        capsuleActor
      }}
    >
      {children}
    </DesignContext.Provider>
  );
}
