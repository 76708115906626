import { identity, last, omit, pickBy } from 'lodash';
import moment from 'moment';
import { normalize, schema } from 'normalizr';
import queryString from 'query-string';
import { createSearchParams } from 'react-router-dom';
import Immutable from 'seamless-immutable';

// Locals
import * as api from '~/api';

const getInitPropsSearch = () => {
  const STRING_EMPTY = '';
  const TIME_TYPE_DEFAUT = '5 Minutes';
  const SPECIFIC_DATE_FROM_DEFAULT = moment().seconds(0).milliseconds(0);
  const SORT_BY_DEFAULT = 'DESC';

  return {
    pipelineName: STRING_EMPTY,
    pipelineVersion: STRING_EMPTY,
    time: TIME_TYPE_DEFAUT,
    logMessage: STRING_EMPTY,
    pipelineKey: STRING_EMPTY,
    level: STRING_EMPTY,
    sortBy: SORT_BY_DEFAULT,
    specific: {
      dateFrom: SPECIFIC_DATE_FROM_DEFAULT,
      dateTo: SPECIFIC_DATE_FROM_DEFAULT
    }
  };
};

const getPropsSearch = () => {
  const params = queryString.parse(window.location.search.replace('?', ''));
  const STRING_EMPTY = '';
  const TIME_TYPE_DEFAUT = '5 Minutes';
  const SPECIFIC_DATE_FROM_DEFAULT = moment().seconds(0).milliseconds(0);
  const SORT_BY_DEFAULT = 'DESC';

  return {
    pipelineName: params.pipelineName || STRING_EMPTY,
    pipelineVersion: params.pipelineVersion || STRING_EMPTY,
    time: params.time || TIME_TYPE_DEFAUT,
    logMessage: params.logMessage || STRING_EMPTY,
    pipelineKey: params.pipelineKey || STRING_EMPTY,
    level: params.level || STRING_EMPTY,
    sortBy: params.sortBy || SORT_BY_DEFAULT,
    specific: {
      dateFrom: moment(params.dateFrom) || SPECIFIC_DATE_FROM_DEFAULT,
      dateTo: moment(params.dateTo) || SPECIFIC_DATE_FROM_DEFAULT
    }
  };
};

const parseStringDate = ({ dateTo, dateFrom }) => ({
  dateFrom: moment(dateFrom).seconds(0).milliseconds(0).toISOString(),
  dateTo: moment(dateTo).seconds(59).milliseconds(999).toISOString()
});

const initialState = Immutable({
  result: {
    pipelineLogs: []
  },
  entities: {
    pipelineLogs: {}
  },
  loading: {
    fetch: false,
    more: false,
    reexecute: false,
    fetchMessagesByKey: false
  },
  success: {
    reexecute: null
  },
  last: true,
  error: {
    fetch: null
  },
  modal: {
    reexecution: false,
    logsDetails: false,
    logDetails: null,
    pipeline: null
  },
  search: getPropsSearch()
});

const Entity = new schema.Entity('pipelineLogs');
const Schema = [Entity];

const transformFormatDate = (values, params) =>
  moment().subtract(values, params).toISOString();

const pipelineLogsModel = {
  name: 'pipelineLogs',
  state: initialState,
  reducers: {
    set(state, payload) {
      return state.merge({ ...payload }, { deep: true });
    },
    loading(state, { path, value }) {
      return state.merge(
        {
          loading: {
            [path]: value
          }
        },
        { deep: true }
      );
    },
    setError(state, { path, value }) {
      return state.merge({
        error: {
          [path]: value
        }
      });
    },
    success(state, { path, value }) {
      return state.merge({
        success: {
          [path]: value
        }
      });
    },
    setLast(state, payload) {
      return state.merge({
        last: payload
      });
    },
    open(state, { path, value }) {
      return state.merge(
        {
          modal: {
            [path]: value
          }
        },
        {
          deep: true
        }
      );
    },
    setModalPipeline(state, payload) {
      return state.merge(
        {
          modal: {
            pipeline: payload
          }
        },
        { deep: true }
      );
    },
    setModalLog(state, payload) {
      return state.merge(
        {
          modal: {
            logDetails: payload
          }
        },
        { deep: true }
      );
    },
    setSearch(state, payload) {
      return state.merge({
        search: payload
      });
    },
    setMessagesByKey(state, payload) {
      return state.merge(
        {
          ...payload
        },
        { deep: true }
      );
    },
    clearError(state) {
      return state.merge(
        {
          error: {
            fetch: null
          }
        },
        { deep: true }
      );
    },
    clearMessage(state) {
      return state.merge({
        result: {
          messages: []
        },
        entities: {
          messages: {}
        }
      });
    },
    clearMessagesByKey(state) {
      return state.merge(
        {
          result: {
            messagesByKey: []
          },
          entities: {
            messagesByKey: {}
          }
        },
        { deep: true }
      );
    },
    clearSearch(state) {
      return state.merge(
        {
          search: getInitPropsSearch()
        },
        { deep: true }
      );
    }
  },
  effects: dispatch => ({
    async clearRoute() {
      dispatch.router.navigate({
        pathname: window.location.pathname
      });
    },
    async fetch(params, rootState) {
      try {
        const { realm } = rootState.application.realm;
        const mapTimes = {
          '5 Minutes': {
            dateFrom: transformFormatDate(5, 'minutes'),
            dateTo: moment().toISOString()
          },
          '15 Minutes': {
            dateFrom: transformFormatDate(15, 'minutes'),
            dateTo: moment().toISOString()
          },
          '1 Hour': {
            dateFrom: transformFormatDate(1, 'hour'),
            dateTo: moment().toISOString()
          }
        };
        const getMapTimes = (type = '5 Minutes', payload) =>
          mapTimes[type] || {
            dateFrom: moment(payload.dateFrom)
              .seconds(0)
              .milliseconds(0)
              .toISOString(),
            dateTo: moment(payload.dateTo)
              .seconds(59)
              .milliseconds(999)
              .toISOString()
          };
        const paramsSearch = params.isQueryString
          ? getPropsSearch()
          : params?.search;
        const specific = getMapTimes(
          paramsSearch?.time,
          paramsSearch?.specific
        );
        const search = {
          ...omit(
            pickBy({ ...paramsSearch, ...specific }, identity),
            'specific'
          )
        };
        dispatch.router.navigate({
          pathname: window.location.pathname,
          search: `?${createSearchParams(search)}`,
          options: { replace: true }
        });
        const response = await api.pipelineLogs.fetch({
          realm,
          ...search,
          environment: params.environment,
          ...specific
        });
        const pipelineLogsData = normalize(response.data.logsSearch, Schema);
        dispatch.pipelineLogs.set(pipelineLogsData, { ...search, specific });
        dispatch.pipelineLogs.setLast(pipelineLogsData?.result?.length !== 50);
      } catch (error) {
        dispatch.pipelineLogs.setError({
          path: 'fetch',
          value: error.message
        });
      }
    },
    async get(params, rootState) {
      try {
        const { realm } = rootState.application.realm;
        const response = await api.pipelineLogs.get({
          realm,
          environment: params.environment,
          id: params.id
        });
        dispatch.pipelineLogs.setModalLog(response?.data?.logSearch);
      } catch (error) {
        dispatch.pipelineLogs.setError({
          path: 'get',
          value: error.message
        });
      }
    },
    async getMore(params, rootState) {
      const { realm } = rootState.application.realm;
      const { pipelineLogs } = rootState;
      const pipelineLogsId = last(pipelineLogs.result);
      const log = pipelineLogs.entities.pipelineLogs[pipelineLogsId];
      const { specific } = pipelineLogs.search;
      const range = parseStringDate(specific);
      const search = {
        ...omit(
          pickBy({ ...pipelineLogs?.search, ...specific }, identity),
          'specific'
        )
      };
      try {
        const response = await api.pipelineLogs.fetch({
          realm,
          ...search,
          ...params,
          ...range,
          afterId: pipelineLogsId,
          afterDate: log?.timestamp
        });
        const pipelineLogsNormalize = normalize(
          response.data.logsSearch,
          Schema
        );
        const result = [
          ...pipelineLogs.result,
          ...pipelineLogsNormalize.result
        ];
        const entities = {
          ...pipelineLogs.entities.pipelineLogs,
          ...pipelineLogsNormalize.entities.pipelineLogs
        };
        const value = {
          result,
          entities: {
            pipelineLogs: entities
          }
        };
        dispatch.pipelineLogs.set(value, { ...search, specific });
        dispatch.pipelineLogs.setLast(
          pipelineLogsNormalize?.result?.length !== 50
        );
      } catch (e) {
        dispatch.pipelineLogs.setError({
          path: 'more',
          value: e.message
        });
      }
    }
  }),
  logics: [
    {
      type: 'pipelineLogs/fetch',
      latest: true,
      process(context, dispatch, done) {
        dispatch.pipelineLogs.loading({
          path: 'fetch',
          value: true
        });
        dispatch.pipelineLogs.clearError();
        done();
      }
    },
    {
      type: 'pipelineLogs/get',
      latest: true,
      process(context, dispatch, done) {
        dispatch.pipelineLogs.loading({
          path: 'get',
          value: true
        });
        done();
      }
    },
    {
      type: 'pipelineLogs/setModalLog',
      latest: true,
      process(_, dispatch, done) {
        dispatch.pipelineLogs.loading({
          path: 'get',
          value: false
        });
        done();
      }
    },
    {
      type: 'pipelineLogs/set',
      latest: true,
      process({ action }, dispatch, done) {
        const { meta } = action;
        dispatch.pipelineLogs.setSearch(meta);
        dispatch.pipelineLogs.loading({
          path: 'more',
          value: false
        });
        dispatch.pipelineLogs.loading({
          path: 'fetch',
          value: false
        });
        done();
      }
    },
    {
      type: 'pipelineLogs/setError',
      latest: true,
      process({ action }, dispatch, done) {
        if (['fetch'].includes(action?.payload?.path)) {
          dispatch.pipelineLogs.loading({
            path: action?.payload.path,
            value: false
          });
          dispatch.pipelineLogs.clearMessage();
        }
        done();
      }
    },
    {
      type: 'pipelineLogs/getMore',
      latest: true,
      process(_, dispatch, done) {
        dispatch.pipelineLogs.loading({
          path: 'more',
          value: true
        });
        done();
      }
    },
    {
      type: 'pipelineLogs/open',
      latest: true,
      process({ action }, dispatch, done) {
        const { meta, payload } = action;
        if (payload.path === 'log' && meta.id) {
          dispatch.pipelineLogs.get(meta);
        }
        if (payload.path === 'log' && !payload.value) {
          dispatch.pipelineLogs.setModalLog(null);
        }
        dispatch.pipelineLogs.setModalPipeline(meta);
        done();
      }
    },
    {
      type: ['pipelineLogs/success'],
      latest: true,
      process(_, dispatch, done) {
        dispatch.pipelineLogs.loading({ path: 'reexecute', value: false });
        dispatch.pipelineLogs.open({
          path: 'reexecution',
          value: false
        });
        done();
      }
    }
  ]
};

export default pipelineLogsModel;
