import {
  Menu,
  IconButton,
  Skeleton,
  Avatar,
  type AvatarProps,
  Alert,
  Tooltip,
  Box
} from '@digibee/beehive-ui'
import { choose, iff, otherwise, when } from '@digibee/control-statements';
import { memo, useCallback } from 'react';

import * as Elements from './Account.elements';
import { Props } from './Account.enhancer';

import { API_KEY } from '~/api/variables';
import i18n from '~/common/helpers/i18n';
import InputFile from '~/components/InputFile';

const Account = ({
  setModalNewPassword,
  setModalTwoFactor,
  setModalProfile,
  onSignOut,
  user,
  token,
  onChangeImage,
  avatarTimestamp,
  saveImageProfileLoading,
  isMainRealm
}: Props) => {
  const UserAvatar = useCallback(({ size }: { size: AvatarProps['size'] }) => (
    <Avatar
      data-testid='profile-show-menu-button'
      size={size}
      text={user?.email}
      placeholder={user?.email}
      type={user?.imageUrl ? 'picture' : 'char'}
      config={{
        provider: {
          url: `${user?.imageUrl}?${avatarTimestamp}`,
          options: {
            responseType: 'blob',
            headers: {
              apiKey: API_KEY,
              Authorization: token
            }
          }
        }
      }}
    />
  ), [user, avatarTimestamp, token]);

  return (
    <Menu.Root modal={false}>
      <Elements.MenuContent
        componentTrigger={(
          <IconButton>
            {choose(
              when(saveImageProfileLoading, () => (
                <Skeleton
                  variant='circular'
                  sizes='xLarge'
                />
              )),
              otherwise(() => <UserAvatar size='xsmall' />)
            )}
          </IconButton>
        )}
      >
        <Elements.Profile>
          {choose(
            when(saveImageProfileLoading, () => (
              <Skeleton
                variant='circular'
                css={{
                  width: '80px',
                  height: '80px',
                }}
              />
            )),
            when(!isMainRealm, () => (
              <UserAvatar size='xlarge' />
            )),
            otherwise(() => (
              <InputFile
                data-testid='governance-photo-input-profile'
                onChange={onChangeImage}
                accept='image/png, image/jpg, image/jpeg'
              >
                <UserAvatar size='xlarge' />
                <Elements.Badge>
                  <Elements.BadgeIcon icon='camera' />
                </Elements.Badge>
              </InputFile>
            ))
          )}
          {iff(!isMainRealm, () => (
            <Box 
              //@ts-ignore
              title={i18n.t('common.guidance.action_allowed_in_main_realm')}
              css={{ marginTop: '16px', marginBottom: '16px' }}
            >
              <Alert 
                title={i18n.t('common.guidance.actions_disabled') || ''}
                variant='info' 
                size='small'
              />
            </Box>
          ))}
          <Elements.Name as='span' className='fs-mask' data-testid='governance-username-profile'>
            {`${user?.firstName} ${user?.lastName}`}
          </Elements.Name>
          <Elements.Email
            as='span'
            className='fs-mask'
            data-testid='governance-email-profile'
          >
            {user?.email}
          </Elements.Email>
          <Elements.Timezone as='span' data-testid='governance-timezone-profile'>
            {user?.timezone}
          </Elements.Timezone>
        </Elements.Profile>
        <Elements.List inset noHairlines>
          <Elements.Item
            data-testid='profile-edit-button'
            onClick={() => setModalProfile(true)}
          >
            <Elements.Icon icon='pencil' />
            <Elements.ItemText as='span'>
              {i18n.t('scenes.profile.labels.edit_profile_details')}
            </Elements.ItemText>
          </Elements.Item>
          {iff(isMainRealm, () => (
            <Elements.Item
              data-testid='profile-change-password-button'
              onClick={() => setModalNewPassword(true)}
            >
              <Elements.Icon icon='lock-alt' />
              <Elements.ItemText as='span'>
                {i18n.t('scenes.profile.labels.change_password')}
              </Elements.ItemText>
            </Elements.Item>
          ))}
          {iff(isMainRealm, () => (
            <Elements.Item
              data-testid='profile-two-factor-auth-button'
              onClick={() => setModalTwoFactor(true)}
            >
              <Elements.Icon icon='mobile' />
              <Elements.ItemText as='span'>
                {i18n.t('scenes.profile.labels.two_factor')}
              </Elements.ItemText>
            </Elements.Item>
          ))}
          <Elements.Item onClick={onSignOut} data-testid='profile-exit-button'>
            <Elements.Icon icon='sign-out-alt' />
            <Elements.ItemText as='span'>
              {i18n.t('scenes.profile.labels.sign_out')}
            </Elements.ItemText>
          </Elements.Item>
        </Elements.List>
      </Elements.MenuContent>
    </Menu.Root>
  )
};

export default memo(Account);
