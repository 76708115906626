import { Text } from '@digibee/beehive-ui';
import { choose, iff, otherwise, when } from '@digibee/control-statements';
import { Level } from '@digibee/flow';
import { faLocationCrosshairs } from '@fortawesome/pro-light-svg-icons';
import {
  faCrosshairs,
  faChevronRight,
  faChevronDown
} from '@fortawesome/pro-regular-svg-icons';
import {
  faCaretDown,
  faCaretRight,
  faCircleDot,
  faCodeCommit,
  faRotate,
  faRotateExclamation
} from '@fortawesome/pro-solid-svg-icons';
import { useSelector } from '@xstate/react';
import { useState } from 'react';
import { shallowEqual } from 'react-redux';

import { TreeNode } from '../Node';
import * as Elements from '../Tree.elements';
import useTreeContext from '../utils/useTreeContext';

type LevelNodeProps = {
  node: TreeNode<Level>;
  shouldInspectDebugLayout: boolean;
};

const LevelNode = ({ node, shouldInspectDebugLayout }: LevelNodeProps) => {
  const [showingActiveButton, setShowingActiveButton] = useState(false);
  const actor = useTreeContext(state => state.actor);
  const goToLevel = useTreeContext(state => state.goToLevel);

  const isChildrenExpanded = useSelector(
    actor,
    state => state.context.expandedById[node.id],
    shallowEqual
  );

  const comesFromCurrentLevelComponent = useSelector(actor, state => {
    const { currentLevelComponent } = state.context;

    return currentLevelComponent
      ? currentLevelComponent.id() === node.item.parentComponent()?.id() ||
          node.item.comesFromComponent(currentLevelComponent.id())
      : false;
  });

  const emphasis =
    comesFromCurrentLevelComponent && !shouldInspectDebugLayout
      ? 'children'
      : undefined;

  const onClickNavigationAction = () => goToLevel(node);
  const onMouseEnter = () => setShowingActiveButton(true);
  const onMouseLeave = () => setShowingActiveButton(false);

  return (
    <Elements.Container
      data-testid='flow-tree-node-container'
      shouldInspectDebugLayout={shouldInspectDebugLayout}
      isLevel
    >
      <Elements.Item
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        data-testid='build-flow-tree-node-item'
        depthLeft={shouldInspectDebugLayout ? node.depth - 1 : node.depth}
        emphasis={emphasis}
        shouldInspectDebugLayout={shouldInspectDebugLayout}
      >
        {choose(
          when(node.id === 'root', () => (
            <Elements.Icon
              type='root'
              icon={shouldInspectDebugLayout ? faCodeCommit : faCircleDot}
            />
          )),
          when(shouldInspectDebugLayout, () => (
            <>
              <Elements.ToggleWrapper
                onClick={() =>
                  actor.send({ type: 'TOGGLE_CHILDREN', id: node.id })
                }
              >
                <Elements.Icon
                  icon={isChildrenExpanded ? faChevronDown : faChevronRight}
                />
              </Elements.ToggleWrapper>
              <Elements.Icon
                icon={
                  (node.item as Level).type() === 'onProcess'
                    ? faRotate
                    : faRotateExclamation
                }
              />
            </>
          )),
          otherwise(() => (
            <>
              <Elements.ToggleWrapper
                onClick={() =>
                  actor.send({ type: 'TOGGLE_CHILDREN', id: node.id })
                }
              >
                <Elements.Icon
                  icon={isChildrenExpanded ? faCaretDown : faCaretRight}
                />
              </Elements.ToggleWrapper>
              <Elements.Icon
                icon={
                  (node.item as Level).type() === 'onProcess'
                    ? faRotate
                    : faRotateExclamation
                }
              />
            </>
          ))
        )}
        <Elements.Label
          type='level'
          shouldInspectDebugLayout={shouldInspectDebugLayout}
        >
          <Text
            variant='b3'
            disabled
            css={{
              fontStyle: shouldInspectDebugLayout ? 'italic' : 'normal',
              fontSize: shouldInspectDebugLayout ? '12px' : '14px'
            }}
          >
            {node.label}
          </Text>
        </Elements.Label>
        {iff(showingActiveButton, () => (
          <Elements.FloatAction
            shouldInspectDebugLayout={shouldInspectDebugLayout}
          >
            <Elements.Action
              onClick={onClickNavigationAction}
              icon={
                shouldInspectDebugLayout ? faCrosshairs : faLocationCrosshairs
              }
              shouldInspectDebugLayout={shouldInspectDebugLayout}
            />
          </Elements.FloatAction>
        ))}
      </Elements.Item>
    </Elements.Container>
  );
};

export default LevelNode;
