/* eslint-disable camelcase */
import { FlowSpec } from '@digibee/flow';
import axios, { isAxiosError } from 'axios';

import { API_KEY, URL } from './variables';

import { AIMessageData } from '~/scenes/AI/components/AIChat/AIChat.machine';

type GetNextComponentParams = {
  realm?: string;
  triggerName?: string;
  sequence?: string[];
  params?: {
    trigger?: string;
    connectors?: string[];
    isTargetOf?: string;
    isOtherwiseOf?: string;
    isOnProcessOf?: string;
    isOnExceptionOf?: string;
    pipelineEndpointId?: string;
    flowspec?: FlowSpec;
  };
  token: string;
};

type ExecutionMessages = {
  id: string;
  body: string;
  processorStepName: string;
  processorType: string;
  elapsed: number;
  connectorId: string;
  connectorName: string;
  timestampStart: number;
  timestampEnd: number;
  indexes: object;
};

type ExecutionLogs = {
  id: string;
  pipelineName: string;
  logMessage: string;
  timestamp: string;
  loglevel: string;
};

export type Insights = {
  type: string;
  message: string;
  details: string;
  connectorId: string;
};

type SendFlowToInsightsParams = {
  flowSpec: FlowSpec;
  executionMessages?: ExecutionMessages[];
  executionLogs?: ExecutionLogs[];
  executionInput?: object;
  executionOutput?: object;
  realm: string;
  token?: string;
};

type NextComponentSuggestion = {
  class: string;
  component_name: string;
  probability: number;
};

export type AIMessage = {
  actions?: Record<string, unknown>[];
  triggers?: Record<string, unknown>[];
  data?: AIMessageData;
  sender: string;
  feedback?: 'like' | 'dislike';
  text: string;
  off_topic?: boolean;
};

export type SendChatMessageParams2 = {
  realm: string;
  target: string;
  lang: string;
  history?: AIMessage[];
  message: AIMessage;
  token: string;
};

const designAIAssistant = {
  sendAIChatMessage: async ({
    lang,
    message,
    realm,
    target,
    history,
    token
  }: SendChatMessageParams2) => {
    const timeout = 1000 * 58; // 58 seconds
    try {
      const body = {
        message,
        history
      };

      const { data } = await axios.post(
        `${URL}/design/realms/${realm}/ai-services/ai-chat/message?target=${target}&lang=${lang}`,
        body,
        {
          headers: {
            authorization: token,
            apiKey: API_KEY
          },
          timeout
        }
      );

      return data;
    } catch (error) {
      if (error instanceof Error && isAxiosError(error)) {
        const isTimeoutFromGraph =
          error.message === `timeout of ${timeout}ms exceeded`;

        if (isTimeoutFromGraph) {
          throw new Error(
            JSON.stringify({
              data: {
                status: 502,
                timeout,
                message: 'Timeout'
              }
            })
          );
        }

        throw new Error(error?.response?.data);
      }
      throw error;
    }
  },

  getNextComponentSuggestions: async ({
    realm,
    triggerName,
    sequence,
    token
  }: GetNextComponentParams) => {
    try {
      const body =
        sequence && sequence.length > 0
          ? { sequence }
          : { trigger: triggerName };

      const response = await axios.post(
        `${URL}/design/realms/${realm}/ai-services/component-prediction`,
        body,
        {
          headers: {
            authorization: token,
            apiKey: API_KEY
          }
        }
      );

      const suggestions: NextComponentSuggestion[] = response.data.result.map(
        (suggestion: NextComponentSuggestion) => ({
          ...suggestion,
          class: suggestion.class || suggestion.component_name
        })
      );

      return suggestions;
    } catch (error) {
      if (error instanceof Error && isAxiosError(error))
        throw Error(error.message);
      throw error;
    }
  },
  sendFlowToInsights: async ({
    flowSpec,
    executionMessages,
    executionLogs,
    executionInput,
    executionOutput,
    realm,
    token
  }: SendFlowToInsightsParams) => {
    try {
      const body = {
        flowspec: flowSpec,
        executionMessages: executionMessages || [],
        executionLogs: executionLogs || [],
        executionInput: executionInput || {},
        executionOutput: executionOutput || {}
      };

      const { data } = await axios.post(
        `${URL}/design/realms/${realm}/ai-services/ai-insights`,
        body,
        {
          headers: {
            authorization: token,
            apiKey: API_KEY,
            'Content-Type': 'application/json'
          }
        }
      );

      if (!data.insights) {
        return null;
      }
      return data;
    } catch (error) {
      if (error instanceof Error && isAxiosError(error))
        throw Error(error.message);
      throw error;
    }
  }
};

export default designAIAssistant;
